import React, { useState, useEffect } from "react";
import {
  PTCSPendingApprovalApi,
  PTCSRejectedOrder,
  acceptedOrderInPTCS,
} from "../apis/apiClients";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserDropdown from "../reusable/UserDropdown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../constants/constants";
import SearchComponent from "../searchBar/SearchComponent";

const PTCSPendingApproval = () => {
  const [orders, setOrders] = useState([]);

  const history = useHistory();

  const handlePrev = () => {
    history.push("/PTCSDashboard");
  };

  const handleAccept = async (orderId) => {
    try {
      const res = await acceptedOrderInPTCS(orderId);
      if (res.status === 200) {
        console.log("Order Accepted successfully!");
      }
      toast.success("Order Accepted Successfully!");
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      window.location.reload(false);
    }, 2000);
  };

  const handleRejection = async (orderId) => {
    const confirmReject = window.confirm(
      "Are you sure you want to reject this order?"
    );

    if (confirmReject) {
      try {
        const res = await PTCSRejectedOrder(orderId);
        if (res.status === 200) {
          console.log("Order Rejected successfully!");
        }
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    toast.success("Order Rejected Successfully!");
    setTimeout(() => {
      window.location.reload(false);
    }, 2000);
  };

  const uniqueId = localStorage.getItem("uniqueId");
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await PTCSPendingApprovalApi(uniqueId);

        if (response) {
          const orders = response.orders;
          setOrders(orders);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrders();
  }, [uniqueId]);

  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">PTCS Pending Approval!</b>
            <UserDropdown />
          </div>
        </div>
      </div>
      <h2>Number of orders : {orders.length}</h2>
      <SearchComponent orders={orders} setOrders={setOrders} />
      <div className="ordersContainer">
        {orders.map((order) => (
          <div className="ordersItem" key={order._id}>
            <div className="orderBoxId">
              <p
                style={{
                  color: "#800000",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
              >
                ORDER ID: {order.orderId}
              </p>
            </div>
            <p>
              <b>Client Name :</b> {order.clientName}
            </p>
            <p>
              <b>Client Email : </b>
              {order.clientEmail}
            </p>
            <p>
              <b>Client Address : </b>
              {order.clientAddress}
            </p>
            <p>
              <b>Client Contact : </b> {order.clientContact}
            </p>
            <p>
              <b>Client Pincode :</b> {order.clientPincode}
            </p>
            <p>
              <b>Client GST :</b> {order.clientGST}
            </p>
            <p>
              <b>Box Type : </b> {order.boxType}
            </p>
            <p>
              <b>Closure :</b> {order.closure}
            </p>
            <p>
              <b>Dimension : </b> {order.dim} {order.dimUnit}
            </p>
            <p>
              <b>Quantity 1 : </b>
              {order.qtt1}
            </p>
            <p>
              <b>Quantity 2 :</b> {order.qtt2}
            </p>
            <p>
              <b>Quantity 3 :</b> {order.qtt3}
            </p>
            <p>
              <b>Paper GSM : </b> {order.quality}
            </p>
            <p>
              <b>Region : </b> {order.region}
            </p>
            <p>
              <b>Price 1 : </b>
              {order.price1}
            </p>
            <p>
              <b>Price 2 : </b>
              {order.price2}
            </p>
            <p>
              <b>Price 3 : </b>
              {order.price3}
            </p>
            <p>
              <b>Transport : </b>
              {order.transport}
            </p>
            <p>
              <b>Sale Date : </b>
              {order.saleDate}
            </p>
            <p>
              <b>Remarks/Additional Info : </b>
              {order.remarks}
            </p>

            <div className="applicationsValueContainer">
              <p>
                <b> Applications :</b>
                <div className="applicationsValue">
                  {Object.keys(order.applicationsValue).map((app) => (
                    <div key={app}>
                      {order.applicationsValue[app] &&
                        ` ${app} : ${order.applicationsValue[app]}%`}
                    </div>
                  ))}
                </div>
              </p>
            </div>
            <p>
              <b>Images</b>
            </p>
            {order.imageUrls.length ? (
              <div>
                {order.imageUrls.map((imageUrl, index) => (
                  <a
                    href={`${BASE_URL}${imageUrl.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="viewPdfButton" key={index}>
                      <a
                        rel="noreferrer"
                        href={`${BASE_URL}${imageUrl}`}
                        target="_blank"
                        className="linkDecoration"
                      >
                        <div className="imageContainer" key={index}>
                          <img
                            key={imageUrl.index}
                            src={`${BASE_URL}${imageUrl}`}
                            alt="ImagesUpload"
                            className="hoverImage"
                            style={{
                              width: "120px",
                              height: "100px",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </a>
                    </button>
                  </a>
                ))}
              </div>
            ) : (
              <h4>No Images found</h4>
            )}

            <div className="buttonContainer">
              <button
                onClick={() => handleAccept(order.orderId)}
                className="acceptButtonSMPending"
              >
                Accept &nbsp; ✅
              </button>
              <button
                onClick={() => handleRejection(order.orderId)}
                className="rejectButtonSMPending"
              >
                Reject &nbsp;❌
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PTCSPendingApproval;
