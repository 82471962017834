import axios from "axios";
import { BASE_URL, ENDPOINTS } from "../constants/constants";

function Downloadbutton() {
    
    const downloadExcelWorking = async () => {
        
        try {
        // eslint-disable-next-line
        const response = await axios({
            // params:{
            //     employeeId:uniqueId
            // },
            url: `${BASE_URL}${ENDPOINTS.DOWNLOAD_EXCEL_WORKING}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', './qis/exports/working.xlsx');
            document.body.appendChild(link);
            link.click();
        });
        } catch (error) {
            console.error('Error:', error);
        }

    }
    return (
        <div className="buttonParent">
            <button onClick={downloadExcelWorking}>
            Download Worksheet in Excel 
        </button>
        </div>
    );
}

export default Downloadbutton;