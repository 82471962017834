import React, { useContext, useState } from "react";
import UserDropdown from "../reusable/UserDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./BDA-QIS.css";
import { qisMailSend } from "../apis/apiClients";
import { toast } from "react-toastify";
import QuotationPriceDisplay from "../components/QuotationPriceDisplay";
import { UniqueIdContext } from "../context/UniqueIdContext";
import { QISOrdersContext } from "../context/QISOrdersContext";
import FaqSection from "../Faq/FaqSection";
import FBBBoxContainer from "../InputFieldsForBoxes/FBBBoxContainer";
import RigidBoxContainer from "../InputFieldsForBoxes/RigidBoxContainer";
import CorrugatedContainer from "../InputFieldsForBoxes/CorrugatedContainer";
import PaperBagContainer from "../InputFieldsForBoxes/PaperBagContainer";
import FoodGradeContainer from "../InputFieldsForBoxes/FoodGradeContainer";
import CommercialContainer from "../InputFieldsForBoxes/CommercialContainer";
// import KraftBoxContainer from "../InputFieldsForBoxes/KraftBoxContainer";
import OutputTables from "./OutputTables";
import "./OutputTables.css";
import CommercialAlternateContainer from "../InputFieldsForBoxes/CommercialAlternateContainer";

const Qis = () => {
  const { price1, price2, price3 } = useContext(UniqueIdContext);
  const {
    clientName,
    clientEmail,
    // clientContact,
    clientAddress,
    product,
    boxType,
    closure,
    handle,
    cuffSize,
    kraftQuality,
    dim,
    dimUnit,
    qtt1,
    qtt2,
    qtt3,
    asspassPaper,
    asspassQuality,
    transport,
    boardThickness,
    region,
    quality,
    applications,
    applicationsValue,
  } = useContext(QISOrdersContext);

  const [displayQuotation, setDisplayQuotation] = useState(false);

  const [activeButton1, setActiveButton1] = useState(null);
  const [activeButton2, setActiveButton2] = useState(null);
  const [activeButton3, setActiveButton3] = useState(null);
  const [activeButton4, setActiveButton4] = useState(null);
  const [activeButton5, setActiveButton5] = useState(null);
  const [activeButton6, setActiveButton6] = useState(null);

  const handleButtonClick1 = (button) => {
    setActiveButton1(button);
    setActiveButton2(null);
    setActiveButton3(null);
    setActiveButton4(null);
    setActiveButton5(null);
    setActiveButton6(null);
  };

  const handleButtonClick2 = (button) => {
    setActiveButton2(button);
    setActiveButton1(null);
    setActiveButton3(null);
    setActiveButton4(null);
    setActiveButton5(null);
    setActiveButton6(null);
  };

  const handleButtonClick3 = (button) => {
    setActiveButton3(button);
    setActiveButton1(null);
    setActiveButton2(null);
    setActiveButton4(null);
    setActiveButton5(null);
    setActiveButton6(null);
  };
  const handleButtonClick4 = (button) => {
    setActiveButton4(button);
    setActiveButton1(null);
    setActiveButton2(null);
    setActiveButton3(null);
    setActiveButton5(null);
    setActiveButton6(null);
  };

  const handleButtonClick5 = (button) => {
    setActiveButton5(button);
    setActiveButton1(null);
    setActiveButton2(null);
    setActiveButton3(null);
    setActiveButton4(null);
    setActiveButton6(null);
  };

  const handleButtonClick6 = (button) => {
    setActiveButton6(button);
    setActiveButton1(null);
    setActiveButton2(null);
    setActiveButton3(null);
    setActiveButton4(null);
    setActiveButton5(null);
  };

  function addSpaceAndCapitalize(str) {
    const modifiedString = str.replace(/([A-Z])/g, " $1");
    return modifiedString.charAt(0).toUpperCase() + modifiedString.slice(1);
  }

  const modifiedHandle = addSpaceAndCapitalize(handle);
  const modifiedBoxType = addSpaceAndCapitalize(boxType);
  const modifiedClosure = addSpaceAndCapitalize(closure);
  const modifiedTransport = addSpaceAndCapitalize(transport);
  const modifiedRegion = addSpaceAndCapitalize(region);

  const sendMail = async () => {
    try {
      const res = await qisMailSend(
        price1,
        price2,
        price3,
        clientName,
        clientEmail,
        clientAddress,
        modifiedBoxType,
        modifiedClosure,
        kraftQuality,
        asspassPaper,
        asspassQuality,
        cuffSize,
        boardThickness,
        dimUnit,
        modifiedHandle,
        dim,
        qtt1,
        qtt2,
        qtt3,
        modifiedTransport,
        quality,
        modifiedRegion,
        applications,
        applicationsValue,
        employeeId,
        product
      );
      if (res.status === 200) {
        setDisplayQuotation(true);
        toast.success("Mail Sent Successfully!");
      }
      console.log(res.data);
      return res.data;
    } catch (error) {
      toast.error("Failed to send the mail!");
      console.log(error);
    }
  };

  const employeeId = localStorage.getItem("uniqueId");

  const history = useHistory();
  const handlePrev = () => {
    history.push("/dashboard");
  };

  return (
    <div className="backgroundImageQIS">
      <div>
        <div className="orderTitleContainer">
          <div className="navbarLayer">
            <div className="logoContainer">
              <i
                onClick={handlePrev}
                id="backwardArrow"
                className="fa-solid fa-circle-arrow-left"
              ></i>
              <b className="titleOrders">(QIS)</b>
              <UserDropdown />
            </div>
          </div>
        </div>
        <div>
          
        </div>

        <h3
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Choose your box type!
        </h3>
        <div className="boxTypeContainer">
          <div className="button-row button-row1">
            <button
              className={`button${activeButton1 === "FBB" ? " is-active" : ""}`}
              onClick={() => handleButtonClick1("FBB")}
            >
              FBB
            </button>
            <button
              className={`button${
                activeButton2 === "Corrugated box" ? " is-active" : ""
              }`}
              onClick={() => handleButtonClick2("Corrugated box")}
            >
              Corrugated box
            </button>
            <button
              className={`button${
                activeButton3 === "Rigid Box" ? " is-active" : ""
              }`}
              onClick={() => handleButtonClick3("Rigid Box")}
            >
              Rigid Box
            </button>
            <button
              className={`button${
                activeButton4 === "Paper Bag" ? " is-active" : ""
              }`}
              onClick={() => handleButtonClick4("Paper Bag")}
            >
              Paper Bag
            </button>
            <button
              className={`button${
                activeButton5 === "Commercial Printing" ? " is-active" : ""
              }`}
              onClick={() => handleButtonClick5("Commercial Printing")}
            >
              Commercial Printing
            </button>
            <button
              className={`button${
                activeButton6 === "Commercial Printing Alt" ? " is-active" : ""
              }`}
              onClick={() => handleButtonClick6("Commercial Printing Alt")}
            >
              Commercial Printing Alt
            </button>
          </div>
        </div>

        <div className="displayDetails">
          {activeButton1 ? (
            <div>
              <div>
                <FBBBoxContainer />
              </div>
            </div>
          ) : (
            ""
          )}

          {activeButton2 ? (
            <div>
              <CorrugatedContainer />
            </div>
          ) : (
            ""
          )}

          {activeButton3 ? (
            <div>
              <RigidBoxContainer />
            </div>
          ) : (
            ""
          )}

          {activeButton4 ? (
            <div>
              <PaperBagContainer />
            </div>
          ) : (
            ""
          )}

          {activeButton5 ? (
            <div>
              {/* <FoodGradeContainer /> */}
              <CommercialContainer/>
            </div>
          ) : (
            ""
          )}
          {activeButton6 ? (
            <div>
              {/* <FoodGradeContainer /> */}
              <CommercialAlternateContainer/>
            </div>
          ) : (
            ""
          )}
        </div>

        {displayQuotation ? (
          <QuotationPriceDisplay qtt1={qtt1} qtt2={qtt2} qtt3={qtt3} />
        ) : (
          ""
        )}

        {displayQuotation ? (
          <div className="mailButtonContainer">
            <button onClick={sendMail}>
              {/* Send mail
           // className className="mailButton" */}
            </button>
          </div>
        ) : (
          ""
        )}
        <br />
        <br />
        {displayQuotation ? (
          <OutputTables qtt1={qtt1} qtt2={qtt2} qtt3={qtt3} />
        ) : (
          ""
        )}

        {/* <FaqSection /> */}
      </div>
    </div>
  );
};

export default Qis;
