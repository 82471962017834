import React, { useContext, useState, useEffect, useRef } from "react";
import { RigidBoxOptions } from "../DropDownOptions/boxClosureTypes/RigidBoxOptions";
import { DimensionUnitOptions } from "../DropDownOptions/DimensionUnitOptions";
import { regionOptions } from "../DropDownOptions/RegionOptions";
import { RigidOuterPaperOptions } from "../DropDownOptions/RigidOuterPaperOptions";
import DropDownFields from "../reusable/DropDownFields";
import { transportOptions } from "../DropDownOptions/TransportOptions";
import { AssPassPaper } from "../DropDownOptions/AsspassPaperOptions";
import { AsspassQualityOptions } from "../DropDownOptions/AsspassQualityOptions";
import { QISOrdersContext } from "../context/QISOrdersContext";
import { LaminationOptions } from "../DropDownOptions/LaminationOptions";
import { UniqueIdContext } from "../context/UniqueIdContext";
import { toast } from "react-toastify";
import { qisOrderDetails } from "../apis/apiClients";
import InputTextField from "../reusable/InputTextField";
import { BoardSizeOptions } from "../DropDownOptions/BoardSizeOptions";
import { CavityOptions } from "../DropDownOptions/CavityOptions";
import QuotationPriceDisplay from "../components/QuotationPriceDisplay";
import QisOrdersDisplay from "../reusable/QisOrdersDisplay";
import RigidClosureImages from "../modals/RigidClosureImages";
import RegionModal from "../modals/RegionModal";
import QisApplicationsValue from "../DropDownOptions/QisApplicationsValue";
import OutputTables from "../BDA/OutputTables";
import { HandleAllClosureOptions } from "../DropDownOptions/HandleAllClosuresOptions";
import RigidClosureImagesDisplay from "./RigidClosureImagesDisplay";
import { PrintingColoursOptions } from "../DropDownOptions/PrintingColoursOptions";
import { PrintingColoursOptionsInsidePaper } from "../DropDownOptions/PrintingColoursOptionsInsidePaper";

const RigidBoxContainer = () => {
  const quotationRef = useRef(null);
  const [displayQuotation, setDisplayQuotation] = useState(false);
  const [displayOrderDetails, setDisplayOrderDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [regionModal, setRegionModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { setPrice1, setPrice2, setPrice3 } = useContext(UniqueIdContext);
  const {
    clientName,
    setClientName,
    clientEmail,
    setClientEmail,
    clientAddress,
    setClientAddress,
    product,
    setProduct,
    closure,
    setClosure,
    handleAllClosureType,
    sethandleAllClosureType,
    dim,
    setDim,
    dimUnit,
    setDimUnit,
    boardThickness,
    setBoardThickness,
    qtt1,
    setQtt1,
    qtt2,
    setQtt2,
    qtt3,
    setQtt3,
    length,
    setLength,
    breadth,
    setBreadth,
    height,
    setHeight,
    asspassPaper,
    setAsspassPaper,
    kraftQuality,
    cuffSize,
    setCuffSize,
    handle,
    asspassQuality,
    setAsspassQuality,
    transport,
    setTransport,
    region,
    setRegion,
    quality,
    setQuality,
    paperBagPaperType,
    applications,
    setApplications,
    applicationsValue,
    setApplicationsValue,
    cavity,
    setCavity,
    doubleSide,
    lamination,
    setLamination,
    fbbPaperType,
    corrugatedInnerPaper, 
    corrugatedOuterPaper,
    foodGradePaperGSM,
    foodGradePrintingType,
    foodGradePaperColour,
    printingColours, 
    setPrintingColours,
    printingColoursInsidePaper, 
    setPrintingColoursInsidePaper
  } = useContext(QISOrdersContext);

  const handleToggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const handleRegionModal = () => {
    setRegionModal((prevShowModal) => !prevShowModal);
  };

  const employeeId = localStorage.getItem("uniqueId");

  const boxType = "rigidBox";

  const resetApplicationsValue = (appName) => {
    setApplicationsValue((prevApplicationsValue) => ({
      ...prevApplicationsValue,
      [appName]: null,
    }));
  };

  const handleApplicationChange = (event) => {
    const { name, checked } = event.target;
    setApplications((prevApplications) => ({
      ...prevApplications,
      [name]: checked,
    }));
    if (!checked) {
      resetApplicationsValue(name);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (displayQuotation && quotationRef.current) {
        quotationRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 1000);

    setTimeout(() => {
      if (displayOrderDetails && quotationRef.current) {
        quotationRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 1000);
  }, [displayQuotation, displayOrderDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisplayOrderDetails(true);
    try {
      const res = await qisOrderDetails(
        clientName,
        clientEmail,
        boxType,
        product,
        closure,
        kraftQuality,
        asspassPaper,
        asspassQuality,
        cuffSize,
        boardThickness,
        dimUnit,
        handle,
        dim,
        qtt1,
        qtt2,
        qtt3,
        transport,
        quality,
        paperBagPaperType,
        region,
        applications,
        applicationsValue,
        employeeId,
        cavity,
        doubleSide,
        lamination,
        fbbPaperType,
        handleAllClosureType,
        corrugatedInnerPaper,
        corrugatedOuterPaper,
        foodGradePaperGSM,
        foodGradePrintingType,
        foodGradePaperColour,
        printingColours,
        printingColoursInsidePaper,
      );
      console.log("API called");
      if (res.status === 200) {
        setDisplayQuotation(true);
        toast.success("Quotation Generated successfully!");

        const price1 = res.data.first;
        setPrice1(price1);

        const price2 = res.data.second;
        setPrice2(price2);

        const price3 = res.data.third;
        setPrice3(price3);
      }
      setTableData(res.data);
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log("Errorrr");
      toast.error("Failed to Generate the Quotation!");
      console.log(error);
    }
  };

  return (
    <div>
      <div className="outerContainer">
        <div className="container">
          <div className="formTitle">Client & Order Details</div>
          <div className="formDetails">
            <form action="POST" className="form" onSubmit={handleSubmit}>
              {/* <div>
                <div className="input-box">
                  <InputTextField
                    value={clientName}
                    setValue={setClientName}
                    label={"Client Name"}
                    placeholder={"Enter Client Name"}
                  />
                </div>

                <div className="input-box">
                  <InputTextField
                    value={clientEmail}
                    label={"Client Email"}
                    setValue={setClientEmail}
                    placeholder={"Enter Email Address"}
                  />
                </div>
              </div>

              <div className="input-box">
                <InputTextField
                  value={clientAddress}
                  setValue={setClientAddress}
                  placeholder={"Enter Client Address"}
                  label={"Client Address"}
                />
              </div>

              <div className="input-box">
                <InputTextField
                  value={product}
                  setValue={setProduct}
                  label={"Product"}
                  placeholder={"Enter Product"}
                />
              </div> */}

              <div className="column">
                <div className="input-box">
                  <label>Rigid Closure</label>

                  <DropDownFields
                    options={RigidBoxOptions}
                    value={closure}
                    setValue={setClosure}
                    onChange={(event) => setClosure(event.target.value)}
                  />
                  <RigidClosureImagesDisplay selectedClosure={closure} />
                </div>
              </div>
              <div className="column">
                
                <div className="input-box">
                  <label>Dimension Unit</label>
                  <DropDownFields
                    options={DimensionUnitOptions}
                    value={dimUnit}
                    setValue={setDimUnit}
                  />
                </div>

                
                <div className="input-box">
                  <label>Inner Paper GSM</label>
                  <DropDownFields
                    options={AsspassQualityOptions}
                    value={asspassQuality}
                    setValue={setAsspassQuality}
                  />
                </div>
              </div>

              {closure === "topBottomCuff" && (
                <div>
                  <InputTextField
                    value={cuffSize}
                    setValue={setCuffSize}
                    label={"Size of Visible Cuff (In Inches only)"}
                  />
                </div>
              )}

              <div className="column">
                <div className="input-box">
                  <label>Inner Paper Type</label>
                  <DropDownFields
                    options={AssPassPaper}
                    value={asspassPaper}
                    setValue={setAsspassPaper}
                  />
                </div>
                <div className="input-box">
                {asspassPaper==="print paper" && (
                  <div>
                    <DropDownFields
                      label={"Inner Paper - No. of Colours"}
                      value={printingColoursInsidePaper}
                      setValue={setPrintingColoursInsidePaper}
                      options={PrintingColoursOptionsInsidePaper}
                    />
                  </div>
                )}
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <label>Board Thickness</label>
                  <DropDownFields
                    options={BoardSizeOptions}
                    value={boardThickness}
                    setValue={setBoardThickness}
                  />
                </div>
                <div className="input-box">
                  <label>Handle Types</label>

                  <DropDownFields
                    options={HandleAllClosureOptions}
                    value={handleAllClosureType}
                    setValue={sethandleAllClosureType}
                  />
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <InputTextField
                    value={length}
                    setValue={(value) => {
                      setLength(value);
                      setDim(`${value}*${breadth}*${height}`);
                    }}
                    label={"Length"}
                    placeholder={"Enter Length"}
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={breadth}
                    setValue={(value) => {
                      setBreadth(value);
                      setDim(`${length}*${value}*${height}`);
                    }}
                    label={"Breadth"}
                    placeholder={"Enter Breadth"}
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={height}
                    setValue={(value) => {
                      setHeight(value);
                      setDim(`${length}*${breadth}*${value}`);
                    }}
                    label={"Height"}
                    placeholder={"Enter Height"}
                  />
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <InputTextField
                    value={qtt1}
                    setValue={setQtt1}
                    label={"Quantity 1"}
                    placeholder={"Quantity 1"}
                    required
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={qtt2}
                    setValue={setQtt2}
                    label={"Quantity 2"}
                    placeholder={"Quantity 2"}
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={qtt3}
                    setValue={setQtt3}
                    label={"Quantity 3"}
                    placeholder={"Quantity 3"}
                  />
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <label>Outer Paper Type</label>
                  <DropDownFields
                    value={quality}
                    setValue={setQuality}
                    options={RigidOuterPaperOptions}
                  />
                </div>
                <div className="input-box">
                  <DropDownFields
                    value={printingColours}
                    setValue={setPrintingColours}
                    options={PrintingColoursOptions}
                    label={"No. of Colours"}
                  />
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <label>Transport</label>
                  <DropDownFields
                    value={transport}
                    setValue={setTransport}
                    options={transportOptions}
                  />
                </div>
                <div className="input-box">
                  <label>Region</label>
                  <DropDownFields
                    value={region}
                    setValue={setRegion}
                    options={regionOptions}
                  />
                </div>
                <i
                  onClick={handleRegionModal}
                  id="infoId"
                  className="fa-solid fa-circle-info"
                ></i>
                {regionModal ? (
                  <RegionModal showModal={handleRegionModal} />
                ) : (
                  ""
                )}
              </div>

              <div className="column">
                <div className="input-box">
                  <label>Lamination</label>
                  <DropDownFields
                    value={lamination}
                    setValue={setLamination}
                    options={LaminationOptions}
                  />
                </div>
                <div className="input-box">
                  <DropDownFields
                    value={cavity}
                    setValue={setCavity}
                    label={"Cavity"}
                    options={CavityOptions}
                  />
                </div>
              </div>

              <div className="applicationsGridContainer">
                <label htmlFor="applications">Applications :</label>
                <div className="applicationsGrid">
                  <QisApplicationsValue
                    applications={applications}
                    handleApplicationChange={handleApplicationChange}
                  />
                </div>
              </div>

              <button>Magic Button ✨</button>
            </form>
          </div>
          {displayOrderDetails ? <QisOrdersDisplay boxType={boxType} /> : ""}
          {/* {displayQuotation ? (
            <QuotationPriceDisplay qtt1={qtt1} qtt2={qtt2} qtt3={qtt3} />
          ) : (
            ""
          )} */}
          {displayQuotation ? (
            <OutputTables
              tableData={tableData}
              qtt1={qtt1}
              qtt2={qtt2}
              qtt3={qtt3}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default RigidBoxContainer;
