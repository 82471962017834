import React from "react";
import { useEffect, useState } from "react";
import { SMmanagerOrders } from "../apis/apiClients";
import { useHistory } from "react-router-dom";
import "./ManageOrders.css";
import UserDropdown from "../reusable/UserDropdown";
import DemoOrders from "../reusable/ViewOrders";
import SearchComponent from "../searchBar/SearchComponent";

const ManagerOrders = () => {
  const [orders, setOrders] = useState([]);

  const history = useHistory();

  const uniqueId = localStorage.getItem("uniqueId");

  const handlePrev = () => {
    history.push("/salesDashboard");
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await SMmanagerOrders(uniqueId);
        if (response) {
          const orders = response.orders;
          setOrders(orders);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrders();
  }, [uniqueId]);

  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">SM View Orders!</b>
            <UserDropdown />
          </div>
        </div>
      </div>
      <h2>Number of orders : {orders.length}</h2>
      <SearchComponent orders={orders} setOrders={setOrders} />
      <DemoOrders orders={orders} />
    </div>
  );
};

export default ManagerOrders;
