const reducer = (state, action) => {
  switch (action.type) {
    case "SEARCH_QUERY":
      return {
        ...state,
        query: action.payload,
      };
    default:
      break;
  }
};

export default reducer;
