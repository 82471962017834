import React from "react";
import UserDropdown from "../reusable/UserDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PTCSDashboard = () => {
  const history = useHistory();

  const redirectToPTCSOrders = () => {
    history.push("/PTCSDashboard/PTCSOrders");
  };

  const handleAcceptReject = () => {
    history.push("/PTCSDashboard/PTCSPendingApproval");
  };

  const redirectToPTCSEscalation = () => {
    history.push("/PTCSDashboard/PTCSEscalation");
  };
  return (
    <div className="mainDiv">
      <div className="navbarLayer">
        <div className="logoContainer">
          <h2>PTCS Dashboard</h2>

          <UserDropdown />
        </div>
      </div>

      <div className="buttonContainer">
        <button
          onClick={() => redirectToPTCSOrders()}
          className="dashboardButton"
        >
          <i class="fa-solid fa-check" id="orderIcon"></i>
          View Orders
        </button>
      </div>
      <div className="buttonContainer">
        <button
          className="dashboardButton"
          onClick={() => handleAcceptReject()}
        >
          <i className="fa-sharp fa-solid fa-xmark" id="pendingIcon"></i>
          Pending Approvals
        </button>
      </div>

      <div className="buttonContainer">
        <button
          onClick={() => redirectToPTCSEscalation()}
          className="dashboardButton"
        >
          <i class="fa-solid fa-bolt" id="escalationIcon"></i>
          Escalation
        </button>
      </div>
    </div>
  );
};

export default PTCSDashboard;
