import React, {useContext, useState, useRef } from "react";
import DropDownFields from "../../reusable/DropDownFields";
import { CommercialQISContext } from "../../context/CommercialQISContext";
import { CommercialBrochureTypeOptions } from "../../DropDownOptions/CommercialBrochureTypeOptions";
import { CommercialPaperGSMOptions } from "../../DropDownOptions/CommercialPaperGSMOptions";
import { PrintingColoursOptionsInsidePaper } from "../../DropDownOptions/PrintingColoursOptionsInsidePaper";
import { LaminationCommercialOptions } from "../../DropDownOptions/LaminationCommercialOptions";
import CommercialApplications from "../../DropDownOptions/CommercialApplications";
import { PrintingColoursOptions } from "../../DropDownOptions/PrintingColoursOptions";

const CommercialBrochure = () => {
    const quotationRef = useRef(null);
    const employeeId = localStorage.getItem("uniqueId");
    const boxType = "commercialPrinting";

    const {
        closure, setClosure,
        length, setLength,
        breadth, setBreadth,
        dimUnit, setDimUnit,
        qtt1, setQtt1,
        qtt2, setQtt2,
        qtt3, setQtt3,
        quality, setQuality,
        lamination, setLamination,
        transport, setTransport,
        region, setRegion,
        applications, setApplications,
        applicationsValue, setApplicationsValue,
        printingColours, setPrintingColours,
        printingColoursInsidePaper, setPrintingColoursInsidePaper,
        doubleSide, setDoubleSide,
        diaCut, setDiaCut,
        posterLayout, setPosterLayout,
        posterGummingType, setPosterGummingType,
        dimensionType, setDimensionType,
        standardDimensions, setStandardDimensions,

        folderType, setFolderType,
        pouchSize, setPouchSize,
        customPouchSize, setCustomPouchSize,

        brochureType, setBrochureType,
        bookletPages, setbookletPages,
        bookletBindingType, setBookletBindingType,
    } = useContext(CommercialQISContext);

    const resetApplicationsValue = (appName) => {
        setApplicationsValue((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const handleApplicationChange = (event) => {
        const { name, checked } = event.target;
        setApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetApplicationsValue(name);
        }
    };

    return (
        <div>
            {/* 
            1. Brochure Type
            2. Paper GSM
            3. Double Side + Inner Colours
            4. Dia Cut
            5. Lamination
            6. Applications
            */}
            <div className="column">
                <div className="input-box">
                    <DropDownFields
                        label={"Brochure Type"}
                        options={CommercialBrochureTypeOptions}
                        value={brochureType}
                        setValue={setBrochureType}
                    />
                </div>
                <div className="input-box">
                    <DropDownFields
                        label={"Paper GSM"}
                        value={quality}
                        setValue={setQuality}
                        options={CommercialPaperGSMOptions}
                    />
                </div>
            </div>
            <div className="column">
                <div className="input-box">
                    <DropDownFields
                        label={"No. Of Printing Colours"}
                        value={printingColours}
                        setValue={setPrintingColours}
                        options={PrintingColoursOptions}
                    />
                </div>
            </div>
            {
                (printingColours === "5" ||
                printingColours === "6") && (
                    <div style={{margin:"30px"}}>
                        <b style={{color:"#800000"}}>Note : </b>
                        More than 4 colours is considered as Pantone Colours. 
                        Upto 4 colours is considered as CMYK.</div>
                )
            }
            <label style={{marginTop:"10px"}}>Back Side Printing</label>
            <div className="column">
                <div className="input-box">
                    <div className="doubleSidePrinting">
                        <div class="radio-label">
                            <input
                                type="radio"
                                name="doubleSidePrinting"
                                id="yes"
                                value="yes"
                                checked={doubleSide === "yes"}
                                style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                onChange={(e) => setDoubleSide(e.target.value)}
                            />
                            <label className="labelNo" for="yes">
                                Yes
                            </label>
                        </div>
                        <div className="radio-label">
                            <input
                                type="radio"
                                name="doubleSidePrinting"
                                id="no"
                                value="no"
                                checked={doubleSide === "no"}
                                style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                onChange={(e) => setDoubleSide(e.target.value)}
                            />
                            <label className="labelNo" for="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                <div className="input-box">
                    {doubleSide==="yes" && (
                        <DropDownFields
                        options={PrintingColoursOptionsInsidePaper}
                        value={printingColoursInsidePaper}
                        setValue={setPrintingColoursInsidePaper}
                        label={"No. of Colours for Back Print"}
                        />
                    )}
                </div>
            </div>
            <div className="column">
                <div className="input-box">
                    <label>Dia Cut</label>
                    <div className="diaCutRadios">
                        <div className="radio-label">
                            <input
                                type="radio"
                                name="diaCut"
                                id="diaCutYes"
                                value="yes"
                                checked={diaCut==="yes"}
                                style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                onChange={(e) => setDiaCut(e.target.value)}
                            />
                            <b style={{marginTop: "26px", marginBottom: "8px", marginLeft: "6px"}} className="labelNoDiaCut" for="diaCutYes">
                                Yes
                            </b>
                        </div>
                        <div className="radio-label">
                            <input
                                type="radio"
                                name="diaCut"
                                id="diaCutNo"
                                value="no"
                                checked={diaCut==="no"}
                                style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                onChange={(e) => setDiaCut(e.target.value)}
                            />
                            <b style={{marginTop: "26px", marginBottom: "8px", marginLeft: "6px"}} className="labelNoDiaCut" for="diaCutNo">
                                No
                            </b>
                        </div>
                    </div>
                </div>
                <div className="input-box">
                    <DropDownFields
                        value={lamination}
                        setValue={setLamination}
                        options={LaminationCommercialOptions}
                        label={"Lamination"}
                    />
                </div>
            </div>
            <div className="applicationsGridContainer">
                <label htmlFor="applications">Applications : </label>
                <div className="commercialApplicationsGrid">
                    <CommercialApplications
                        applications={applications}
                        handleApplicationChange={handleApplicationChange}
                    />
                </div>
            </div>
        </div>
    )
};

export default CommercialBrochure;