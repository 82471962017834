import React from "react";
import "./PGDashboard.css";
import UserDropdown from "../reusable/UserDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PGDashboard = () => {
  const history = useHistory();

  const redirectToBriefSheets = () => {
    history.push("/PGDashboard/BriefSheetsInPGD");
  };

  return (
    <div className="background">
      <div className="navbarLayer">
        <div className="logoContainer">
          <h2>Product Graphic Designer (PGD) Dashboard</h2>
          <UserDropdown />
        </div>
      </div>
      <div className="PGDFields">
        <div className="buttonContainerCS">
          <button
            className="dashboardButtonCS"
            onClick={() => redirectToBriefSheets()}
          >
            <i class="fa-solid fa-crosshairs" id="orderIcon"></i>
            Pending Designs
          </button>
        </div>
      </div>
    </div>
  );
};

export default PGDashboard;
