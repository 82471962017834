import React from "react";
import "./InputField.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-toastify/dist/ReactToastify.css";
import UserDropdown from "../reusable/UserDropdown";

import PunchCard from "./PunchCard";

const InputField = () => {
  const history = useHistory();

  const handlePrev = () => {
    history.push("/dashboard");
  };

  return (
    <div className="MainWrapperContainer">
      <div className="containerField">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>

            <UserDropdown />
          </div>
        </div>
        <PunchCard />
        {/* <div className="wrapperContainer">
          <form action="POST" onSubmit={handleSubmit} disabled={buttonDisabled}>
            <div className="inputFieldContainer">
              <div>
                <InputTextField
                  value={clientName}
                  label={"Name"}
                  setValue={setClientName}
                />

                <InputTextField
                  value={clientEmail}
                  label={"Email"}
                  setValue={setClientEmail}
                />

                <InputTextField
                  value={clientContact}
                  label={"Contact"}
                  setValue={setClientContact}
                />

                <InputTextField
                  value={clientAddress}
                  label={"Address"}
                  setValue={setClientAddress}
                />

                <InputTextField
                  value={clientPincode}
                  label={"Pincode"}
                  setValue={setClientPincode}
                />
                <InputTextField
                  value={clientGST}
                  label={"GST"}
                  setValue={setClientGST}
                />
                <DropDownFields
                  options={boxTypeOptions}
                  value={boxType}
                  label={"Box Type :"}
                  setValue={setBoxType}
                />
                <br />

                {boxType === "fbb" && (
                  <div>
                    <DropDownFields
                      options={FBBOptions}
                      value={closure}
                      setValue={setClosure}
                      label={"FBB Closure"}
                    />
                  </div>
                )}
                {boxType === "kraft" && (
                  <div>
                    <DropDownFields
                      options={FBBOptions}
                      value={closure}
                      setValue={setClosure}
                      label={"Kappa Closure"}
                    />
                  </div>
                )}

                {boxType === "rigidBox" && (
                  <div>
                    <DropDownFields
                      options={RigidBoxOptions}
                      value={closure}
                      setValue={setClosure}
                      label={"Rigid Box Closure"}
                    />
                  </div>
                )}

                {boxType === "rigidBox" && (
                  <div>
                    <DropDownFields
                      options={AssPassPaper}
                      value={asspassPaper}
                      setValue={setAsspassPaper}
                      label={"Inner Paper"}
                    />
                  </div>
                )}

                {boxType === "rigidBox" && (
                  <div>
                    <DropDownFields
                      options={BoardSizeOptions}
                      value={boardThickness}
                      setValue={setBoardThickness}
                      label={"Board Thickness"}
                    />
                  </div>
                )}

                {boxType === "rigidBox" && (
                  <div>
                    <DropDownFields
                      options={AsspassQualityOptions}
                      value={asspassQuality}
                      setValue={setAsspassQuality}
                      label={"Inner Paper Quality"}
                    />
                  </div>
                )}
                {boxType === "rigidBox" && closure === "topBottomCuff" && (
                  <div>
                    <InputTextField
                      value={cuffSize}
                      setValue={setCuffSize}
                      label={"Size of Visible Cuff"}
                    />
                  </div>
                )}

                {boxType === "corrugatedBox" && (
                  <div>
                    <DropDownFields
                      options={CorrugatedOptions}
                      value={closure}
                      setValue={setClosure}
                      label={"Corrugated Closure"}
                    />
                  </div>
                )}

                {boxType === "corrugatedBox" && (
                  <div>
                    <DropDownFields
                      options={KraftQualityOptions}
                      value={kraftQuality}
                      setValue={setKraftQuality}
                      label={"Kraft Quality"}
                    />
                  </div>
                )}

                {boxType === "paperBag" && (
                  <div>
                    <DropDownFields
                      options={PaperBagOptions}
                      value={closure}
                      setValue={setClosure}
                      label={"Paper Bag Closure"}
                    />
                  </div>
                )}

                {closure === "paperBag" && (
                  <div>
                    <DropDownFields
                      value={handle}
                      setValue={setHandle}
                      options={HandleOptions}
                      label={"Handle"}
                    />
                  </div>
                )}

                <DropDownFields
                  options={DimensionUnitOptions}
                  value={dimUnit}
                  setValue={setDimUnit}
                  label={"Dimension Unit :"}
                />
                <br />
                <InputTextField
                  value={length}
                  setValue={(value) => {
                    setLength(value);
                    setDim(`${value}*${breadth}*${height}`);
                  }}
                  label={"Length"}
                />

                <InputTextField
                  value={breadth}
                  setValue={(value) => {
                    setBreadth(value);
                    setDim(`${length}*${value}*${height}`);
                  }}
                  label={"Breadth"}
                />

                <InputTextField
                  value={height}
                  setValue={(value) => {
                    setHeight(value);
                    setDim(`${length}*${breadth}*${value}`);
                  }}
                  label={"Height"}
                />
                <InputTextField
                  value={qtt1}
                  setValue={setQtt1}
                  label={"Quantity 1"}
                />

                <InputTextField
                  value={qtt2}
                  setValue={setQtt2}
                  label={"Quantity 2"}
                />
                <InputTextField
                  value={qtt3}
                  setValue={setQtt3}
                  label={"Quantity 3"}
                />

                <DropDownFields
                  options={transportOptions}
                  value={transport}
                  setValue={setTransport}
                  label={"Transport :"}
                />

                <DropDownFields
                  options={PaperGSMOptions}
                  value={quality}
                  setValue={setQuality}
                  label={"Paper GSM :"}
                />
                <div className="regionAndInfoIcon">
                  <div>
                    <DropDownFields
                      value={region}
                      setValue={setRegion}
                      label={"Region :"}
                      options={regionOptions}
                    />
                  </div>
                  <div>
                    <i
                      onClick={handleIconHover}
                      id="infoId"
                      className="fa-solid fa-circle-info"
                      style={{ color: "#0a1529", fontSize: "25px" }}
                    ></i>
                  </div>
                </div>

                {isHovered ? <BoxTypeInfo /> : ""}
                <InputTextField
                  value={price1}
                  setValue={setPrice1}
                  label={"Price 1"}
                />

                <InputTextField
                  value={price2}
                  setValue={setPrice2}
                  label={"Price 2"}
                />

                <InputTextField
                  value={price3}
                  setValue={setPrice3}
                  label={"Price 3"}
                />

                <div class="input-box">
                  <label>Sale Date</label>
                  <input
                    type="date"
                    required
                    value={saleDate}
                    onChange={(e) => setSaleDate(e.target.value)}
                  />
                </div>

                <InputTextField
                  value={remarks}
                  setValue={setRemarks}
                  label={"Remarks/Additional Info"}
                />

                <br />
                <div className="applicationsGridContainer">
                  <label htmlFor="applications">Applications :</label>
                  <div className="applicationsGrid">
                    <ApplicationsCheckBoxes
                      applications={applications}
                      handleApplicationChange={handleApplicationChange}
                    />
                  </div>
                </div>
                {applications.Matte ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.Matte}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        Matte: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
                {applications.Gloss ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.Gloss}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        Gloss: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
                {applications.Gold ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.Gold}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        Gold: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
                {applications.Thermal ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.Thermal}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        Thermal: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
                {applications.Pearl ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.Pearl}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        Pearl: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
                {applications.SpotUV ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.SpotUV}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        SpotUV: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
                {applications.EmbossDia ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.EmbossDia}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        EmbossDia: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
                {applications.FoilingDia ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.FoilingDia}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        FoilingDia: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
                {applications.Foiling ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.Foiling}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        Foiling: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
                {applications.DripOffUV ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.DripOffUV}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        DripOffUV: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
                {applications.Varnish ? (
                  <DropDownFields
                    options={ApplicationsPercentage}
                    value={applicationsValue.Varnish}
                    setValue={(selectedOption) =>
                      setApplicationsValue((prevApplicationsValue) => ({
                        ...prevApplicationsValue,
                        Varnish: selectedOption,
                      }))
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <button type="submit">Punch Data</button>
          </form>
          {showImage ? (
            <div class="form-group">
              <label for="imageFiles">Select multiple image files:</label>
              <input
                type="file"
                id="imageFiles"
                name="images"
                multiple
                required
                onChange={handleMultipleChange}
              />
              <div className="buttonContainerInPunch">
                <button
                  type="submit"
                  onClick={() => submitPunchWithImages(receivedOrderId)}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div> */}
      </div>
    </div>
  );
};

export default InputField;
