export const CommercialStandardPaperSizes = [
    { value: "", label: "None"},
    { value: "A3", label: "A3"},
    { value: "A4", label: "A4"},
    { value: "A5", label: "A5"},
    { value: "A6", label: "A6"},
    { value: "4x6", label: "4x6"},
    { value: "5x7", label: "5x7"},
    { value: "5x9", label: "5x9"},
    { value: "4x7", label: "4x7"},
]