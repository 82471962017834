export const PaperBagGSM = [
  { value: "", label: "None" },
  { value: "80", label: "80 gsm" },
  { value: "100", label: "100 gsm" },
  { value: "120", label: "120 gsm" },
  { value: "150", label: "150 gsm" },
  { value: "180", label: "180 gsm" },
  { value: "200", label: "200 gsm" },
  { value: "250", label: "250 gsm" },
  { value: "300", label: "300 gsm" },
  { value: "350", label: "350 gsm" },
  { value: "400", label: "400 gsm" },
];
