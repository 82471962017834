import React from "react";
import UserDropdown from "../reusable/UserDropdown";
import "./CSDashboard.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CSDashboard = () => {
  const history = useHistory();

  const redirectToFinalDispatch = () => {
    history.push("/CSDashboard/FinalDispatch");
  };

  const redirectToBriefSheets = () => {
    history.push("/CSDashboard/BriefSheets");
  };

  const redirectToStatusPage = () => {
    history.push("/CSDashboard/StatusPage");
  };

  const redirectToGraphicDesigns = () => {
    history.push("/CSDashboard/GraphicDesigns");
  };

  const redirectToSampleDispatch = () => {
    history.push("/CSdashboard/SampleDispatch");
  };

  const redirectToCSQIS = () => {
    history.push("/CSDashboard/CSQIS");
  };

  const redirectToCSIvoice = () => {
    history.push("CSDashboard/CSInvoiceDetails");
  };

  return (
    <div>
      <div className="navbarLayer">
        <div className="logoContainer">
          <h2>Client-Servicing (CS) Dashboard</h2>
          <UserDropdown />
        </div>
      </div>
      <div className="CSFields">
        <div className="buttonContainerCS">
          <button
            className="dashboardButtonCS"
            onClick={() => redirectToBriefSheets()}
          >
            <i class="fa-solid fa-sheet-plastic" id="orderIcon"></i>
            Brief Sheets
          </button>
        </div>
        <div className="buttonContainerCS">
          <button
            onClick={() => redirectToStatusPage()}
            className="dashboardButtonCS"
          >
            <i class="fa-solid fa-signal" id="orderIcon"></i>
            Status Page
          </button>
        </div>
        <div className="buttonContainerCS">
          <button
            onClick={() => redirectToGraphicDesigns()}
            className="dashboardButtonCS"
          >
            <i class="fa-sharp fa-solid fa-marker" id="orderIcon"></i>
            Graphic Designs
          </button>
        </div>
        <div className="buttonContainerCS">
          <button
            onClick={() => redirectToSampleDispatch()}
            className="dashboardButtonCS"
          >
            <i class="fa-solid fa-vial" id="orderIcon"></i>
            Samples Dispatch
          </button>
        </div>
        <div className="buttonContainerCS">
          <button
            onClick={() => redirectToFinalDispatch()}
            className="dashboardButtonCS"
          >
            <i class="fa-solid fa-terminal" id="orderIcon"></i>
            Final Dispatch
          </button>
        </div>
        <div className="buttonContainerCS">
          <button
            onClick={() => redirectToCSQIS()}
            className="dashboardButtonCS"
          >
            <i class="fa-sharp fa-solid fa-quote-right" id="QISIcon"></i>
            QIS
          </button>
        </div>
        <div className="buttonContainerCS">
          <button
            onClick={() => redirectToCSIvoice()}
            className="dashboardButtonCS"
          >
            <i class="fa-solid fa-file-invoice" id="QISIcon"></i>
            Invoice Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default CSDashboard;
