import React, { useState, useContext, useRef } from "react";
import DropDownFields from "../../reusable/DropDownFields";
import InputTextField from "../../reusable/InputTextField";
import { CommercialClosuresOptions } from "../../DropDownOptions/boxClosureTypes/CommercialClosuresOptions";
import { CommercialQISContext } from "../../context/CommercialQISContext";
import { PosterTypeOptions } from "../../DropDownOptions/PosterTypesOptions";
import { PosterGummingTypes } from "../../DropDownOptions/PosterGummingTypes";
import { CommercialPaperGSMOptions } from "../../DropDownOptions/CommercialPaperGSMOptions";
import { PrintingColoursOptions } from "../../DropDownOptions/PrintingColoursOptions";
import { LaminationCommercialOptions } from "../../DropDownOptions/LaminationCommercialOptions";
import CommercialApplications from "../../DropDownOptions/CommercialApplications";
import { PosterLaminationOptions } from "../../DropDownOptions/PosterLaminationOptions";

const CommercialPoster = () => {
    const quotationRef = useRef(null);
    const [displayQuotation, setDisplayQuotation] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [displayOrderDetails, setDisplayOrderDetails] = useState(false);

    const employeeId = localStorage.getItem("uniqueId");
    const boxType = "commercialPrinting";

    const resetApplicationsValue = (appName) => {
        setApplicationsValue((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const handleApplicationChange = (event) => {
        const { name, checked } = event.target;
        setApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetApplicationsValue(name);
        }
    };

    const {
        closure, setClosure,
        length, setLength,
        breadth, setBreadth,
        dimUnit, setDimUnit,
        qtt1, setQtt1,
        qtt2, setQtt2,
        qtt3, setQtt3,
        quality, setQuality,
        lamination, setLamination,
        transport, setTransport,
        region, setRegion,
        applications, setApplications,
        applicationsValue, setApplicationsValue,
        printingColours, setPrintingColours,
        printingColoursInsidePaper, setPrintingColoursInsidePaper,
        doubleSide, setDoubleSide,
        diaCut, setDiaCut,
        posterLayout, setPosterLayout,
        posterGummingType, setPosterGummingType,
        dimensionType, setDimensionType,
        standardDimensions, setStandardDimensions,
    } = useContext(CommercialQISContext);

    return (
        <div>
            {/* 
            1. Paper GSM
            2. No. of Printing Colours
            3. Poster Layout
            3. Gumming Type
            4. Laminations
            5. Applcations
            */}
            <div className="column">
                <div className="input-box">
                    <DropDownFields
                        label={"Paper GSM"}
                        value={quality}
                        setValue={setQuality}
                        options={CommercialPaperGSMOptions}
                    />
                </div>
                <div className="input-box">
                    <DropDownFields
                        label={"No. Of Printing Colours"}
                        value={printingColours}
                        setValue={setPrintingColours}
                        options={PrintingColoursOptions}
                    />
                </div>
            </div>
            {
                (printingColours === "5" ||
                printingColours === "6") && (
                    <div style={{margin:"30px"}}>
                        <b style={{color:"#800000"}}>Note : </b>
                        More than 4 colours is considered as Pantone Colours. 
                        Upto 4 colours is considered as CMYK.</div>
                )
            }
            <div className="column">
                <div className="input-box">
                    <DropDownFields
                        label={"Poster Layout"}
                        options={PosterTypeOptions}
                        value={posterLayout}
                        setValue={setPosterLayout}
                    />
                </div>
                <div className="input-box">
                    <DropDownFields
                        label={"Gumming Type"}
                        options={PosterGummingTypes}
                        value={posterGummingType}
                        setValue={setPosterGummingType}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="input-box">
                    <DropDownFields
                        value={lamination}
                        setValue={setLamination}
                        options={PosterLaminationOptions}
                        label={"Lamination"}
                    />
                </div>
                <div className="applicationsGridContainer">
                    <label htmlFor="applications">Applications : </label>
                    <div className="commercialApplicationsGrid">
                        <CommercialApplications
                            applications={applications}
                            handleApplicationChange={handleApplicationChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CommercialPoster;