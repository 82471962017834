import React, { useContext } from "react";
import { QISOrdersContext } from "../context/QISOrdersContext";
import "../ReusableCSS/QisOrdersDisplay.css";

// no work practised sql;

const QisOrdersDisplay = ({ boxType }) => {
  const {
    closure,
    dim,
    dimUnit,
    quality,
    region,
    applications,
    applicationsValue,
    transport,
    cavity,
    asspassPaper,
    fbbPaperType,
    handleAllClosureType,
    asspassQuality,
    paperBagPaperType,
    boardThickness,
    doubleSide,
    cuffSize,
    lamination,
    kraftQuality,
  } = useContext(QISOrdersContext);
  return (
    <div>
      <div className="outerOuterContainer">
        <div className="outerContainer">
          <div className="displayContainer">
            <h3>Order Details</h3>
            <div>
              <b>Box Type : </b> {boxType}
            </div>

            <div>
              <b>Closure :</b> {closure}
            </div>
            {boxType === "fbb" && (
              <div>
                {fbbPaperType && (
                  <div>
                    <b>Paper Type : </b> {fbbPaperType}
                  </div>
                )}
              </div>
            )}
            {boxType === "corrugatedBox" && (
              <div>
                {kraftQuality && (
                  <div>
                    <b>Kraft Quality : </b> {kraftQuality}
                  </div>
                )}
              </div>
            )}
            {boxType === "rigidBox" && (
              <div>
                {asspassPaper && (
                  <div>
                    <b>Inner Paper : </b> {asspassPaper}
                  </div>
                )}
                {asspassQuality && (
                  <div>
                    <b>Inner Paper Quality : </b> {asspassQuality}
                  </div>
                )}
                {boardThickness && (
                  <div>
                    <b>Board Thickness: </b> {boardThickness}
                  </div>
                )}
                {cuffSize && (
                  <div>
                    <b>Cuff Size:</b> {cuffSize}
                  </div>
                )}
              </div>
            )}
            {boxType === "paperBag" && (
              <div>
                {paperBagPaperType && (
                  <div>
                    <b>Paper Bag paper Type : </b>
                    {paperBagPaperType}
                  </div>
                )}
              </div>
            )}

            {handleAllClosureType && (
              <div>
                <b>Handle Type : </b> {handleAllClosureType}
              </div>
            )}
            <div>
              <b>Dimension : </b> {dim} {dimUnit}
            </div>
            <div>
              <b>Paper GSM :</b> {quality}
            </div>
            <div>
              <b>Region : </b> {region}
            </div>
            <div>
              <b>Transport : </b>
              {transport}
            </div>
            <div>
              <b>Double Side Printing : </b>
              {doubleSide}
            </div>
            <div>
              <b>Cavity: </b>
              {cavity}
            </div>
            <div>
              <b>Lamination: </b>
              {lamination}
            </div>
            <div>
              <b> Applications : </b>
              <div className="applicationsValue">
                {Object.keys(applications).map((app) => (
                  <div key={app}>{applications[app] && `${app}`}</div>
                ))}
              </div>
            </div>

            {applicationsValue && (
              <div>
                <b>Applications Value:</b>
                <div className="applicationsValue">
                  {Object.keys(applicationsValue).map((app) => (
                    <div key={app}>
                      {applicationsValue[app] &&
                        `${app}: ${applicationsValue[app]}%`}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QisOrdersDisplay;
