import React from "react";
import "./RegionModal.css";

const RegionModal = ({ showModal }) => {
  return (
    <>
      <div className="modal-wrapper" onClick={showModal}></div>
      <div className="modal-container">
        <br />

        <div className="box">
          <b>East:</b>
          <ul>
            <li>West Bengal</li>
            <li>Odisha (formerly known as Orissa)</li>
            <li>Bihar</li>
            <li>Jharkhand</li>
            <li>Chhattisgarh</li>
            <li>Sikkim</li>
            <li>Assam</li>
            <li>Manipur</li>
            <li>Mizoram</li>
            <li>Tripura</li>
            <li>Nagaland</li>
            <li>Meghalaya</li>
            <li>Arunachal Pradesh</li>
          </ul>

          <b>West:</b>
          <ul>
            <li>Maharashtra</li>
            <li>Gujarat</li>
            <li>Rajasthan</li>
            <li>Goa</li>
          </ul>

          <b>North:</b>
          <ul>
            <li>Jammu and Kashmir</li>
            <li>Himachal Pradesh</li>
            <li>Uttarakhand</li>
            <li>Punjab</li>
            <li>Haryana</li>
            <li>Delhi (National Capital Territory)</li>
            <li>Uttar Pradesh</li>
            <li>Madhya Pradesh</li>
          </ul>

          <b>South:</b>
          <ul>
            <li>Tamil Nadu</li>
            <li>Kerala</li>
            <li>Karnataka</li>
            <li>Andhra Pradesh</li>
            <li>Telangana</li>
            <li>Puducherry (Union Territory)</li>
            <li>Lakshadweep (Union Territory)</li>
            <li>Andaman and Nicobar Islands (Union Territory)</li>
          </ul>
        </div>

        <div className="modal-btn" onClick={showModal}>
          Close
        </div>
      </div>
    </>
  );
};

export default RegionModal;
