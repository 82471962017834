import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserDropdown from "../reusable/UserDropdown";
import {
  getCSFinalDispatchOrders,
  EndOfTheSeasonSale,
} from "../apis/apiClients";
import { BASE_URL } from "../constants/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchComponent from "../searchBar/SearchComponent";

const FinalDispatch = () => {
  const [orders, setOrders] = useState([]);

  const history = useHistory();

  const handlePrev = () => {
    history.push("/CSDashboard");
  };

  const closeThisSale = async (orderId) => {
    try {
      const response = await EndOfTheSeasonSale(orderId);
      if (response.status === 200) {
        toast.success("This Sale has been closed! Hurrayy!");
      }
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  const uniqueId = localStorage.getItem("uniqueId");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getCSFinalDispatchOrders(uniqueId);
        console.log(response, "response");

        if (response) {
          const orders = response.orders;
          setOrders(orders);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrders();
  }, [uniqueId]);

  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">Final Dispatch In CS</b>
            <UserDropdown />
          </div>
        </div>
      </div>
      <h2>Number of Orders : {orders.length}</h2>
      <SearchComponent orders={orders} setOrders={setOrders} />
      <div className="ordersContainer">
        {orders.length === 0 ? (
          <h3>No orders found.</h3>
        ) : (
          orders.map((order) => (
            <div className="ordersItem" key={order._id}>
              <div className="orderBoxId">
                <p
                  style={{
                    color: "#800000",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  ORDER ID: {order.orderId}
                </p>
              </div>
              <p>
                <b>Client Name : </b> {order.clientName}
              </p>
              <p>
                <b>Client Email : </b> {order.clientEmail}
              </p>
              <p>
                <b>Client Address : </b> {order.clientAddress}
              </p>
              <p>
                <b>Client Contact : </b> {order.clientContact}
              </p>
              <p>
                <b>Client Pincode :</b> {order.clientPincode}
              </p>
              <p>
                <b>Client GST : </b> {order.clientGST}
              </p>
              <p>
                <b>Box Type : </b> {order.boxType}
              </p>
              <p>
                <b>Closure :</b> {order.closure}
              </p>
              <p>
                <b>Dimension : </b> {order.dim} {order.dimUnit}
              </p>

              <p>
                <b>Quantity 1 : </b>
                {order.qtt1}
              </p>
              <p>
                <b>Quantity 2 : </b>
                {order.qtt2}
              </p>
              <p>
                <b>Quantity 3 :</b> {order.qtt3}
              </p>
              <p>
                <b>Paper GSM : </b> {order.quality}
              </p>
              <p>
                <b>Region : </b> {order.region}
              </p>
              <p>
                <b>Transport :</b> {order.transport}
              </p>

              <div className="applicationsValueContainer">
                <p>
                  Applications :
                  <div className="applicationsValue">
                    {Object.keys(order.applicationsValue).map((app) => (
                      <div key={app}>
                        {order.applicationsValue[app] &&
                          ` ${app} : ${order.applicationsValue[app]}%`}
                      </div>
                    ))}
                  </div>
                </p>
                <br />
                <p>
                  <b>Images</b>
                </p>
                {order.imageUrls.map((imageUrl, index) => (
                  <a
                    href={`${BASE_URL}${imageUrl.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="viewPdfButton" key={index}>
                      <a
                        rel="noreferrer"
                        href={`${BASE_URL}${imageUrl}`}
                        target="_blank"
                        className="linkDecoration"
                      >
                        <div className="imageContainer" key={index}>
                          <img
                            key={imageUrl.index}
                            src={`${BASE_URL}${imageUrl}`}
                            alt="ImagesUpload"
                            className="hoverImage"
                            style={{
                              width: "120px",
                              height: "100px",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </a>
                    </button>
                  </a>
                ))}
              </div>

              <br />
              <div className="buttonContainer">
                <button
                  onClick={() => closeThisSale(order.orderId)}
                  className="rejectButtonSMPending"
                >
                  <i class="fa-solid fa-circle-xmark"></i> Close this sale
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default FinalDispatch;
