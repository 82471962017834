export const CommercialMaplithoGSMOptions = [
    { value: "", label: "None"},
    { value: "54", label: "54"},
    { value: "60", label: "60"},
    { value: "70", label: "70"},
    { value: "80", label: "80"},
    { value: "90", label: "90"},
    { value: "100", label: "100"},
    { value: "120", label: "120"},
    { value: "140", label: "140"},
];