import React, { useContext } from "react";
import "./QuotationPriceDisplay.css";
import { UniqueIdContext } from "../context/UniqueIdContext";

const QuotationPriceDisplay = ({ qtt1, qtt2, qtt3 }) => {
  const { price1, price2, price3 } = useContext(UniqueIdContext);

  const roundedPrice1 = Number(price1).toFixed(3);
  const roundedPrice2 = Number(price2).toFixed(3);
  const roundedPrice3 = Number(price3).toFixed(3);

  return (
    <div className="cardContainer">
      <div className="card">
        <div className="cardContent">
          <div className="quotationTitle">QUOTATION</div>
          <div className="field">
            <label>Price for {qtt1} Quantity: </label>
            <span>₹ {roundedPrice1}</span>
          </div>
          <div className="field">
            <label>Price for {qtt2} Quantity: </label>
            <span>₹ {roundedPrice2}</span>
          </div>
          <div className="field">
            <label>Price for {qtt3} Quantity: </label>
            <span>₹ {roundedPrice3}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationPriceDisplay;
