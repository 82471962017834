import React, { useContext } from "react";
import InputTextField from "../reusable/InputTextField";
import { ProformaInvoiceContext } from "../context/ProformaInvoiceContext";

const ClientDetailsInvoice = () => {
  const {
    clientName,
    setClientName,
    clientEmail,
    setClientEmail,
    clientGST,
    setClientGST,
    clientContact,
    setClientContact,
    billingAddress,
    setBillingAddress,
  } = useContext(ProformaInvoiceContext);
  return (
    <div>
      <fieldset>
        <InputTextField
          value={clientName}
          setValue={setClientName}
          label={"Client Name"}
        />
        <InputTextField
          value={clientEmail}
          setValue={setClientEmail}
          label={"Client Email"}
        />

        <InputTextField
          value={clientContact}
          setValue={setClientContact}
          label={"Client Contact Number"}
        />

        <InputTextField
          value={clientGST}
          setValue={setClientGST}
          label={"Client GST"}
        />

        <InputTextField
          value={billingAddress}
          setValue={setBillingAddress}
          label={"Billing Address"}
        />
      </fieldset>
    </div>
  );
};

export default ClientDetailsInvoice;
