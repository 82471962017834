import React, {useContext, useState, useRef} from "react";
import { CommercialFolderTypeOptions } from "../../DropDownOptions/CommercialFolderTypeOptions";
import DropDownFields from "../../reusable/DropDownFields";
import InputTextField from "../../reusable/InputTextField";
import { CommercialQISContext } from "../../context/CommercialQISContext";
import { CommercialFolderPouchSizeOptions } from "../../DropDownOptions/CommercialFolderPouchSizeOptions";
import { PrintingColoursOptionsInsidePaper } from "../../DropDownOptions/PrintingColoursOptionsInsidePaper";
import CommercialApplications from "../../DropDownOptions/CommercialApplications";
import { LaminationCommercialOptions } from "../../DropDownOptions/LaminationCommercialOptions";
import { CommercialPaperGSMOptions } from "../../DropDownOptions/CommercialPaperGSMOptions";
import { PrintingColoursOptions } from "../../DropDownOptions/PrintingColoursOptions";

const CommercialFolder = () => {
    const quotationRef = useRef(null);
    const [displayQuotation, setDisplayQuotation] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [displayOrderDetails, setDisplayOrderDetails] = useState(false);

    const employeeId = localStorage.getItem("uniqueId");
    const boxType = "commercialPrinting";

    const {
        closure, setClosure,
        length, setLength,
        breadth, setBreadth,
        dimUnit, setDimUnit,
        qtt1, setQtt1,
        qtt2, setQtt2,
        qtt3, setQtt3,
        quality, setQuality,
        lamination, setLamination,
        transport, setTransport,
        region, setRegion,
        applications, setApplications,
        applicationsValue, setApplicationsValue,
        printingColours, setPrintingColours,
        printingColoursInsidePaper, setPrintingColoursInsidePaper,
        doubleSide, setDoubleSide,
        diaCut, setDiaCut,
        posterLayout, setPosterLayout,
        posterGummingType, setPosterGummingType,
        dimensionType, setDimensionType,
        standardDimensions, setStandardDimensions,

        folderType, setFolderType,
        pouchSize, setPouchSize,
        customPouchSize, setCustomPouchSize,

        brochureType, setBrochureType,
        bookletPages, setbookletPages,
        bookletBindingType, setBookletBindingType,
        
        bookletCoverPaperType, setBookletCoverPaperType,
        bookletCoverPaperGSM, setBookletCoverPaperGSM,
        bookletCoverOutsideColours, setBookletCoverOutsideColours,
        bookletCoverLamination, setBookletCoverLamination,
        bookletCoverDoubleSide, setBookletCoverDoubleSide,
        bookletCoverInsideColours, setBookletCoverInsideColours,
        bookletCoverApplications, setBookletCoverApplications,
        
        bookletInnerPaperType, setBookletInnerPaperType,
        bookletInnerPaperGSM, setBookletInnerPaperGSM,
        bookletInnerOutsideColours, setBookletInnerOutsideColours,
        bookletInnerLamination, setBookletInnerLamination,
        bookletInnerDoubleSide, setBookletInnerDoubleSide,
        bookletInnerInsideColours, setBookletInnerInsideColours,
        bookletInnerApplications, setBookletInnerApplications,
        prescriptionPadType, setPrescriptionPadType,

    } = useContext(CommercialQISContext);

    const resetApplicationsValue = (appName) => {
        setApplicationsValue((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const resetCoverApplicationsValue = (appName) => {
        setBookletCoverApplications((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const resetInnerApplicationsValue = (appName) => {
        setBookletInnerApplications((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };
    
    const handleApplicationChange = (event) => {
        const { name, checked } = event.target;
        setApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetApplicationsValue(name);
        }
    };

    const handleCoverApplicationChange = (event) => {
        const { name, checked } = event.target;
        setBookletCoverApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetCoverApplicationsValue(name);
        }
    };

    const handleInnerApplicationChange = (event) => {
        const { name, checked } = event.target;
        setBookletInnerApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetInnerApplicationsValue(name);
        }
    };

    const handleDimensionChange = (selectedOption) => {
        setStandardDimensions(selectedOption);
        if (selectedOption === "A3") {
            setDimUnit("mm");
            setLength("297");
            setBreadth("420");
        }
        else if (selectedOption === "A4") {
            setDimUnit("mm");
            setLength("210");
            setBreadth("297");
        }
        else if (selectedOption === "A5") {
            setDimUnit("mm");
            setLength("148");
            setBreadth("210");
        }
        else if (selectedOption === "A6") {
            setDimUnit("mm");
            setLength("105");
            setBreadth("148");
        }
        else if (selectedOption === "4x6") {
            setDimUnit("inch");
            setLength("4");
            setBreadth("6");
        }
        else if (selectedOption === "5x7") {
            setDimUnit("inch");
            setLength("5");
            setBreadth("7");
        }
        else if (selectedOption === "5x9") {
            setDimUnit("inch");
            setLength("5");
            setBreadth("9");
        }
        else if (selectedOption === "4x7") {
            setDimUnit("inch");
            setLength("4");
            setBreadth("7");
        }
        else {
            // Reset length and breadth if option is not "A4"
            setDimUnit("");
            setLength("");
            setBreadth("");
        }
    };

    return (
        <div>
            {/* 
            1. Folder Type
            2. Pouch Size
            3. Custom Pouch Size
            4. Double Side Printing
            5. Dia Cut
            5. Lamination
            6. Applications
            */}
            <div className="input-box">
                <DropDownFields
                    label={"Folder Type"}
                    value={folderType}
                    setValue={setFolderType}
                    options={CommercialFolderTypeOptions}
                />
            </div>
            <div className="column">
                <div className="input-box">
                    <DropDownFields
                        label={"Pouch Size"}
                        value={pouchSize}
                        setValue={setPouchSize}
                        options={CommercialFolderPouchSizeOptions}
                    />
                </div>
                {
                    (pouchSize === "custom") && (
                        <div className="input-box">
                            <InputTextField
                                value={customPouchSize}
                                setValue={setCustomPouchSize}
                                label={"Height of Pouch (inch)"}
                            />
                        </div>
                    )
                }
            </div>
            <div className="column">
                <div className="input-box">
                    <DropDownFields
                        label={"Paper GSM"}
                        value={quality}
                        setValue={setQuality}
                        options={CommercialPaperGSMOptions}
                    />
                </div>
                <div className="input-box">
                    <DropDownFields
                        label={"No. Of Printing Colours"}
                        value={printingColours}
                        setValue={setPrintingColours}
                        options={PrintingColoursOptions}
                    />
                </div>
            </div>
            <label style={{marginTop:"10px"}}>Back Side Printing</label>
            <div className="column">
                <div className="input-box">
                    <div className="doubleSidePrinting">
                        <div class="radio-label">
                            <input
                                type="radio"
                                name="doubleSidePrinting"
                                id="yes"
                                value="yes"
                                checked={doubleSide === "yes"}
                                style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                onChange={(e) => setDoubleSide(e.target.value)}
                            />
                            <label className="labelNo" for="yes">
                                Yes
                            </label>
                        </div>
                        <div className="radio-label">
                            <input
                                type="radio"
                                name="doubleSidePrinting"
                                id="no"
                                value="no"
                                checked={doubleSide === "no"}
                                style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                onChange={(e) => setDoubleSide(e.target.value)}
                            />
                            <label className="labelNo" for="no">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                <div className="input-box">
                    {doubleSide==="yes" && (
                        <DropDownFields
                            options={PrintingColoursOptionsInsidePaper}
                            value={printingColoursInsidePaper}
                            setValue={setPrintingColoursInsidePaper}
                            label={"No. of Colours for Back Print"}
                        />
                    )}
                </div>
            </div>
            {
                (printingColoursInsidePaper === "5" ||
                printingColoursInsidePaper === "6") && (
                    <div style={{margin:"30px"}}>
                        <b style={{color:"#800000"}}>Note : </b>
                        More than 4 colours is considered as Pantone Colours. 
                        Upto 4 colours is considered as CMYK.
                    </div>
                )
            }
            <div className="column">
                <div className="input-box">
                    <label>Dia Cut</label>
                    <div className="diaCutRadios">
                        <div className="radio-label">
                            <input
                                type="radio"
                                name="diaCut"
                                id="diaCutYes"
                                value="yes"
                                checked={diaCut==="yes"}
                                style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                onChange={(e) => setDiaCut(e.target.value)}
                            />
                            <label style={{marginTop: "26px", marginBottom: "8px", marginLeft: "6px"}} className="labelNoDiaCut" for="diaCutYes">
                                Yes
                            </label>
                        </div>
                        <div className="radio-label">
                            <input
                                type="radio"
                                name="diaCut"
                                id="diaCutNo"
                                value="no"
                                checked={diaCut==="no"}
                                style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                onChange={(e) => setDiaCut(e.target.value)}
                            />
                            <label style={{marginTop: "26px", marginBottom: "8px", marginLeft: "6px"}} className="labelNoDiaCut" for="diaCutNo">
                                No
                            </label>
                        </div>
                    </div>
                </div>
                <div className="input-box">
                    <DropDownFields
                        value={lamination}
                        setValue={setLamination}
                        options={LaminationCommercialOptions}
                        label={"Lamination"}
                    />
                </div>
            </div>
            <div className="applicationsGridContainer">
                <label htmlFor="applications">Applications : </label>
                <div className="commercialApplicationsGrid">
                    <CommercialApplications
                        applications={applications}
                        handleApplicationChange={handleApplicationChange}
                    />
                </div>
            </div>
        </div>
    )
};

export default CommercialFolder;