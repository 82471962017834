import React, {useContext, useState, useRef } from "react";

import DropDownFields from "../../reusable/DropDownFields";
import InputTextField from "../../reusable/InputTextField";
import { CommercialQISContext } from "../../context/CommercialQISContext";
import { LaminationCommercialOptions } from "../../DropDownOptions/LaminationCommercialOptions";
import CommercialApplications from "../../DropDownOptions/CommercialApplications";
import { PrintingColoursOptions } from "../../DropDownOptions/PrintingColoursOptions";
import { PrintingColoursOptionsInsidePaper } from "../../DropDownOptions/PrintingColoursOptionsInsidePaper";
import { CommercialPaperTypeOptions } from "../../DropDownOptions/CommercialPaperTypeOptions";
import { CommercialArtPaperGSMOptions } from "../../DropDownOptions/CommercialArtPaperGSMOptions";
import { CommercialArtCardGSMOptions } from "../../DropDownOptions/CommercialArtCardGSMOptions";
import { CommercialMaplithoGSMOptions } from "../../DropDownOptions/CommercialMaplithoGSMOptions";
import { CommercialBookletBindingTypeOptions } from  "../../DropDownOptions/CommercialBookletBindingTypeOptions";

const CommercialBooklet = () => {
    const quotationRef = useRef(null);
    const [displayQuotation, setDisplayQuotation] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [displayOrderDetails, setDisplayOrderDetails] = useState(false);

    const employeeId = localStorage.getItem("uniqueId");
    const boxType = "commercialPrinting";

    const {
        closure, setClosure,
        length, setLength,
        breadth, setBreadth,
        dimUnit, setDimUnit,
        qtt1, setQtt1,
        qtt2, setQtt2,
        qtt3, setQtt3,
        quality, setQuality,
        lamination, setLamination,
        transport, setTransport,
        region, setRegion,
        applications, setApplications,
        applicationsValue, setApplicationsValue,
        printingColours, setPrintingColours,
        printingColoursInsidePaper, setPrintingColoursInsidePaper,
        doubleSide, setDoubleSide,
        diaCut, setDiaCut,
        posterLayout, setPosterLayout,
        posterGummingType, setPosterGummingType,
        dimensionType, setDimensionType,
        standardDimensions, setStandardDimensions,

        folderType, setFolderType,
        pouchSize, setPouchSize,
        customPouchSize, setCustomPouchSize,

        brochureType, setBrochureType,
        bookletPages, setbookletPages,
        bookletBindingType, setBookletBindingType,
        
        bookletCoverPaperType, setBookletCoverPaperType,
        bookletCoverPaperGSM, setBookletCoverPaperGSM,
        bookletCoverOutsideColours, setBookletCoverOutsideColours,
        bookletCoverLamination, setBookletCoverLamination,
        bookletCoverDoubleSide, setBookletCoverDoubleSide,
        bookletCoverInsideColours, setBookletCoverInsideColours,
        bookletCoverApplications, setBookletCoverApplications,
        
        bookletInnerPaperType, setBookletInnerPaperType,
        bookletInnerPaperGSM, setBookletInnerPaperGSM,
        bookletInnerOutsideColours, setBookletInnerOutsideColours,
        bookletInnerLamination, setBookletInnerLamination,
        bookletInnerDoubleSide, setBookletInnerDoubleSide,
        bookletInnerInsideColours, setBookletInnerInsideColours,
        bookletInnerApplications, setBookletInnerApplications,
        prescriptionPadType, setPrescriptionPadType,

    } = useContext(CommercialQISContext);

    const resetApplicationsValue = (appName) => {
        setApplicationsValue((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const resetCoverApplicationsValue = (appName) => {
        setBookletCoverApplications((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const resetInnerApplicationsValue = (appName) => {
        setBookletInnerApplications((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };
    
    const handleApplicationChange = (event) => {
        const { name, checked } = event.target;
        setApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetApplicationsValue(name);
        }
    };

    const handleCoverApplicationChange = (event) => {
        const { name, checked } = event.target;
        setBookletCoverApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetCoverApplicationsValue(name);
        }
    };

    const handleInnerApplicationChange = (event) => {
        const { name, checked } = event.target;
        setBookletInnerApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetInnerApplicationsValue(name);
        }
    };

    return (
        <div>
            <label className="cover-inner-section-title" style={{color:"#800000"}}>
                Cover
            </label>
            <div className="booklet-cover-inner-container">
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            options={CommercialPaperTypeOptions}
                            label={"Cover Paper Type"}
                            value={bookletCoverPaperType}
                            setValue={setBookletCoverPaperType}
                        />
                    </div>
                    <div className="input-box">
                        {
                            (bookletCoverPaperType === "artPaper") && (
                                <DropDownFields
                                    options={CommercialArtPaperGSMOptions}
                                    label={"Art Paper GSM"}
                                    value={bookletCoverPaperGSM}
                                    setValue={setBookletCoverPaperGSM}
                                />
                            )
                        }
                        {
                            (bookletCoverPaperType === "artCard") && (
                                <DropDownFields
                                    options={CommercialArtCardGSMOptions}
                                    label={"Art Card GSM"}
                                    value={bookletCoverPaperGSM}
                                    setValue={setBookletCoverPaperGSM}
                                />
                            )
                        }
                        {
                            (bookletCoverPaperType === "maplitho") && (
                                <DropDownFields
                                    options={CommercialMaplithoGSMOptions}
                                    label={"Maplitho GSM"}
                                    value={bookletCoverPaperGSM}
                                    setValue={setBookletCoverPaperGSM}
                                />
                            )
                        }
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            options={PrintingColoursOptions}
                            label={"Outside Colours"}
                            value={bookletCoverOutsideColours}
                            setValue={setBookletCoverOutsideColours}
                        />
                    </div>
                    <div className="input-box">
                        <DropDownFields
                            options={LaminationCommercialOptions}
                            label={"Lamination"}
                            value={bookletCoverLamination}
                            setValue={setBookletCoverLamination}                                                    
                        />
                    </div>
                </div>
                <label>Double side Printing</label>
                <div className="column">
                    <div className="input-box">
                        <div className="doubleSidePrinting">
                            <div class="cover-radio-label">
                                <input
                                    type="radio"
                                    name="bookletCoverDoubleSidePrinting"
                                    id="coverYes"
                                    value="yes"
                                    checked={bookletCoverDoubleSide === "yes"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setBookletCoverDoubleSide(e.target.value)}
                                />
                                <label className="bookletCoverLabelNo" for="coverYes">
                                    Yes
                                </label>
                            </div>
                            <div className="cover-radio-label">
                                <input
                                    type="radio"
                                    name="bookletCoverDoubleSidePrinting"
                                    id="coverNo"
                                    value="no"
                                    checked={bookletCoverDoubleSide === "no"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setBookletCoverDoubleSide(e.target.value)}
                                />
                                <label className="bookletCoverLabelNo" for="coverNo">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="input-box">
                        {bookletCoverDoubleSide==="yes" && (
                            <DropDownFields
                            options={PrintingColoursOptionsInsidePaper}
                            value={bookletCoverInsideColours}
                            setValue={setBookletCoverInsideColours}
                            label={"No. of Colours for Inside Print"}
                            />
                        )}
                    </div>
                </div>
                <div className="applicationsGridContainer">
                    <label htmlFor="applications">Applications : </label>
                    <div className="commercialApplicationsGrid">
                        <CommercialApplications
                            applications={bookletCoverApplications}
                            handleApplicationChange={handleCoverApplicationChange}
                        />
                    </div>
                </div>
            </div>
            
            <label className="cover-inner-section-title" style={{color:"#800000"}}>
                Inner
            </label>
            <div className="booklet-cover-inner-container">
                <div className="input-box">
                    <InputTextField
                        value={bookletPages}
                        setValue={setbookletPages}
                        label={"No. of Inner Pages"}
                    />
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            options={CommercialPaperTypeOptions}
                            label={"Inner Paper Type"}
                            value={bookletInnerPaperType}
                            setValue={setBookletInnerPaperType}
                        />
                    </div>
                    <div className="input-box">
                        {
                            (bookletInnerPaperType === "artPaper") && (
                                <DropDownFields
                                    options={CommercialArtPaperGSMOptions}
                                    label={"Art Paper GSM"}
                                    value={bookletInnerPaperGSM}
                                    setValue={setBookletInnerPaperGSM}
                                />
                            )
                        }
                        {
                            (bookletInnerPaperType === "artCard") && (
                                <DropDownFields
                                    options={CommercialArtCardGSMOptions}
                                    label={"Art Card GSM"}
                                    value={bookletInnerPaperGSM}
                                    setValue={setBookletInnerPaperGSM}
                                />
                            )
                        }
                        {
                            (bookletInnerPaperType === "maplitho") && (
                                <DropDownFields
                                    options={CommercialMaplithoGSMOptions}
                                    label={"Maplitho GSM"}
                                    value={bookletInnerPaperGSM}
                                    setValue={setBookletInnerPaperGSM}
                                />
                            )
                        }
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            options={PrintingColoursOptions}
                            label={"Outside Colours"}
                            value={bookletInnerOutsideColours}
                            setValue={setBookletInnerOutsideColours}
                        />
                    </div>
                    <div className="input-box">
                        <DropDownFields
                            options={LaminationCommercialOptions}
                            label={"Lamination"}
                            value={bookletInnerLamination}
                            setValue={setBookletInnerLamination}
                        />
                    </div>
                </div>
                <label>Double side Printing</label>
                <div className="column">
                    <div className="input-box">
                        <div className="doubleSidePrinting">
                            <div class="radio-label">
                                <input
                                    type="radio"
                                    name="bookletInnerDoubleSidePrinting"
                                    id="innerYes"
                                    value="yes"
                                    checked={bookletInnerDoubleSide === "yes"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setBookletInnerDoubleSide(e.target.value)}
                                />
                                <label className="bookletInnerLabelNo" for="innerYes">
                                    Yes
                                </label>
                            </div>
                            <div className="radio-label">
                                <input
                                    type="radio"
                                    name="bookletInnerDoubleSidePrinting"
                                    id="innerNo"
                                    value="no"
                                    checked={bookletInnerDoubleSide === "no"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setBookletInnerDoubleSide(e.target.value)}
                                />
                                <label className="bookletInnerLabelNo" for="innerNo">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="input-box">
                        {bookletInnerDoubleSide==="yes" && (
                            <DropDownFields
                            options={PrintingColoursOptionsInsidePaper}
                            value={bookletInnerInsideColours}
                            setValue={setBookletInnerInsideColours}
                            label={"No. of Colours for Inside Print"}
                            />
                        )}
                    </div>
                </div>
                <div className="applicationsGridContainer">
                    <label htmlFor="applications">Applications : </label>
                    <div className="commercialApplicationsGrid">
                        <CommercialApplications
                            applications={bookletInnerApplications}
                            handleApplicationChange={handleInnerApplicationChange}
                        />
                    </div>
                </div>
            </div>
            <div className="input-box">
                <DropDownFields
                    label={"Type of Binding"}
                    options={CommercialBookletBindingTypeOptions}
                    value={bookletBindingType}
                    setValue={setBookletBindingType}
                />
            </div>
        </div>
    )
};

export default CommercialBooklet;