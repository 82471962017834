export const CorrugatedOptions = [
  { value: "", label: "None" },
  // { value: "mailerBox", label: "Mailer Box" },
  // { value: "topBottom", label: "Top Bottom" },
  // { value: "topBottomShoeBox", label: "Top Bottom Shoe Box" },
  // { value: "tuckIn", label: "Tuck In" },
  // { value: "tamperEvident", label: "Tamper Evident Box" },
  // { value: "rsc", label: "RSC" },
  // { value: "easyCarry", label: "Easy Carry" },
  // { value: "doubleLock", label: "Double Lock" },
  // { value: "pizzaBox", label: "Pizza Box" },

  { value: "topBottom", label: "Top Bottom Closure"},

  { value: "cbe189", label: "Apparel - Shoe Box With Nylon Thread - CBE189" },
  { value: "cbe117", label: "Apparel - Custom Corrugated Mailer Box - CBE117" },
  { value: "cbe491", label: "Apparel - Biodegradable Apparel Packaging - CBE491" },
  { value: "cbe107", label: "Apparel - Custom Shoe Box Packging - CBE107" },
  
  { value: "fbe420", label: "Bakery and Cakes -	Retail Gable Box - FBE420" },
  
  { value: "cbe385", label: "Cosmetics - Sustainable Beauty Packaging - CBE385" },
  
  { value: "cbe371", label: "Ecommerce - Custom Display Mailer Box - CBE371" },
  { value: "cbe376", label: "Ecommerce - Custom Triangle Mailing Tube - CBE376" },
  { value: "cbe383", label: "Ecommerce - Custom Shipping Box - CBE383" },
  { value: "cbe384", label: "Ecommerce - Full Overlap Box - CBE384" },
  { value: "cbe382", label: "Ecommerce - Shipping Shoe Box - CBE382" },
  { value: "cbe381", label: "Ecommerce - Custom Book Wrap Mailer Box - CBE381" },
  { value: "cbe380", label: "Ecommerce - Custom Flower Box - CBE380" },
  { value: "cbe379", label: "Ecommerce - Custom Letterbox - CBE379" },
  { value: "cbe378", label: "Ecommerce - Custom Sqaure Mailing Tube - CBE378" },
  { value: "cbe375", label: "Ecommerce - Custom Returnable Postal Box - CBE375" },
  { value: "cbe374", label: "Ecommerce - Mailer Gift Box - CBE374" },
  { value: "cbe373", label: "Ecommerce - Custom End Mailer Box - CBE373" },
  { value: "cbe372", label: "Ecommerce - Custom Sqaure Mailer Box - CBE372" },
  { value: "c002", label: "Ecommerce - Roll End Tuck Top Corrugated Box - C002" },
  { value: "c003", label: "Ecommerce - Roll End Tuck Front Corrugated Box - C003" },
  { value: "c004", label: "Ecommerce - Roll End 3 Flap Lock Corrugated Box - C004" },
  { value: "c005", label: "Ecommerce - Roll Ends With Lid - C005" },
  { value: "c006", label: "Ecommerce - Tuck Top 1 2 3 Bottom - C006" },
  { value: "c007", label: "Ecommerce - Tuck Top Auto Bottom - C007" },
  { value: "c008", label: "Ecommerce - Corrugated Reverse Tuck End - C008" },
  { value: "c009", label: "Ecommerce - Corrugated Gusset Folder - C009" },
  { value: "c018", label: "Ecommerce - Corrugated Slotted Container - C018" },
  { value: "c019", label: "Ecommerce - 123 Bottom Slotted Container - C019" },
  { value: "c020", label: "Ecommerce - Auto Bottom Slotted Container - C020" },
  { value: "c021", label: "Ecommerce - One Piece Corrugated Mailer Box - C021" },
  { value: "c022", label: "Ecommerce - Full Slotted Container - C022" },
  { value: "c025", label: "Ecommerce - Corrugated Book Wrap Mailers - C025" },
  
  { value: "cbe195", label: "Retail - Retail Display Packaging Box - CBE195" },
  { value: "cbe194", label: "Retail - Creative Display Box - CBE194" },
  { value: "cbe004", label: "Retail - Printed Slotted Box	- CBE004" },
  { value: "fbe010", label: "Retail - Home Camera Packaging Box	- FBE010" },
];
