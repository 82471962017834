import React, { useContext } from "react";
import DropDownFields from "../reusable/DropDownFields";
import { ApplicationsPercentage } from "./ApplicationsPercentage";
import { QISOrdersContext } from "../context/QISOrdersContext";

const QisApplicationsValue = ({ applications, handleApplicationChange }) => {
  const { applicationsValue, setApplicationsValue } =
    useContext(QISOrdersContext);
  return (
    <>
      <div className="checkBoxGrid">
        <label htmlFor="Gold">
          <input
            type="checkbox"
            name="Gold"
            checked={applications.Gold}
            onChange={handleApplicationChange}
          />
          Outer Gold
        </label>
        <label>
          
        </label>
        {/* <label htmlFor="Thermal">
          <input
            type="checkbox"
            name="Thermal"
            checked={applications.Thermal}
            onChange={handleApplicationChange}
          />
          Outer Thermal
        </label>
        <label htmlFor="Pearl">
          <input
            type="checkbox"
            name="Pearl"
            checked={applications.Pearl}
            onChange={handleApplicationChange}
          />
          Outer Pearl
        </label> */}

        <label htmlFor="Spot UV">
          <input
            type="checkbox"
            name="LogoSpotUV"
            checked={applications.LogoSpotUV}
            onChange={handleApplicationChange}
          />
          Logo Spot UV
        </label>

        <label htmlFor="Spot UV On Other Elements">
          <input
            type="checkbox"
            name="SpotUVOnOtherElements"
            checked={applications.SpotUVOnOtherElements}
            onChange={handleApplicationChange}
          />
          Spot UV On Other Elements
        </label>

        <label htmlFor="Logo Embossing">
          <input
            type="checkbox"
            name="LogoEmbossing"
            checked={applications.LogoEmbossing}
            onChange={handleApplicationChange}
          />
          Logo Embossing
        </label>

        <label htmlFor="Embossing On Other Elements">
          <input
            type="checkbox"
            name="EmbossingOnOtherElements"
            checked={applications.EmbossingOnOtherElements}
            onChange={handleApplicationChange}
          />
          Embossing On Other Elements
        </label>

        <label htmlFor="Logo Foiling">
          <input
            type="checkbox"
            name="LogoFoiling"
            checked={applications.LogoFoiling}
            onChange={handleApplicationChange}
          />
          Logo Foiling
        </label>

        <label htmlFor="Foiling On Other Elements">
          <input
            type="checkbox"
            name="FoilingOnOtherElements"
            checked={applications.FoilingOnOtherElements}
            onChange={handleApplicationChange}
          />
          Foiling On Other Elements
        </label>

        <label htmlFor="DripOffUV">
          <input
            type="checkbox"
            name="DripOffUV"
            checked={applications.DripOffUV}
            onChange={handleApplicationChange}
          />
          Drip Off UV
        </label>

        {/* {applications.DripOffUV ? (
          <DropDownFields
            options={ApplicationsPercentage}
            value={applicationsValue.DripOffUV}
            setValue={(selectedOption) =>
              setApplicationsValue((prevApplicationsValue) => ({
                ...prevApplicationsValue,
                DripOffUV: selectedOption,
              }))
            }
          />
        ) : (
          ""
        )} */}

        <label htmlFor="Varnish">
          <input
            type="checkbox"
            name="Varnish"
            checked={applications.Varnish}
            onChange={handleApplicationChange}
          />
          Varnish
        </label>

        {/* {applications.Varnish ? (
          <DropDownFields
            options={ApplicationsPercentage}
            value={applicationsValue.Varnish}
            setValue={(selectedOption) =>
              setApplicationsValue((prevApplicationsValue) => ({
                ...prevApplicationsValue,
                Varnish: selectedOption,
              }))
            }
          />
        ) : (
          ""
        )} */}

        {/* <label htmlFor="Gumming">
          <input
            type="checkbox"
            name="Gumming"
            checked={applications.Gumming}
            onChange={handleApplicationChange}
          />
          Gumming
        </label> */}

        {/* {applications.Gumming ? (
          <DropDownFields
            options={ApplicationsPercentage}
            value={applicationsValue.Gumming}
            setValue={(selectedOption) =>
              setApplicationsValue((prevApplicationsValue) => ({
                ...prevApplicationsValue,
                Gumming: selectedOption,
              }))
            }
          />
        ) : (
          ""
        )} */}
      </div>
    </>
  );
};

export default QisApplicationsValue;
