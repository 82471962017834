import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import reducer from "./reducers/reducer";
import { SearchPreviousQuotations } from "../apis/apiClients";

export const SearchContext = createContext();

const initialState = {
  query: "",
};

const SearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const searchPost = (searchQuery) => {
    dispatch({
      type: "SEARCH_QUERY",
      payload: searchQuery,
    });
  };

  const uniqueId = localStorage.getItem("uniqueId");

  const fetchOrders = useCallback(
    async (query) => {
      try {
        const response = await SearchPreviousQuotations(query, uniqueId);
        console.log(response, "response");
      } catch (error) {
        console.log(error);
      }
    },
    [uniqueId]
  );

  useEffect(() => {
    if (state.query !== "") {
      fetchOrders(state.query);
    }
  }, [state.query, fetchOrders]);

  return (
    <div>
      <SearchContext.Provider value={{ ...state, searchPost }}>
        {children}
      </SearchContext.Provider>
    </div>
  );
};

export default SearchProvider;
