import React from "react";

const DropDownFields = ({ options, value, setValue, label }) => {
  return (
    <div>
      <label htmlFor={label}>{label} </label>
      <select value={value} onChange={(e) => setValue(e.target.value)} required>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropDownFields;
