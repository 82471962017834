import React from "react";

const OutputTables = ({ qtt1, qtt2, qtt3, tableData }) => {
  console.log(tableData, "Props data");
  return (
    <div className="tableContainer">
      <br />
      <div>
        {Object.keys(tableData).map((key) => (
          <div key={key}>
            <h3>{key}</h3>

            <div className="box">
              <ul>
                <li>Applications: {tableData[key].Applications.join(", ")}</li>
                {tableData[key].quality && (
                  <li>Quality: {tableData[key].quality}</li>
                )}
                {tableData[key].kraftQuality && (
                  <li>Kraft Quality: {tableData[key].kraftQuality}</li>
                )}
                {tableData[key].boardSize && (
                  <li>Board Size : {tableData[key].boardSize}</li>
                )}

                {tableData[key].asspassQuality && (
                  <li>Inner Paper Quality : {tableData[key].asspassQuality}</li>
                )}

                <h4>Prices</h4>
                <div class="InnerBox">
                  <ul>
                    <li>
                      <b>Quantity {qtt1} : </b> {tableData[key].first}
                    </li>
                    <li>
                      <b>Quantity {qtt2} : </b> {tableData[key].second}
                    </li>
                    <li>
                      <b>Quantity {qtt3} : </b> {tableData[key].third}
                    </li>
                  </ul>
                </div>

                {/* 
                Display Incentives 
                */}

                <h4>Logistics Cost</h4>
                <div class="InnerBox">
                  <ul>
                    <li>
                      <b>Quantity {qtt1} : </b> {tableData[key].logistics_one}
                    </li>
                    <li>
                      <b>Quantity {qtt2} : </b> {tableData[key].logistics_two}
                    </li>
                    <li>
                      <b>Quantity {qtt3} : </b> {tableData[key].logistics_three}
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </div>
        ))}
      </div>
      {/* <table>
        <thead>
          {Object.keys(tableData).map((key) => (
            <div key={key}>
              <tr>
                <th>&nbsp;</th>
                <th>{key}</th>
              </tr>
              <tbody>
                <tr>
                  <td>{qtt1}</td>
                  <td>{tableData[key].first}</td>
                  <td> {tableData[key].second}</td>
                  <td>{tableData[key].third}</td>
                </tr>
              </tbody>
            </div>
          ))}
        </thead>
      </table> */}
    </div>
  );
};

export default OutputTables;
