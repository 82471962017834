import React, { useContext, useState, useEffect, useRef } from "react";
import "./PunchCard.css";
import { OrderDetailsContext } from "../context/OrderDetailsContext";
import ApplicationsCheckBoxes from "../DropDownOptions/ApplicationsCheckBoxes";
import DropDownFields from "../reusable/DropDownFields";
import { boxTypeOptions } from "../DropDownOptions/BoxTypeOptions";
import { FBBOptions } from "../DropDownOptions/boxClosureTypes/FBBOptions";
import { RigidBoxOptions } from "../DropDownOptions/boxClosureTypes/RigidBoxOptions";
import { CorrugatedOptions } from "../DropDownOptions/boxClosureTypes/CorrugatedOptions";
import { PaperBagOptions } from "../DropDownOptions/boxClosureTypes/PaperBagOptions";
import { DimensionUnitOptions } from "../DropDownOptions/DimensionUnitOptions";
import { AssPassPaper } from "../DropDownOptions/AsspassPaperOptions";
import { AsspassQualityOptions } from "../DropDownOptions/AsspassQualityOptions";
import { BoardSizeOptions } from "../DropDownOptions/BoardSizeOptions";
import { KraftQualityOptions } from "../DropDownOptions/KraftQualityOptions";
import { HandleOptions } from "../DropDownOptions/HandleOptions";
import { transportOptions } from "../DropDownOptions/TransportOptions";
import { PaperGSMOptions } from "../DropDownOptions/PaperGSMOptions";
import { regionOptions } from "../DropDownOptions/RegionOptions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postPunchCost } from "../apis/apiClients";
import { punchCostWithImages } from "../apis/apiClients";
import { CavityOptions } from "../DropDownOptions/CavityOptions";
import { sendClientIDinPunch } from "../apis/apiClients";

const PunchCard = () => {
  const ImageRef = useRef(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const {
    remarks,
    setRemarks,
    imageUpload,
    setImageUpload,
    receivedOrderId,
    setReceivedOrder,
    receivedClientId,
    setReceivedClientId,
    folderPath,
    setFolderPath,
    showImage,
    setShowImage,
    clientName,
    setClientName,
    clientEmail,
    setClientEmail,
    clientContact,
    setClientContact,
    clientAddress,
    setClientAddress,
    clientPincode,
    setClientPincode,
    clientGST,
    setClientGST,
    product,
    setProduct,
    boxType,
    setBoxType,
    closure,
    setClosure,
    asspassPaper,
    setAsspassPaper,
    asspassQuality,
    setAsspassQuality,
    boardThickness,
    setBoardThickness,
    kraftQuality,
    setKraftQuality,
    cuffSize,
    setCuffSize,
    handle,
    setHandle,
    dim,
    setDim,
    dimUnit,
    setDimUnit,
    length,
    setLength,
    breadth,
    setBreadth,
    height,
    setHeight,
    cavity,
    setCavity,
    qtt1,
    setQtt1,
    quality,
    setQuality,
    transport,
    setTransport,
    region,
    setRegion,
    price1,
    setPrice1,
    saleDate,
    setSaleDate,
    doubleSide,
    setDoubleSide,
    applications,
    setApplications,
    applicationsValue,
    setApplicationsValue,
  } = useContext(OrderDetailsContext);

  const employeeId = localStorage.getItem("uniqueId");
  const handleSubmit = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();

    try {
      const res = await postPunchCost(
        employeeId,
        clientName,
        clientEmail,
        clientContact,
        clientAddress,
        clientPincode,
        clientGST,
        boxType,
        closure,
        dimUnit,
        dim,
        qtt1,
        transport,
        quality,
        region,
        kraftQuality,
        asspassPaper,
        asspassQuality,
        boardThickness,
        cuffSize,
        handle,
        applications,
        applicationsValue,
        price1,
        saleDate,
        remarks,
        product,
        doubleSide,
        cavity
      );
      if (res.status === 200) {
        setShowImage(true);
        setImageUpload(true);
        toast.success("Data Punched Successfully!");
        const id = res.data.orderId;
        const clientId = res.data.clientId;
        setReceivedOrder(id);
        setReceivedClientId(clientId);

        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 2000);
      } else {
        toast.error("Failed to punch the data");
      }
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (ImageRef.current) {
      const timeout = setTimeout(() => {
        ImageRef.current.scrollIntoView({ behavior: "smooth" });
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [imageUpload]);

  // const handleMultipleChange = (e) => {
  //   const selectedFiles = Array.from(e.target.files);
  //   setFiles(selectedFiles);
  // };

  const sendClientId = async (clientId) => {
    try {
      const res = await sendClientIDinPunch(clientId);
      console.log("1");
      if (res && res.status === 200) {
        console.log("2");
        const pathFolder = res.folderPath;
        console.log("3", pathFolder);
        setFolderPath(pathFolder);
      } else {
        console.log("Unexpected response:", res.folderPath);
        console.log("testing", res.test);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const resetApplicationsValue = (appName) => {
    setApplicationsValue((prevApplicationsValue) => ({
      ...prevApplicationsValue,
      [appName]: null,
    }));
  };

  const handleApplicationChange = (event) => {
    const { name, checked } = event.target;
    setApplications((prevApplications) => ({
      ...prevApplications,
      [name]: checked,
    }));
    if (!checked) {
      resetApplicationsValue(name);
    }
  };

  return (
    <div>
      <div className="punchCardContainerWrapper">
        <div class="container">
          <div class="text">Punch Your Orders</div>
          <form action="POST" onSubmit={handleSubmit} disabled={buttonDisabled}>
            <div class="form-row">
              <div class="input-data">
                <input
                  type="text"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  required
                />
                <div class="underline"></div>
                <label for="">Client Name</label>
              </div>
            </div>

            <div class="form-row">
              <div class="input-data">
                <input
                  type="text"
                  value={clientEmail}
                  onChange={(e) => setClientEmail(e.target.value)}
                  required
                />
                <div class="underline"></div>
                <label for="">Client Email Address</label>
              </div>
            </div>

            <div className="form-row">
              <div class="input-data">
                <input
                  type="text"
                  value={clientAddress}
                  onChange={(e) => setClientAddress(e.target.value)}
                  required
                />
                <div class="underline"></div>
                <label for="">Client Address</label>
              </div>
            </div>

            <div class="form-row">
              <div class="input-data">
                <input
                  type="text"
                  value={clientContact}
                  onChange={(e) => setClientContact(e.target.value)}
                  required
                />
                <div class="underline"></div>
                <label for="">Contact Number</label>
              </div>

              <div class="input-data">
                <input
                  type="text"
                  value={clientGST}
                  onChange={(e) => setClientGST(e.target.value)}
                  required
                />
                <div className="underline"></div>
                <label for="">Client GST</label>
              </div>
            </div>

            <div className="form-row">
              <div className="input-data">
                <input
                  type="text"
                  value={clientPincode}
                  onChange={(e) => setClientPincode(e.target.value)}
                  required
                />
                <div class="underline"></div>
                <label for="">Client Pincode</label>
              </div>
              <div class="input-data">
                <input
                  type="text"
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                  required
                />
                <div className="underline"></div>
                <label for="">Product</label>
              </div>
            </div>

            <div className="form-row">
              <div className="input-data-dropdown">
                <label for="">Box Type</label>
                <DropDownFields
                  options={boxTypeOptions}
                  value={boxType}
                  setValue={setBoxType}
                />
                <div class="underline"></div>
              </div>
              <div class="input-data-dropdown">
                {boxType === "fbb" && (
                  <div>
                    <DropDownFields
                      options={FBBOptions}
                      value={closure}
                      setValue={setClosure}
                      label={"FBB Closure"}
                    />
                  </div>
                )}

                {boxType === "kraft" && (
                  <div>
                    <DropDownFields
                      options={FBBOptions}
                      value={closure}
                      setValue={setClosure}
                      label={"Kraft Closure"}
                    />
                  </div>
                )}
                {boxType === "rigidBox" && (
                  <div>
                    <DropDownFields
                      options={RigidBoxOptions}
                      value={closure}
                      setValue={setClosure}
                      label={"Rigid Box Closure"}
                    />
                  </div>
                )}

                {boxType === "corrugatedBox" && (
                  <div>
                    <DropDownFields
                      options={CorrugatedOptions}
                      value={closure}
                      setValue={setClosure}
                      label={"Corrugated Closure"}
                    />
                  </div>
                )}

                {boxType === "paperBag" && (
                  <div>
                    <DropDownFields
                      options={PaperBagOptions}
                      value={closure}
                      setValue={setClosure}
                      label={"Paper Bag Closure"}
                    />
                  </div>
                )}
              </div>
            </div>

            {boxType === "rigidBox" && (
              <div className="form-row">
                <div className="input-data-dropdown">
                  <div>
                    <DropDownFields
                      options={AssPassPaper}
                      value={asspassPaper}
                      setValue={setAsspassPaper}
                      label={"Inner Paper"}
                    />
                  </div>
                </div>

                <div class="input-data-dropdown">
                  <div>
                    <DropDownFields
                      options={AsspassQualityOptions}
                      value={asspassQuality}
                      setValue={setAsspassQuality}
                      label={"Inner Paper Quality"}
                    />
                  </div>
                </div>
              </div>
            )}

            {boxType === "rigidBox" && (
              <div className="form-row">
                <div className="input-data-dropdown">
                  <div>
                    <DropDownFields
                      options={BoardSizeOptions}
                      value={boardThickness}
                      setValue={setBoardThickness}
                      label={"Board Thickness"}
                    />
                  </div>
                  <div>
                    <div className="input-data-dropdown">
                      <DropDownFields
                        value={cavity}
                        setValue={setCavity}
                        label={"Cavity"}
                        options={CavityOptions}
                      />
                    </div>
                  </div>
                </div>

                <div className="input-data">
                  {boxType === "rigidBox" && closure === "topBottomCuff" && (
                    <div className="input-data">
                      <input
                        type="text"
                        value={cuffSize}
                        onChange={(e) => setCuffSize(e.target.value)}
                        required
                      />
                      <div class="underline"></div>
                      <label for="">Size of Visible Cuff</label>
                    </div>
                  )}
                </div>
              </div>
            )}

            {boxType === "corrugatedBox" && (
              <div className="form-data">
                <div className="input-data-dropdown">
                  <div>
                    <DropDownFields
                      options={KraftQualityOptions}
                      value={kraftQuality}
                      setValue={setKraftQuality}
                      label={"Kraft Quality"}
                    />
                  </div>
                </div>
                <div className="input-data-dropdown">
                  <DropDownFields
                    value={cavity}
                    setValue={setCavity}
                    label={"Cavity"}
                    options={CavityOptions}
                  />
                </div>
              </div>
            )}

            {boxType === "paperBag" && closure === "paperBag" && (
              <div className="input-data-dropdown">
                <div>
                  <DropDownFields
                    value={handle}
                    setValue={setHandle}
                    options={HandleOptions}
                    label={"Handle"}
                  />
                </div>
              </div>
            )}

            <div className="form-row">
              <div className="input-data-dropdown">
                <DropDownFields
                  options={DimensionUnitOptions}
                  value={dimUnit}
                  setValue={setDimUnit}
                  label={"Dim Unit"}
                />
              </div>
              <div class="input-data-dropdown">
                {boxType === "fbb" && (
                  <div>
                    <div>
                      <DropDownFields
                        value={cavity}
                        setValue={setCavity}
                        label={"Cavity"}
                        options={CavityOptions}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="input-data">
                <input
                  type="text"
                  value={length}
                  onChange={(e) => {
                    const value = e.target.value;
                    setLength(value);
                    setDim(`${value}*${breadth}*${height}`);
                  }}
                  required
                />

                <div class="underline"></div>
                <label for="">Length</label>
              </div>
              <div class="input-data">
                <input
                  type="text"
                  value={breadth}
                  onChange={(e) => {
                    const value = e.target.value;
                    setBreadth(value);
                    setDim(`${length}*${value}*${height}`);
                  }}
                  required
                />
                <div class="underline"></div>
                <label for="">Breadth</label>
              </div>
              <div class="input-data">
                <input
                  type="text"
                  value={height}
                  onChange={(e) => {
                    const value = e.target.value;
                    setHeight(value);
                    setDim(`${length}*${breadth}*${value}`);
                  }}
                  required
                />
                <div class="underline"></div>
                <label for="">Height</label>
              </div>
            </div>

            <div className="form-row">
              <div className="input-data">
                <input
                  type="text"
                  value={qtt1}
                  onChange={(e) => setQtt1(e.target.value)}
                  required
                />
                <div className="underline"></div>
                <label for="">Quantity</label>
              </div>

              <div className="input-data">
                <input
                  type="text"
                  value={price1}
                  onChange={(e) => setPrice1(e.target.value)}
                  required
                />
                <div className="underline"></div>
                <label for="">Price </label>
              </div>
            </div>

            {/* transport, region */}

            <div className="form-row">
              <div className="input-data-dropdown">
                <DropDownFields
                  options={transportOptions}
                  value={transport}
                  setValue={setTransport}
                  label={"Transport "}
                />
              </div>
              <div className="input-data-dropdown">
                <DropDownFields
                  options={PaperGSMOptions}
                  value={quality}
                  setValue={setQuality}
                  label={"Paper GSM "}
                />
              </div>

              <div className="input-data-dropdown">
                <DropDownFields
                  value={region}
                  setValue={setRegion}
                  label={"Region :"}
                  options={regionOptions}
                />
              </div>
            </div>

            {/* sale date and remarks */}
            <div className="form-row">
              <div className="input-data">
                <input
                  type="date"
                  required
                  value={saleDate}
                  onChange={(e) => setSaleDate(e.target.value)}
                />
                <div className="underline"></div>
                <label for="">Sale Date</label>
              </div>
            </div>

            {boxType === "fbb" && (
              <div>
                <div className="doubleSideInPunch">
                  <label>Double side Printing</label>
                  <div className="doubleSidePrinting">
                    <div class="radio-label">
                      <input
                        type="radio"
                        name="doubleSidePrinting"
                        id="yes"
                        value="yes"
                        checked={doubleSide === "yes"}
                        style={{ marginTop: "12px" }}
                        onChange={(e) => setDoubleSide(e.target.value)}
                      />
                      <label className="labelNo" for="yes">
                        Yes
                      </label>
                    </div>
                    <div className="radio-label">
                      <input
                        type="radio"
                        name="doubleSidePrinting"
                        id="no"
                        value="no"
                        checked={doubleSide === "no"}
                        style={{ marginTop: "12px", marginLeft: "8px" }}
                        onChange={(e) => setDoubleSide(e.target.value)}
                      />
                      <label className="labelNo" for="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {boxType === "corrugatedBox" && (
              <div>
                <div className="doubleSideInPunch">
                  <label>Double side Printing</label>
                  <div className="doubleSidePrinting">
                    <div class="radio-label">
                      <input
                        type="radio"
                        name="doubleSidePrinting"
                        id="yes"
                        value="yes"
                        checked={doubleSide === "yes"}
                        style={{ marginTop: "12px" }}
                        onChange={(e) => setDoubleSide(e.target.value)}
                      />
                      <label className="labelNo" for="yes">
                        Yes
                      </label>
                    </div>
                    <div className="radio-label">
                      <input
                        type="radio"
                        name="doubleSidePrinting"
                        id="no"
                        value="no"
                        checked={doubleSide === "no"}
                        style={{ marginTop: "12px", marginLeft: "8px" }}
                        onChange={(e) => setDoubleSide(e.target.value)}
                      />
                      <label className="labelNo" for="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {boxType === "kraft" && (
              <div>
                <div className="doubleSideInPunch">
                  <label>Double side Printing</label>
                  <div className="doubleSidePrinting">
                    <div class="radio-label">
                      <input
                        type="radio"
                        name="doubleSidePrinting"
                        id="yes"
                        value="yes"
                        checked={doubleSide === "yes"}
                        style={{ marginTop: "12px" }}
                        onChange={(e) => setDoubleSide(e.target.value)}
                      />
                      <label className="labelNo" for="yes">
                        Yes
                      </label>
                    </div>
                    <div className="radio-label">
                      <input
                        type="radio"
                        name="doubleSidePrinting"
                        id="no"
                        value="no"
                        checked={doubleSide === "no"}
                        style={{ marginTop: "12px", marginLeft: "8px" }}
                        onChange={(e) => setDoubleSide(e.target.value)}
                      />
                      <label className="labelNo" for="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {boxType === "kraft" && (
              <div className="input-data-dropdown">
                <DropDownFields
                  value={cavity}
                  setValue={setCavity}
                  label={"Cavity"}
                  options={CavityOptions}
                />
              </div>
            )}

            <div class="form-row">
              <div class="input-data textarea">
                <textarea
                  rows="8"
                  cols="80"
                  type="text"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  required
                ></textarea>
                <br />
                <div class="underline"></div>
                <label for="">Additional Remarks</label>
                <br />
                <div class="form-row submit-btn">
                  <div class="input-data"></div>
                </div>
              </div>
            </div>

            {/* applications */}

            <div className="applicationsGridContainer">
              <label htmlFor="applications">Applications :</label>
              <div className="applicationsGrid">
                <ApplicationsCheckBoxes
                  applications={applications}
                  handleApplicationChange={handleApplicationChange}
                />
              </div>
            </div>

            <div>
              <div>
                <button type="submit">Submit</button>
              </div>
            </div>
          </form>

          {console.log(
            "linkkk",
            `https://drive.google.com/drive/folders/${folderPath}`
          )}

          {showImage ? (
            <div>
              <div className="form-row">
                <a
                  href={`https://drive.google.com/drive/folders/${folderPath}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    type="submit"
                    style={{ fontWeight: "bold" }}
                    onClick={() => sendClientId(receivedClientId)}
                  >
                    Upload Files
                  </button>
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default PunchCard;
