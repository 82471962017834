export const CavityOptions = [
  { value: "noCavity", label: "No Cavity" },
  { value: "foamEPE", label: "Foam EPE" },
  { value: "foamEVA", label: "Foam EVA" },
  { value: "paper", label: "Paper" },
  { value: "kappa", label: "Kappa" },
  { value: "hitlon", label: "Hitlon"},
  { value: "paperPlusEVA", label: "Paper + EVA" },
  { value: "flowerCavity", label: "Flower Cavity" },
  { value: "EVAPlusVelvet", label: "EVA + Velvet" },
  { value: "EVAPlusSatin", label: "EVA + Satin" },
  { value: "kappaPlusEVAPlusPaper", label: "Kappa + EVA + Paper" },
  { value: "spongePU", label: "Sponge (PU)" },
  
];
