import React, { createContext, useState } from "react";
import { CavityOptions } from "../DropDownOptions/CavityOptions";
import { LaminationOptions } from "../DropDownOptions/LaminationOptions";
import { HandleAllClosureOptions } from "../DropDownOptions/HandleAllClosuresOptions";
import { PaperTypesOptions } from "../DropDownOptions/PaperTypesOptions";
import { HandleOptions } from "../DropDownOptions/HandleOptions";
import { PrintingColoursOptions } from "../DropDownOptions/PrintingColoursOptions";
import { PrintingColoursOptionsInsidePaper } from "../DropDownOptions/PrintingColoursOptionsInsidePaper";

export const QISOrdersContext = createContext();

const QISOrdersContextProvider = ({ children }) => {
  const [product, setProduct] = useState("");
  const [error, setError] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientContact, setClientContact] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [saleDate, setSaleDate] = useState();
  const [boxType, setBoxType] = useState("");
  const [fbbPaperType, setFbbPaperType] = useState("");
  const [handleAllClosureType, sethandleAllClosureType] = useState(HandleAllClosureOptions[0].value);
  const [kraftQuality, setKraftQuality] = useState("");
  const [asspassPaper, setAsspassPaper] = useState("");
  const [asspassQuality, setAsspassQuality] = useState("");
  const [boardThickness, setBoardThickness] = useState("");
  const [closure, setClosure] = useState("");
  const [handle, setHandle] = useState(HandleOptions[0].value);
  const [cuffSize, setCuffSize] = useState("");
  const [dim, setDim] = useState("");
  const [dimUnit, setDimUnit] = useState("");
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");
  const [qtt1, setQtt1] = useState("");
  const [qtt2, setQtt2] = useState("");
  const [qtt3, setQtt3] = useState("");
  const [transport, setTransport] = useState("");
  const [region, setRegion] = useState("");
  const [quality, setQuality] = useState("");
  const [paperBagPaperType, setPaperBagPaperType] = useState(PaperTypesOptions[0].value);
  const [cavity, setCavity] = useState(CavityOptions[0].value);
  const [doubleSide, setDoubleSide] = useState("");
  const [lamination, setLamination] = useState(LaminationOptions[0].value);
  const [corrugatedInnerPaper, setCorrugatedInnerPaper] = useState("");
  const [corrugatedOuterPaper, setCorrugatedOuterPaper] = useState("");
  const [foodGradePaperColour, setFoodGradePaperColour] = useState("");
  const [foodGradePaperGSM, setFoodGradePaperGSM] = useState("");
  const [foodGradePrintingType, setFoodGradePrintingType] = useState("");
  const [printingColours, setPrintingColours] = useState(PrintingColoursOptions[0].value);
  const [printingColoursInsidePaper, setPrintingColoursInsidePaper] = useState(PrintingColoursOptionsInsidePaper[0].value);
  const [applications, setApplications] = useState({
    Gold: false,
    Thermal: false,
    Pearl: false,
    LogoSpotUV: false,
    SpotUVOnOtherElements: false,
    LogoEmbossing: false,
    EmbossingOnOtherElements: false,
    LogoFoiling: false,
    FoilingOnOtherElements: false,
    DripOffUV: false,
    Varnish: false,
    Gumming: false,
  });
  const [applicationsValue, setApplicationsValue] = useState({
    DripOffUV: null,
    Gumming: null,
    Varnish: null,
  });
  return (
    <div>
      <QISOrdersContext.Provider
        value={{
          product,
          setProduct,
          error,
          setError,
          clientName,
          setClientName,
          clientEmail,
          setClientEmail,
          clientContact,
          setClientContact,
          clientAddress,
          setClientAddress,
          saleDate,
          setSaleDate,
          boxType,
          setBoxType,
          fbbPaperType,
          setFbbPaperType,
          handleAllClosureType,
          sethandleAllClosureType,
          closure,
          setClosure,
          asspassPaper,
          setAsspassPaper,
          asspassQuality,
          setAsspassQuality,
          boardThickness,
          setBoardThickness,
          kraftQuality,
          setKraftQuality,
          cuffSize,
          setCuffSize,
          handle,
          setHandle,
          dim,
          setDim,
          dimUnit,
          setDimUnit,
          length,
          setLength,
          breadth,
          setBreadth,
          height,
          setHeight,
          qtt1,
          setQtt1,
          qtt2,
          setQtt2,
          qtt3,
          setQtt3,
          quality,
          setQuality,
          paperBagPaperType,
          setPaperBagPaperType,
          transport,
          setTransport,
          region,
          setRegion,
          cavity,
          setCavity,
          doubleSide,
          setDoubleSide,
          lamination,
          setLamination,
          applications,
          setApplications,
          applicationsValue,
          setApplicationsValue,
          corrugatedInnerPaper, 
          setCorrugatedInnerPaper,
          corrugatedOuterPaper,
          setCorrugatedOuterPaper,
          foodGradePaperColour, 
          setFoodGradePaperColour,
          foodGradePaperGSM, 
          setFoodGradePaperGSM,
          foodGradePrintingType,
          setFoodGradePrintingType,
          printingColours, 
          setPrintingColours,
          printingColoursInsidePaper,
          setPrintingColoursInsidePaper,
        }}
      >
        {children}
      </QISOrdersContext.Provider>
    </div>
  );
};

export default QISOrdersContextProvider;
