import React, { useContext, useState, useEffect, useRef } from "react";
import "./RigidBoxContainer.css";
import DropDownFields from "../reusable/DropDownFields";
import { DimensionUnitOptions } from "../DropDownOptions/DimensionUnitOptions";
import { FBBOptions } from "../DropDownOptions/boxClosureTypes/FBBOptions";
import { transportOptions } from "../DropDownOptions/TransportOptions";
import { PaperGSMOptions } from "../DropDownOptions/PaperGSMOptions";
import { regionOptions } from "../DropDownOptions/RegionOptions";
import { QISOrdersContext } from "../context/QISOrdersContext";
import { LaminationOptions } from "../DropDownOptions/LaminationOptions";
import InputTextField from "../reusable/InputTextField";
import { CavityOptions } from "../DropDownOptions/CavityOptions";
import { qisOrderDetails } from "../apis/apiClients";
import { toast } from "react-toastify";
import { UniqueIdContext } from "../context/UniqueIdContext";
import QisOrdersDisplay from "../reusable/QisOrdersDisplay";
import QuotationPriceDisplay from "../components/QuotationPriceDisplay";
import FbbKraftClosureImages from "../modals/Fbb&KraftClosureImages";
import RegionModal from "../modals/RegionModal";
import { FbbPaperTypeOptions } from "../DropDownOptions/FbbPaperTypeOptions";
import QisApplicationsValue from "../DropDownOptions/QisApplicationsValue";
import OutputTables from "../BDA/OutputTables";
import Downloadbutton from "../components/button";
import { HandleAllClosureOptions } from "../DropDownOptions/HandleAllClosuresOptions";
import FBBClosureImagesDisplay from "./FBBClosureImageDisplay";
import { PrintingColoursOptions } from "../DropDownOptions/PrintingColoursOptions";
import { PrintingColoursOptionsInsidePaper } from "../DropDownOptions/PrintingColoursOptionsInsidePaper";

const FBBBoxContainer = () => {
  const quotationRef = useRef(null);
  const [displayQuotation, setDisplayQuotation] = useState(false);
  const [displayOrderDetails, setDisplayOrderDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [regionModal, setRegionModal] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      if (displayQuotation && quotationRef.current) {
        quotationRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 1000);

    setTimeout(() => {
      if (displayOrderDetails && quotationRef.current) {
        quotationRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 1000);
  }, [displayQuotation, displayOrderDetails]);

  const { setPrice1, setPrice2, setPrice3 } = useContext(UniqueIdContext);
  const {
    clientName,
    setClientName,
    clientEmail,
    setClientEmail,
    clientAddress,
    setClientAddress,
    product,
    setProduct,
    closure,
    setClosure,
    fbbPaperType,
    setFbbPaperType,
    handleAllClosureType,
    sethandleAllClosureType,
    dim,
    setDim,
    dimUnit,
    setDimUnit,
    boardThickness,
    qtt1,
    setQtt1,
    qtt2,
    setQtt2,
    qtt3,
    setQtt3,
    length,
    setLength,
    breadth,
    setBreadth,
    height,
    setHeight,
    asspassPaper,
    kraftQuality,
    cuffSize,
    handle,
    asspassQuality,
    transport,
    setTransport,
    region,
    setRegion,
    quality,
    setQuality,
    paperBagPaperType,
    applications,
    setApplications,
    applicationsValue,
    setApplicationsValue,
    cavity,
    setCavity,
    doubleSide,
    setDoubleSide,
    lamination,
    setLamination,
    corrugatedInnerPaper, 
    corrugatedOuterPaper,
    foodGradePaperGSM,
    foodGradePrintingType,
    foodGradePaperColour,
    printingColours, 
    setPrintingColours,
    printingColoursInsidePaper, 
    setPrintingColoursInsidePaper
  } = useContext(QISOrdersContext);

  const employeeId = localStorage.getItem("uniqueId");
  const handleToggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const handleRegionModal = () => {
    setRegionModal((prevShowModal) => !prevShowModal);
  };

  const boxType = "fbb";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisplayOrderDetails(true);
    try {
      const res = await qisOrderDetails(
        clientName,
        clientEmail,
        boxType,
        product,
        closure,
        kraftQuality,
        asspassPaper,
        asspassQuality,
        cuffSize,
        boardThickness,
        dimUnit,
        handle,
        dim,
        qtt1,
        qtt2,
        qtt3,
        transport,
        quality,
        paperBagPaperType,
        region,
        applications,
        applicationsValue,
        employeeId,
        cavity,
        doubleSide,
        lamination,
        fbbPaperType,
        handleAllClosureType,
        corrugatedInnerPaper,
        corrugatedOuterPaper,
        foodGradePaperGSM,
        foodGradePrintingType,
        foodGradePaperColour,
        printingColours,
        printingColoursInsidePaper,
      );
      if (res.status === 200) {
        setDisplayQuotation(true);
        toast.success("Quotation Generated successfully!");
        const price1 = res.data.first;
        setPrice1(price1);
        const price2 = res.data.second;
        setPrice2(price2);
        const price3 = res.data.third;
        setPrice3(price3);
      }
      setTableData(res.data);
      console.log(res.data);
      return res.data;
    } catch (error) {
      toast.error("Failed to Generate the Quotation!");
      console.log(error);
    }
  };

  const resetApplicationsValue = (appName) => {
    setApplicationsValue((prevApplicationsValue) => ({
      ...prevApplicationsValue,
      [appName]: null,
    }));
  };

  const handleApplicationChange = (event) => {
    const { name, checked } = event.target;
    setApplications((prevApplications) => ({
      ...prevApplications,
      [name]: checked,
    }));
    if (!checked) {
      resetApplicationsValue(name);
    }
  };

  return (
    <div>
      <div className="outerContainer">
        <div className="container">
          <div className="formTitle">Client & Order Details</div>
          <div className="formDetails">
            <form onSubmit={handleSubmit} className="form">
              {/* <div>
                <div className="input-box">
                  <InputTextField
                    value={clientName}
                    setValue={setClientName}
                    label={"Client Name"}
                    placeholder={"Enter Client Name"}
                  />
                </div>

                <div className="input-box">
                  <InputTextField
                    value={clientEmail}
                    label={"Client Email"}
                    setValue={setClientEmail}
                    placeholder={"Enter Email Address"}
                  />
                </div>
              </div>
              <div className="input-box">
                <InputTextField
                  value={clientAddress}
                  setValue={setClientAddress}
                  label={"Client Address"}
                  placeholder={"Enter Client Address"}
                />
              </div>

              <div className="input-box">
                <InputTextField
                  value={product}
                  setValue={setProduct}
                  label={"Product"}
                  placeholder={"Enter Product"}
                />
              </div> */}

              <div className="column">
                <div className="input-box">
                  <label>FBB Closure</label>

                  <DropDownFields
                    options={FBBOptions}
                    value={closure}
                    setValue={setClosure}
                  />
                  <FBBClosureImagesDisplay selectedClosure={closure} />
                </div>
              </div>
              {showModal ? (
                <FbbKraftClosureImages showModal={handleToggleModal} />
              ) : (
                ""
              )}

              <div className="column">
                <div className="input-box">
                  <label>Dimension Unit</label>
                  <DropDownFields
                    options={DimensionUnitOptions}
                    value={dimUnit}
                    setValue={setDimUnit}
                  />
                </div>
                <div className="input-box">
                  <label>Paper Type</label>

                  <DropDownFields
                    options={FbbPaperTypeOptions}
                    value={fbbPaperType}
                    setValue={setFbbPaperType}
                  />
                </div>
                <div className="input-box">
                  <label>Handle Types</label>

                  <DropDownFields
                    options={HandleAllClosureOptions}
                    value={handleAllClosureType}
                    setValue={sethandleAllClosureType}
                  />
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <InputTextField
                    value={length}
                    setValue={(value) => {
                      setLength(value);
                      setDim(`${value}*${breadth}*${height}`);
                    }}
                    label={"Length"}
                    placeholder={"Enter Length"}
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={breadth}
                    setValue={(value) => {
                      setBreadth(value);
                      setDim(`${length}*${value}*${height}`);
                    }}
                    label={"Breadth"}
                    placeholder={"Enter Breadth"}
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={height}
                    setValue={(value) => {
                      setHeight(value);
                      setDim(`${length}*${breadth}*${value}`);
                    }}
                    label={"Height"}
                    placeholder={"Enter Height"}
                  />
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <InputTextField
                    value={qtt1}
                    setValue={setQtt1}
                    label={"Quantity 1"}
                    placeholder={"Quantity 1"}
                    required
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={qtt2}
                    setValue={setQtt2}
                    label={"Quantity 2"}
                    placeholder={"Quantity 2"}
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={qtt3}
                    setValue={setQtt3}
                    label={"Quantity 3"}
                    placeholder={"Quantity 3"}
                  />
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <label>Paper GSM</label>
                  <DropDownFields
                    value={quality}
                    setValue={setQuality}
                    options={PaperGSMOptions}
                  />
                </div>
                <div className="input-box">
                    <DropDownFields
                      value={printingColours}
                      setValue={setPrintingColours}
                      options={PrintingColoursOptions}
                      label={"No. of Colours"}
                    />
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <label>Transport</label>
                  <DropDownFields
                    value={transport}
                    setValue={setTransport}
                    options={transportOptions}
                  />
                </div>
                <div className="input-box">
                  <label>Region</label>
                  <DropDownFields
                    value={region}
                    setValue={setRegion}
                    options={regionOptions}
                  />
                </div>
                <i
                  onClick={handleRegionModal}
                  id="infoId"
                  className="fa-solid fa-circle-info"
                ></i>
                {regionModal ? (
                  <RegionModal showModal={handleRegionModal} />
                ) : (
                  ""
                )}
              </div>
              <div className="column">
                <div className="input-box">
                  <DropDownFields
                    value={cavity}
                    setValue={setCavity}
                    label={"Cavity"}
                    options={CavityOptions}
                  />
                </div>
                <div className="input-box">
                  <DropDownFields
                    value={lamination}
                    setValue={setLamination}
                    options={LaminationOptions}
                    label={"Lamination"}
                  />
                </div>
              </div>

              <label>Double side Printing</label>
              <div className="column">
                <div className="input-box">
                  <div className="doubleSidePrinting">
                    <div class="radio-label">
                      <input
                        type="radio"
                        name="doubleSidePrinting"
                        id="yes"
                        value="yes"
                        checked={doubleSide === "yes"}
                        style={{ marginTop: "12px", marginBottom: "8px"}}
                        onChange={(e) => setDoubleSide(e.target.value)}
                      />
                      <label className="labelNo" for="yes">
                        Yes
                      </label>
                    </div>
                    <div className="radio-label">
                      <input
                        type="radio"
                        name="doubleSidePrinting"
                        id="no"
                        value="no"
                        checked={doubleSide === "no"}
                        style={{ marginTop: "12px", marginBottom: "8px"}}
                        onChange={(e) => setDoubleSide(e.target.value)}
                      />
                      <label className="labelNo" for="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="input-box">
                  {doubleSide==="yes" && (
                    <DropDownFields
                      options={PrintingColoursOptionsInsidePaper}
                      value={printingColoursInsidePaper}
                      setValue={setPrintingColoursInsidePaper}
                      label={"No. of Colours for Inside Print"}
                    />
                  )}
                </div>
              </div>

              <div className="applicationsGridContainer">
                <label htmlFor="applications">Applications :</label>
                <div className="applicationsGrid">
                  <QisApplicationsValue
                    applications={applications}
                    handleApplicationChange={handleApplicationChange}
                  />
                </div>
              </div>

              <button type="submit">Magic Button ✨</button>

              {/* download the worksheet in excel */}
              <Downloadbutton>
                  
              </Downloadbutton>
            </form>
          </div>
          {displayOrderDetails ? <QisOrdersDisplay boxType={boxType} /> : ""}
          {/* {displayQuotation ? (
            <QuotationPriceDisplay qtt1={qtt1} qtt2={qtt2} qtt3={qtt3} />
          ) : (
            ""
          )} */}
          {displayQuotation ? (
            <OutputTables
              tableData={tableData}
              qtt1={qtt1}
              qtt2={qtt2}
              qtt3={qtt3}
            />
          ) : (
            ""
          )}
        </div>
        <div ref={quotationRef}></div>
      </div>
    </div>
  );
};

export default FBBBoxContainer;
