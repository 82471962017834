import React, { useContext } from "react";
import UserDropdown from "../reusable/UserDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./CSProformaInvoiceInput.css";
import OrderDetails from "./OrderDetailsInvoice";
import ClientDetailsInvoice from "./ClientDetailsInvoice";
import { proformaInvoiceInput } from "../apis/apiClients";
import { ProformaInvoiceContext } from "../context/ProformaInvoiceContext";
import { toast } from "react-toastify";

const CSProformaInvoiceInput = () => {
  const {
    clientName,
    clientGST,
    clientEmail,
    clientContact,
    billingAddress,
    orders,
    setOrders,
  } = useContext(ProformaInvoiceContext);

  const redirectToViewInvoice = () => {
    history.push("/CSDashboard/ViewPerformaInvoice");
    // <ViewInvoice
    //   clientName={clientName}
    //   clientGST={clientGST}
    //   billingAddress={billingAddress}
    // />;
  };

  const addNewOrder = () => {
    setOrders([
      ...orders,
      {
        orderDescription: "",
        hsn: "",
        quantity: "",
        ratePerPiece: "",
        finalAmount: "",
      },
    ]);
  };

  const removeOrder = (indexToRemove) => {
    setOrders((prevOrders) =>
      prevOrders.filter((order, index) => index !== indexToRemove)
    );
  };

  const history = useHistory();

  const handlePrev = () => {
    history.push("/CSDashboard");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await proformaInvoiceInput(
        clientName,
        clientGST,
        clientEmail,
        clientContact,
        billingAddress,
        orders
      );
      if (response.status === 200) {
        toast.success("Order details submitted successfully!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">Proforma Invoice Details</b>
            <UserDropdown />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div className="InvoiceContainer">
            <form className="InvoiceProforma" onSubmit={handleSubmit}>
              {/* <h1> Invoice Details </h1> */}
              <legend>
                <span className="number">1</span>Client Details
              </legend>
              <ClientDetailsInvoice />
              {orders.map((order, index) => (
                <div>
                  <legend>
                    <span className="number">2</span>Order Details {index + 1}
                  </legend>
                  <OrderDetails
                    key={index}
                    order={order}
                    setOrders={(newOrder) => {
                      setOrders((prevOrders) => {
                        return prevOrders
                          .slice(0, index)
                          .concat(newOrder)
                          .concat(prevOrders.slice(index + 1));
                      });
                    }}
                    removeOrder={() => removeOrder(index)}
                  />
                </div>
              ))}

              <div className="addMoreContainer">
                <span onClick={addNewOrder} className="addMoreOrders">
                  +
                </span>
                &nbsp;Add orders
              </div>
              <div className="viewAndSubmitButton">
                <button type="submit" onClick={() => redirectToViewInvoice()}>
                  View Invoice Details
                </button>
              </div>
              <div className="viewAndSubmitButton">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CSProformaInvoiceInput;
