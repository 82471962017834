export const CommercialClosuresOptions = [
    { value: "", label: "None"},
    { value: "card", label: "Card"},
    { value: "flyer", label: "Flyer"},
    { value: "postCard", label: "Post Card"},
    { value: "menuCard", label: "Menu Card"},
    { value: "doorHandle", label: "Door Handle"},
    { value: "certificate", label: "Certificate"},
    { value: "poster", label: "Poster"},
    { value: "folder", label: "Folder"},
    { value: "brochure", label: "Brochure"},
    { value: "booklet", label: "Booklet"},
    { value: "prescriptionPad", label: "Prescription Pad"},
    { value: "chitPad", label: "Chit Pad"},
    { value: "notePad", label: "Note Pad"},
];