import React, { createContext, useState } from "react";

export const ProformaInvoiceContext = createContext();

const ProformaInvoiceContextProvider = ({ children }) => {
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientContact, setClientContact] = useState("");
  const [clientGST, setClientGST] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [orders, setOrders] = useState([
    {
      orderDescription: "",
      hsn: "",
      quantity: "",
      ratePerPiece: "",
      finalAmount: "",
    },
  ]);

  return (
    <div>
      <ProformaInvoiceContext.Provider
        value={{
          clientName,
          setClientName,
          clientEmail,
          setClientEmail,
          clientContact,
          setClientContact,
          clientGST,
          setClientGST,
          billingAddress,
          setBillingAddress,
          orders,
          setOrders,
        }}
      >
        {children}
      </ProformaInvoiceContext.Provider>
    </div>
  );
};

export default ProformaInvoiceContextProvider;
