import Dashboard from "./dashboards/dashboard";
import LoginPage from "./components/LoginPage";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import InputField from "./BDA/InputField";
import Orders from "./BDA/Orders";
import UniqueIdProvider from "./context/UniqueIdContext";
import SalesDashboard from "./dashboards/salesDashboard";
import ManagerOrders from "./SM/ManagerOrders";
import BDAEscalation from "./BDA/BDAEscalation";
import SMPendingApproval from "./SM/SMPendingApproval";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Qis from "./BDA/BDA-QIS";
import PTCSDashboard from "./dashboards/PTCSDashboard";
import PTCSOrders from "./PTCS/PTCSOrders";
import PTCSPendingApproval from "./PTCS/PTCSPendingApproval";
import SMEscalation from "./SM/SMEscalation";
import CSDashboard from "./dashboards/CSDashboard";
import BriefSheets from "./Client-Servicing/BriefSheets";
import PGDashboard from "./dashboards/PGDashboard";
import BriefSheetsInPGD from "./PGD/BriefSheetsInPGD";
import GraphicDesigns from "./Client-Servicing/GraphicDesigns";
import JRPTDashboard from "./dashboards/JRPTDashboard";
import PendingSamplesInJRPT from "./JRPT/PendingSamplesInJRPT";
import PendingFinalProduction from "./JRPT/PendingFinalProduction";
import SampleDispatch from "./Client-Servicing/SampleDispatch";
import FinalDispatch from "./Client-Servicing/FinalDispatch";
import PTCSEscalation from "./PTCS/PTCSEscalation";
import CSQIS from "./Client-Servicing/CS-QIS";
import FBBBoxContainer from "./InputFieldsForBoxes/FBBBoxContainer";
import CorrugatedContainer from "./InputFieldsForBoxes/CorrugatedContainer";
import OrderDetailsProvider from "./context/OrderDetailsContext";
import QISOrdersContextProvider from "./context/QISOrdersContext";
import StatusInCS from "./Client-Servicing/StatusInCS";
import "./index.css";
import CSProformaInvoiceInput from "./Client-Servicing/CSProformaInvoiceInput";
import ProformaInvoiceContextProvider from "./context/ProformaInvoiceContext";
import PreviousQuotations from "./BDA/PreviousQuotations";
import SearchProvider from "./context/SearchContext";
import ViewInvoice from "./Client-Servicing/ViewInvoice";
import { CommercialQISContextProvider } from "./context/CommercialQISContext";

const App = () => {
  return (
    <BrowserRouter>
      <OrderDetailsProvider>
        <QISOrdersContextProvider>
          <CommercialQISContextProvider>
            <ProformaInvoiceContextProvider>
              <SearchProvider>
                <UniqueIdProvider>
                  <Switch>
                    <Route exact path="/">
                      <ToastContainer />
                      <LoginPage />
                    </Route>
                    <Route exact path="/box">
                      <FBBBoxContainer />
                    </Route>
                    <Route exact path="/box/1">
                      <CorrugatedContainer />
                    </Route>
                    <Route exact path="/dashboard">
                      <Dashboard />
                    </Route>
                    <Route exact path="/salesDashboard">
                      <SalesDashboard />
                    </Route>
                    <Route exact path="/salesDashboard/managerOrders">
                      <ManagerOrders />
                    </Route>
                    <Route exact path="/salesDashboard/sm-pending-approval">
                      <ToastContainer />
                      <SMPendingApproval />
                    </Route>
                    <Route exact path="/salesDashboard/SMEscalation">
                      <ToastContainer />
                      <SMEscalation />
                    </Route>

                    <Route exact path="/dashboard/InputField">
                      <ToastContainer />
                      <InputField />
                    </Route>
                    <Route exact path="/dashboard/BDAEscalation">
                      <ToastContainer />
                      <BDAEscalation />
                    </Route>
                    <Route exact path="/PTCSDashboard">
                      <PTCSDashboard />
                    </Route>

                    <Route exact path="/PTCSDashboard/PTCSPendingApproval">
                      <ToastContainer />
                      <PTCSPendingApproval />
                    </Route>

                    <Route exact path="/PTCSDashboard/PTCSOrders">
                      <PTCSOrders />
                    </Route>

                    <Route exact path="/PTCSDashboard/PTCSEscalation">
                      <ToastContainer />
                      <PTCSEscalation />
                    </Route>
                    <Route exact path="/dashboard/Qis">
                      <ToastContainer />
                      <Qis />
                    </Route>

                    <Route exact path="/dashboard/prevQuotations">
                      <PreviousQuotations />
                    </Route>

                    <Route exact path="/dashboard/Orders">
                      <Orders />
                    </Route>
                    <Route exact path="/CSDashboard">
                      <CSDashboard />
                    </Route>
                    <Route exact path="/CSDashboard/BriefSheets">
                      <ToastContainer />
                      <BriefSheets />
                    </Route>
                    <Route exact path="/CSDashboard/StatusPage">
                      <ToastContainer />
                      <StatusInCS />
                    </Route>
                    <Route exact path="/CSDashboard/GraphicDesigns">
                      <ToastContainer />
                      <GraphicDesigns />
                    </Route>
                    <Route exact path="/CSDashboard/SampleDispatch">
                      <ToastContainer />
                      <SampleDispatch />
                    </Route>

                    <Route exact path="/CSDashboard/CSQIS">
                      <ToastContainer />
                      <CSQIS />
                    </Route>
                    <Route exact path="/CSDashboard/CSInvoiceDetails">
                      <ToastContainer />
                      <CSProformaInvoiceInput />
                    </Route>
                    <Route exact path="/CSDashboard/ViewPerformaInvoice">
                      <ToastContainer />
                      <ViewInvoice />
                    </Route>

                    <Route exact path="/CSDashboard/FinalDispatch">
                      <ToastContainer />
                      <FinalDispatch />
                    </Route>
                    <Route exact path="/PGDashboard/">
                      <PGDashboard />
                    </Route>
                    <Route exact path="/PGDashboard/BriefSheetsInPGD">
                      <ToastContainer />
                      <BriefSheetsInPGD />
                    </Route>
                    <Route exact path="/JRPTDashboard">
                      <JRPTDashboard />
                    </Route>
                    <Route exact path="/JRPTDashboard/PendingSamplesInJRPT">
                      <ToastContainer />
                      <PendingSamplesInJRPT />
                    </Route>
                    <Route exact path="/JRPTDashboard/PendingFinalProduction">
                      <ToastContainer />
                      <PendingFinalProduction />
                    </Route>
                  </Switch>
                </UniqueIdProvider>
              </SearchProvider>
            </ProformaInvoiceContextProvider>
          </CommercialQISContextProvider>
        </QISOrdersContextProvider>
      </OrderDetailsProvider>
    </BrowserRouter>
  );
};

export default App;
