import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UniqueIdProvider from "./context/UniqueIdContext";
import "react-toastify/dist/ReactToastify.css";
import OrderDetailsProvider from "./context/OrderDetailsContext";
import QISOrdersContextProvider from "./context/QISOrdersContext";
import ProformaInvoiceContextProvider from "./context/ProformaInvoiceContext";
import SearchProvider from "./context/SearchContext";
import { CommercialQISContextProvider } from "./context/CommercialQISContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <OrderDetailsProvider>
      <QISOrdersContextProvider>
        <CommercialQISContextProvider>
          <ProformaInvoiceContextProvider>
            <SearchProvider>
              <UniqueIdProvider>
                <App />
              </UniqueIdProvider>
            </SearchProvider>
          </ProformaInvoiceContextProvider>
        </CommercialQISContextProvider>
      </QISOrdersContextProvider>
    </OrderDetailsProvider>
  </React.StrictMode>
);

reportWebVitals();
