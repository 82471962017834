import React, { memo } from "react";
import "../ReusableCSS/InputTextField.css";

const InputTextField = ({ label, value, setValue, placeholder }) => {
  return (
    <div>
      <label htmlFor={label}>{label} </label>
      <input
        placeholder={placeholder}
        className="InputFieldCSS"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        required
      />
    </div>
  );
};

export default memo(InputTextField);
