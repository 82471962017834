import React from "react";

const FBBClosureImagesDisplay = ({ selectedClosure}) => {
    return (
        <div style={{ margin: "20px"}}>

            {/*  */}
            {/*  */}
            {/* APPAREL SECTION */}
            {/*  */}
            {/*  */}

            {selectedClosure === "fbe119" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe119open.png"}
                        alt="Description of fbe119"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe119closed.png"}
                        alt="Description of fbe119"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe119mock.png"}
                        alt="Description of fbe119"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe119kld.png"}
                        alt="Description of fbe119"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe127" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe127open.png"}
                        alt="Description of fbe127"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe127closed.png"}
                        alt="Description of fbe127"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe127mock.png"}
                        alt="Description of fbe127"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe127kld.png"}
                        alt="Description of fbe127"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe288" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe288open.png"}
                        alt="Description of fbe288"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe288closed.png"}
                        alt="Description of fbe288"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe288mock.png"}
                        alt="Description of fbe288"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe288kld.png"}
                        alt="Description of fbe288"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe431" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe431open.png"}
                        alt="Description of fbe431"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe431closed.png"}
                        alt="Description of fbe431"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe431mock.png"}
                        alt="Description of fbe431"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/apparel/fbe431kld.png"}
                        alt="Description of fbe431"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* BAKERY AND CAKES SECTION */}
            {/* bakery_and_cakes */}
            {/*  */}

            {selectedClosure === "b153" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/b153open.png"}
                        alt="Description of b153"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/b153closed.png"}
                        alt="Description of b153"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/b153mock.png"}
                        alt="Description of b153"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/b153kld.png"}
                        alt="Description of b153"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "b154" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/b154open.png"}
                        alt="Description of b154"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/b154closed.png"}
                        alt="Description of b154"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/b154mock.png"}
                        alt="Description of b154"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/b154kld.png"}
                        alt="Description of b154"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe066" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe066open.png"}
                        alt="Description of fbe066"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe066closed.png"}
                        alt="Description of fbe066"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe066mock.png"}
                        alt="Description of fbe066"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe066kld.png"}
                        alt="Description of fbe066"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe115" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe115open.png"}
                        alt="Description of fbe115"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe115closed.png"}
                        alt="Description of fbe115"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe115mock.png"}
                        alt="Description of fbe115"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe115kld.png"}
                        alt="Description of fbe115"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe118" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe118open.png"}
                        alt="Description of fbe118"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe118closed.png"}
                        alt="Description of fbe118"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe118mock.png"}
                        alt="Description of fbe118"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe118kld.png"}
                        alt="Description of fbe118"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe120" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe120open.png"}
                        alt="Description of fbe120"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe120closed.png"}
                        alt="Description of fbe120"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe120mock.png"}
                        alt="Description of fbe120"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe120kld.png"}
                        alt="Description of fbe120"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe139" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe139open.png"}
                        alt="Description of fbe139"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe139closed.png"}
                        alt="Description of fbe139"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe139mock.png"}
                        alt="Description of fbe139"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe139kld.png"}
                        alt="Description of fbe139"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe282" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe282open.png"}
                        alt="Description of fbe282"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe282closed.png"}
                        alt="Description of fbe282"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe282mock.png"}
                        alt="Description of fbe282"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe282kld.png"}
                        alt="Description of fbe282"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe473" && (
                <div className="gridOfClosureImages">
                <img
                    src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe473open.png"}
                    alt="Description of fbe473"
                    className="image-element"
                    style={{ width: "100%", height: "auto" }}
                />
                <img
                    src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe473closed.png"}
                    alt="Description of fbe473"
                    className="image-element"
                    style={{ width: "100%", height: "auto" }}
                />
                <img
                    src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe473mock.png"}
                    alt="Description of fbe473"
                    className="image-element"
                    style={{ width: "100%", height: "auto" }}
                />
                <img
                    src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe473kld.png"}
                    alt="Description of fbe473"
                    className="image-element"
                    style={{ width: "100%", height: "auto" }}
                />
            </div>
            )}
            {selectedClosure === "fbe474" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe474open.png"}
                        alt="Description of fbe474"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe474closed.png"}
                        alt="Description of fbe474"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe474mock.png"}
                        alt="Description of fbe474"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe474kld.png"}
                        alt="Description of fbe474"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe505" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe505open.png"}
                        alt="Description of fbe505"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe505closed.png"}
                        alt="Description of fbe505"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe505mock.png"}
                        alt="Description of fbe505"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe505kld.png"}
                        alt="Description of fbe505"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe504" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe504open.png"}
                        alt="Description of fbe504"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe504closed.png"}
                        alt="Description of fbe504"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe504mock.png"}
                        alt="Description of fbe504"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe504kld.png"}
                        alt="Description of fbe504"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe6015" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe6015open.png"}
                        alt="Description of fbe6015"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe6015closed.png"}
                        alt="Description of fbe6015"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe6015mock.png"}
                        alt="Description of fbe6015"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe6015kld.png"}
                        alt="Description of fbe6015"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe476" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe476open.png"}
                        alt="Description of fbe476"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe476closed.png"}
                        alt="Description of fbe476"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe476mock.png"}
                        alt="Description of fbe476"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe476kld.png"}
                        alt="Description of fbe476"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe507" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe507open.png"}
                        alt="Description of fbe507"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe507closed.png"}
                        alt="Description of fbe507"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe507mock.png"}
                        alt="Description of fbe507"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe507kld.png"}
                        alt="Description of fbe507"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe506" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe506open.png"}
                        alt="Description of fbe506"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe506closed.png"}
                        alt="Description of fbe506"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe506mock.png"}
                        alt="Description of fbe506"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe506kld.png"}
                        alt="Description of fbe506"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe279" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe279open.png"}
                        alt="Description of fbe279"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe279closed.png"}
                        alt="Description of fbe279"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe279mock.png"}
                        alt="Description of fbe279"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/bakery_and_cakes/fbe279kld.png"}
                        alt="Description of fbe279"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* Chocolates and Candies SECTION */}
            {/* chocolates_and_candies */}
            {/*  */}

            {selectedClosure === "fbe508" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe508open.png"}
                        alt="Description of fbe508"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe508closed.png"}
                        alt="Description of fbe508"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe508mock.png"}
                        alt="Description of fbe508"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe508kld.png"}
                        alt="Description of fbe508"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe082" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe082open.png"}
                        alt="Description of fbe082"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe082closed.png"}
                        alt="Description of fbe082"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe082mock.png"}
                        alt="Description of fbe082"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe082kld.png"}
                        alt="Description of fbe082"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe6003" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe6003open.png"}
                        alt="Description of fbe6003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe6003closed.png"}
                        alt="Description of fbe6003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe6003mock.png"}
                        alt="Description of fbe6003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe6003kld.png"}
                        alt="Description of fbe6003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe101" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe101open.png"}
                        alt="Description of fbe101"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe101closed.png"}
                        alt="Description of fbe101"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe101mock.png"}
                        alt="Description of fbe101"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/chocolates_and_candies/fbe101kld.png"}
                        alt="Description of fbe101"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* COFFEE SECTION */}
            {/* coffee */}
            {/*  */}

            {selectedClosure === "f001" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f001open.png"}
                        alt="Description of f001"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f001closed.png"}
                        alt="Description of f001"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f001mock.png"}
                        alt="Description of f001"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f001kld.png"}
                        alt="Description of f001"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f002" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f002open.png"}
                        alt="Description of f002"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f002closed.png"}
                        alt="Description of f002"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f002mock.png"}
                        alt="Description of f002"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f002kld.png"}
                        alt="Description of f002"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f003" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f003open.png"}
                        alt="Description of f003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f003closed.png"}
                        alt="Description of f003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f003mock.png"}
                        alt="Description of f003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f003kld.png"}
                        alt="Description of f003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f005" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f005open.png"}
                        alt="Description of f005"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f005closed.png"}
                        alt="Description of f005"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f005mock.png"}
                        alt="Description of f005"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f005kld.png"}
                        alt="Description of f005"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f006" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f006open.png"}
                        alt="Description of f006"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f006closed.png"}
                        alt="Description of f006"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f006mock.png"}
                        alt="Description of f006"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f006kld.png"}
                        alt="Description of f006"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f009" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f009open.png"}
                        alt="Description of f009"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f009closed.png"}
                        alt="Description of f009"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f009mock.png"}
                        alt="Description of f009"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f009kld.png"}
                        alt="Description of f009"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f013" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f013open.png"}
                        alt="Description of f013"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f013closed.png"}
                        alt="Description of f013"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f013mock.png"}
                        alt="Description of f013"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f013kld.png"}
                        alt="Description of f013"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f014" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f014open.png"}
                        alt="Description of f014"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f014closed.png"}
                        alt="Description of f014"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f014mock.png"}
                        alt="Description of f014"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f014kld.png"}
                        alt="Description of f014"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f021" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f021open.png"}
                        alt="Description of f021"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f021closed.png"}
                        alt="Description of f021"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f021mock.png"}
                        alt="Description of f021"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f021kld.png"}
                        alt="Description of f021"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f022" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f022open.png"}
                        alt="Description of f022"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f022closed.png"}
                        alt="Description of f022"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f022mock.png"}
                        alt="Description of f022"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f022kld.png"}
                        alt="Description of f022"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f023" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f023open.png"}
                        alt="Description of f023"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f023closed.png"}
                        alt="Description of f023"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f023mock.png"}
                        alt="Description of f023"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f023kld.png"}
                        alt="Description of f023"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "f024" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f024open.png"}
                        alt="Description of f024"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f024closed.png"}
                        alt="Description of f024"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f024mock.png"}
                        alt="Description of f024"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/coffee/f024kld.png"}
                        alt="Description of f024"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* COSMETICS SECTION */}
            {/* cosmetics */}
            {/*  */}

            {selectedClosure === "fbe443" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe443open.png"}
                        alt="Description of fbe443"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe443closed.png"}
                        alt="Description of fbe443"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe443mock.png"}
                        alt="Description of fbe443"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe443kld.png"}
                        alt="Description of fbe443"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe502" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe502open.png"}
                        alt="Description of fbe502"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe502closed.png"}
                        alt="Description of fbe502"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe502mock.png"}
                        alt="Description of fbe502"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe502kld.png"}
                        alt="Description of fbe502"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe501" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe501open.png"}
                        alt="Description of fbe501"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe501closed.png"}
                        alt="Description of fbe501"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe501mock.png"}
                        alt="Description of fbe501"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe501kld.png"}
                        alt="Description of fbe501"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe499" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe499open.png"}
                        alt="Description of fbe499"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe499closed.png"}
                        alt="Description of fbe499"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe499mock.png"}
                        alt="Description of fbe499"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe499kld.png"}
                        alt="Description of fbe499"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe241" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe241open.png"}
                        alt="Description of fbe241"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe241closed.png"}
                        alt="Description of fbe241"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe241mock.png"}
                        alt="Description of fbe241"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe241kld.png"}
                        alt="Description of fbe241"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe214" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe214open.png"}
                        alt="Description of fbe214"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe214closed.png"}
                        alt="Description of fbe214"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe214mock.png"}
                        alt="Description of fbe214"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe214kld.png"}
                        alt="Description of fbe214"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe442" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe442open.png"}
                        alt="Description of fbe442"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe442closed.png"}
                        alt="Description of fbe442"
                        className="image-element"
                        style={{ width: "40%", height: "auto", transform: "rotate(90deg)" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe442mock.png"}
                        alt="Description of fbe442"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe442kld.png"}
                        alt="Description of fbe442"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe441" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe441open.png"}
                        alt="Description of fbe441"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe441closed.png"}
                        alt="Description of fbe441"
                        className="image-element"
                        style={{ width: "40%", height: "auto", marginLeft: "30%", marginTop: "10%" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe441mock.png"}
                        alt="Description of fbe441"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe441kld.png"}
                        alt="Description of fbe441"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe6093" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe6093open.png"}
                        alt="Description of fbe6093"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe6093closed.png"}
                        alt="Description of fbe6093"
                        className="image-element"
                        style={{ width: "40%", height: "auto", marginLeft: "30%", marginTop: "20%"}}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe6093mock.png"}
                        alt="Description of fbe6093"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/cosmetics/fbe6093kld.png"}
                        alt="Description of fbe6093"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            
            {/*  */}
            {/*  */}
            {/* RETAIL SECTION */}
            {/* retail */}
            {/*  */}

            {selectedClosure === "fbe458" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe458open.png"}
                        alt="Description of fbe458"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe458closed.png"}
                        alt="Description of fbe458"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe458mock.png"}
                        alt="Description of fbe458"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe458kld.png"}
                        alt="Description of fbe458"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe459" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe459open.png"}
                        alt="Description of fbe459"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe459closed.png"}
                        alt="Description of fbe459"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe459mock.png"}
                        alt="Description of fbe459"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe459kld.png"}
                        alt="Description of fbe459"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe461" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe461open.png"}
                        alt="Description of fbe461"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe461closed.png"}
                        alt="Description of fbe461"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe461mock.png"}
                        alt="Description of fbe461"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe461kld.png"}
                        alt="Description of fbe461"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe465" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe465open.png"}
                        alt="Description of fbe465"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe465closed.png"}
                        alt="Description of fbe465"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe465mock.png"}
                        alt="Description of fbe465"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe465kld.png"}
                        alt="Description of fbe465"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe468" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe468open.png"}
                        alt="Description of fbe468"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe468closed.png"}
                        alt="Description of fbe468"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe468mock.png"}
                        alt="Description of fbe468"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe468kld.png"}
                        alt="Description of fbe468"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe471" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe471open.png"}
                        alt="Description of fbe471"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe471closed.png"}
                        alt="Description of fbe471"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe471mock.png"}
                        alt="Description of fbe471"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe471kld.png"}
                        alt="Description of fbe471"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe470" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe470open.png"}
                        alt="Description of fbe470"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe470closed.png"}
                        alt="Description of fbe470"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe470mock.png"}
                        alt="Description of fbe470"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe470kld.png"}
                        alt="Description of fbe470"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe467" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe467open.png"}
                        alt="Description of fbe467"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe467closed.png"}
                        alt="Description of fbe467"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe467mock.png"}
                        alt="Description of fbe467"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe467kld.png"}
                        alt="Description of fbe467"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe466" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe466open.png"}
                        alt="Description of fbe466"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe466closed.png"}
                        alt="Description of fbe466"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe466mock.png"}
                        alt="Description of fbe466"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe466kld.png"}
                        alt="Description of fbe466"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe464" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe464open.png"}
                        alt="Description of fbe464"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe464closed.png"}
                        alt="Description of fbe464"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe464mock.png"}
                        alt="Description of fbe464"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe464kld.png"}
                        alt="Description of fbe464"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe462" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe462open.png"}
                        alt="Description of fbe462"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe462closed.png"}
                        alt="Description of fbe462"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe462mock.png"}
                        alt="Description of fbe462"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe462kld.png"}
                        alt="Description of fbe462"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe493" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe493open.png"}
                        alt="Description of fbe493"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe493closed.png"}
                        alt="Description of fbe493"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe493mock.png"}
                        alt="Description of fbe493"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe493kld.png"}
                        alt="Description of fbe493"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe495" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe495open.png"}
                        alt="Description of fbe495"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe495closed.png"}
                        alt="Description of fbe495"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe495mock.png"}
                        alt="Description of fbe495"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe495kld.png"}
                        alt="Description of fbe495"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe496" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe496open.png"}
                        alt="Description of fbe496"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe496closed.png"}
                        alt="Description of fbe496"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe496mock.png"}
                        alt="Description of fbe496"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe496kld.png"}
                        alt="Description of fbe496"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe498" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe498open.png"}
                        alt="Description of fbe498"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe498closed.png"}
                        alt="Description of fbe498"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe498mock.png"}
                        alt="Description of fbe498"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe498kld.png"}
                        alt="Description of fbe498"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe042" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe042open.png"}
                        alt="Description of fbe042"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe042closed.png"}
                        alt="Description of fbe042"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe042mock.png"}
                        alt="Description of fbe042"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe042kld.png"}
                        alt="Description of fbe042"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe055" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe055open.png"}
                        alt="Description of fbe055"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe055closed.png"}
                        alt="Description of fbe055"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe055mock.png"}
                        alt="Description of fbe055"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe055kld.png"}
                        alt="Description of fbe055"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe056" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe056open.png"}
                        alt="Description of fbe056"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe056closed.png"}
                        alt="Description of fbe056"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe056mock.png"}
                        alt="Description of fbe056"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe056kld.png"}
                        alt="Description of fbe056"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe057" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe057open.png"}
                        alt="Description of fbe057"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe057closed.png"}
                        alt="Description of fbe057"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe057mock.png"}
                        alt="Description of fbe057"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe057kld.png"}
                        alt="Description of fbe057"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe058" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe058open.png"}
                        alt="Description of fbe058"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe058closed.png"}
                        alt="Description of fbe058"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe058mock.png"}
                        alt="Description of fbe058"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe058kld.png"}
                        alt="Description of fbe058"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe061" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe061open.png"}
                        alt="Description of fbe061"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe061closed.png"}
                        alt="Description of fbe061"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe061mock.png"}
                        alt="Description of fbe061"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe061kld.png"}
                        alt="Description of fbe061"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe063" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe063open.png"}
                        alt="Description of fbe063"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe063closed.png"}
                        alt="Description of fbe063"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe063mock.png"}
                        alt="Description of fbe063"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe063kld.png"}
                        alt="Description of fbe063"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe065" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe065open.png"}
                        alt="Description of fbe065"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe065closed.png"}
                        alt="Description of fbe065"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe065mock.png"}
                        alt="Description of fbe065"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe065kld.png"}
                        alt="Description of fbe065"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe078" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe078open.png"}
                        alt="Description of fbe078"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe078closed.png"}
                        alt="Description of fbe078"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe078mock.png"}
                        alt="Description of fbe078"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe078kld.png"}
                        alt="Description of fbe078"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe126" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe126open.png"}
                        alt="Description of fbe126"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe126closed.png"}
                        alt="Description of fbe126"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe126mock.png"}
                        alt="Description of fbe126"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe126kld.png"}
                        alt="Description of fbe126"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe140" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe140open.png"}
                        alt="Description of fbe140"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe140closed.png"}
                        alt="Description of fbe140"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe140mock.png"}
                        alt="Description of fbe140"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/FBBBoxes/retail/fbe140kld.png"}
                        alt="Description of fbe140"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
        </div>
    );
};

export default FBBClosureImagesDisplay;











