import React from "react";

const DemoOrders = ({ orders }) => {
  if (!Array.isArray(orders) || orders.length === 0) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div>
      <div>
        <div className="ordersContainer">
          {orders.map((order) => (
            <div className="ordersItem" key={order._id}>
              {order.orderId && (
                <div className="orderBoxId">
                  <p
                    style={{
                      color: "#800000",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    ORDER ID: {order.orderId}
                  </p>
                </div>
              )}

              <p>
                <b>Client Name : </b> {order.clientName}
              </p>
              <p>
                <b>Client Email : </b>
                {order.clientEmail}
              </p>
              {order.clientAddress && (
                <p>
                  <b>Client Address : </b>
                  {order.clientAddress}
                </p>
              )}

              {order.clientPincode && (
                <p>
                  <b>Client Pincode :</b> {order.clientPincode}
                </p>
              )}

              {order.clientContact && (
                <p>
                  <b>Client Contact : </b> {order.clientContact}
                </p>
              )}

              <p>
                <b>Product : </b> {order.product}
              </p>

              {order.clientGST && (
                <p>
                  <b>Client GST :</b> {order.clientGST}
                </p>
              )}

              <p>
                <b>Box Type : </b> {order.boxType}
              </p>
              <p>
                <b>Closure :</b> {order.closure}
              </p>
              <p>
                <b>Dimension : </b> {order.dim} {order.dimUnit}
              </p>

              <p>
                <b>Quantity 1 : </b>
                {order.qtt1}
              </p>
              <p>
                <b>Quantity 2 :</b> {order.qtt2}
              </p>
              <p>
                <b>Quantity 3 :</b> {order.qtt3}
              </p>
              <p>
                <b>Paper GSM :</b> {order.quality}
              </p>
              <p>
                <b>Region : </b> {order.region}
              </p>
              <p>
                <b>Price 1: </b>
                {order.price1}
              </p>
              <p>
                <b>Price 2 : </b>
                {order.price2}
              </p>
              <p>
                <b>Price 3 : </b>
                {order.price3}
              </p>
              <p>
                <b>Transport : </b>
                {order.transport}
              </p>
              {order.saleDate && (
                <p>
                  <b>Sale Date : </b>
                  {order.saleDate}
                </p>
              )}
              {order.remarks && (
                <p>
                  <b>Remarks/Additional Info : </b>
                  {order.remarks}
                </p>
              )}

              <p>
                <b> Applications : </b>
                <div className="applicationsValue">
                  {Object.entries(order.applications)
                    .filter(([key, value]) => value === true)
                    .map(([app]) => (
                      <div key={app}>{app}</div>
                    ))}
                </div>
              </p>

              <div className="applicationsValueContainer">
                <p>
                  <b> Applications Value : </b>
                  <div className="applicationsValue">
                    {Object.keys(order.applicationsValue).map((app) => (
                      <div key={app}>
                        {order.applicationsValue[app] &&
                          ` ${app} : ${order.applicationsValue[app]}%`}
                      </div>
                    ))}
                  </div>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DemoOrders;
