import React, { useContext } from "react";
import "./QuotationPriceDisplay.css";
import { UniqueIdContext } from "../context/UniqueIdContext";

const QuotationPriceDisplayCommercial = ({ qtt1, qtt2, qtt3, tableData }) => {

    const roundedPrice1 = tableData.first;
    const roundedPrice2 = tableData.second;
    const roundedPrice3 = tableData.third;

    return (
        <div className="cardContainer">
            <div className="card">
                <div className="cardContent">
                    <div className="quotationTitle">QUOTATION</div>
                    <div className="field">
                        <label>Price for {qtt1} Quantity: </label>
                        <span>₹ {roundedPrice1}</span>
                    </div>
                    <div className="field">
                        <label>Price for {qtt2} Quantity: </label>
                        <span>₹ {roundedPrice2}</span>
                    </div>
                    <div className="field">
                        <label>Price for {qtt3} Quantity: </label>
                        <span>₹ {roundedPrice3}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuotationPriceDisplayCommercial;
