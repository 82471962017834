import React, { useState } from "react";
import UserDropdown from "../reusable/UserDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import {
  acceptedOrderByCSForGD,
  graphicDesignsInCS,
  rejectedOrderByCSForGD,
} from "../apis/apiClients";
import { PDF_URL } from "../constants/constants";
import "./GraphicDesigns.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { rejectedOrderByCSForPTCS } from "../apis/apiClients";
import { BASE_URL } from "../constants/constants";
import SearchComponent from "../searchBar/SearchComponent";

const GraphicDesigns = () => {
  const [orders, setOrders] = useState([]);
  const history = useHistory();

  const handlePrev = () => {
    history.push("/CSDashboard");
  };

  const uniqueId = localStorage.getItem("uniqueId");

  const handleAcceptInJrPT = async (orderId) => {
    const confirmAccept = window.confirm(
      "Are you sure you want to accept this order?"
    );

    if (confirmAccept) {
      try {
        const response = await acceptedOrderByCSForGD(orderId);
        if (response.status === 200) {
          console.log("Acccepted");
        }
        toast.success("Order Has Been Accepted Successfully!");
      } catch (error) {
        console.log(error);
      }
    }

    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  const sendBackToCSDashboard = async (orderId) => {
    const confirmReject = window.confirm(
      "Are you sure you want to reject this order?"
    );

    if (confirmReject) {
      try {
        const response = await rejectedOrderByCSForGD(orderId);
        if (response.status === 200) {
          console.log("Rejected");
        }
        toast.success("Order Has Been Rejected to PGD Successfully!");
      } catch (error) {
        console.log(error);
      }
    }
    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  const sendBackToPTCSDashboard = async (orderId) => {
    const confirmReject = window.confirm(
      "Are you sure you want to reject this order?"
    );

    if (confirmReject) {
      try {
        const response = await rejectedOrderByCSForPTCS(orderId);
        if (response.status === 200) {
          console.log("rejected");
        }
        toast.success("Order Has Been Rejected to PTCS Successfully!");
      } catch (error) {
        console.log(error);
      }
    }
    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await graphicDesignsInCS(uniqueId);
        console.log(response, "response");

        if (response) {
          const orders = response.orders;
          setOrders(orders);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrders();
  }, [uniqueId]);

  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">Graphic Designs</b>
            <UserDropdown />
          </div>
        </div>
      </div>
      <h2>Number of Orders : {orders.length}</h2>
      <SearchComponent orders={orders} setOrders={setOrders} />
      <div className="ordersContainer">
        {orders.length === 0 ? (
          <h3>No orders found.</h3>
        ) : (
          orders.map((order) => (
            <div className="ordersItem" key={order._id}>
              <div className="orderBoxId">
                <p
                  style={{
                    color: "#800000",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  ORDER ID: {order.orderId}
                </p>
              </div>
              <p>
                <b>Client Name : </b> {order.clientName}
              </p>
              <p>
                <b>Client Email : </b> {order.clientEmail}
              </p>
              <p>
                <b>Client Address : </b> {order.clientAddress}
              </p>
              <p>
                <b>Client Contact : </b> {order.clientContact}
              </p>
              <p>
                <b>Client Pincode :</b> {order.clientPincode}
              </p>
              <p>
                <b>Client GST : </b> {order.clientGST}
              </p>
              <p>
                <b>Box Type : </b> {order.boxType}
              </p>
              <p>
                <b>Closure :</b> {order.closure}
              </p>
              <p>
                <b>Dimension : </b>
                {order.dim} {order.dimUnit}
              </p>

              <p>
                <b>Quantity 1 : </b>
                {order.qtt1}
              </p>
              <p>
                <b>Quantity 2 : </b>
                {order.qtt2}
              </p>
              <p>
                <b>Quantity 3 :</b> {order.qtt3}
              </p>
              <p>
                <b>Paper GSM : </b> {order.quality}
              </p>
              <p>
                <b>Region : </b> {order.region}
              </p>
              <p>
                <b>Price 1 : </b>
                {order.price1}
              </p>
              <p>
                <b>Price 2 : </b>
                {order.price2}
              </p>
              <p>
                <b>Price 3 : </b>
                {order.price3}
              </p>
              <p>
                <b>Transport : </b>
                {order.transport}
              </p>
              <p>
                <b>Sale Date : </b>
                {order.saleDate}
              </p>
              <p>
                <b>Remarks/Additional Info : </b>
                {order.remarks}
              </p>

              <p>
                <b>Applications :</b>
                <div className="applicationsValue">
                  {Object.keys(order.applicationsValue).map((app) => (
                    <div key={app}>
                      {order.applicationsValue[app] &&
                        ` ${app} : ${order.applicationsValue[app]}%`}
                    </div>
                  ))}
                </div>
              </p>
              <p>
                <b>Reference Images :</b>
              </p>
              {order.imageUrls.length ? (
                <div>
                  {order.imageUrls.map((imageUrl, index) => (
                    <a
                      href={`${BASE_URL}${imageUrl.url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="viewPdfButton" key={index}>
                        <a
                          rel="noreferrer"
                          href={`${BASE_URL}${imageUrl}`}
                          target="_blank"
                          className="linkDecoration"
                        >
                          <div className="imageContainer" key={index}>
                            <img
                              key={imageUrl.index}
                              src={`${BASE_URL}${imageUrl}`}
                              alt="ImagesUpload"
                              className="hoverImage"
                              style={{
                                width: "120px",
                                height: "100px",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                        </a>
                      </button>
                    </a>
                  ))}
                </div>
              ) : (
                <h4>No Images found</h4>
              )}

              <br />
              <p>
                <b>View PDF :</b>
                <br />
              </p>
              <p>
                {order.fileIds.map((pdf, index) => (
                  <a
                    href={`${PDF_URL}/${pdf}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="viewPdfButton" key={index}>
                      <a
                        rel="noreferrer"
                        href={`${PDF_URL}/${pdf}`}
                        target="_blank"
                        className="linkDecoration"
                      >
                        <span className="linkText">PDF Design</span>
                        <span className="indexNumber">{index + 1}</span>
                      </a>
                    </button>
                  </a>
                ))}
              </p>
              <div className="buttonContainer">
                <button
                  onClick={() => handleAcceptInJrPT(order.orderId)}
                  className="acceptButtonSMPending"
                >
                  Accept &nbsp; ✅
                </button>
                <button
                  onClick={() => sendBackToCSDashboard(order.orderId)}
                  className="rejectButtonSMPending"
                >
                  Reject to PGD &nbsp;❌
                </button>
                <button
                  onClick={() => sendBackToPTCSDashboard(order.orderId)}
                  className="rejectButtonSMPending"
                >
                  Reject to PTCS &nbsp;❌
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default GraphicDesigns;
