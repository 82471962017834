import React , { useRef, useState, useContext } from "react";

import DropDownFields from "../../reusable/DropDownFields";
import { CommercialQISContext } from "../../context/CommercialQISContext";
import { PrintingColoursOptions } from "../../DropDownOptions/PrintingColoursOptions";
import { PrintingColoursOptionsInsidePaper } from "../../DropDownOptions/PrintingColoursOptionsInsidePaper";
import CommercialApplications from "../../DropDownOptions/CommercialApplications";
import { CommercialArtCardGSMOptions } from "../../DropDownOptions/CommercialArtCardGSMOptions";
import { CommercialArtPaperGSMOptions } from "../../DropDownOptions/CommercialArtPaperGSMOptions";
import { CommercialPaperTypeOptions } from "../../DropDownOptions/CommercialPaperTypeOptions";
import { CommercialMaplithoGSMOptions } from "../../DropDownOptions/CommercialMaplithoGSMOptions";
import { LaminationCommercialOptions } from "../../DropDownOptions/LaminationCommercialOptions";
import { CommercialChitGSMOptions } from "../../DropDownOptions/CommercialChitGSMOptions";
import InputTextField from "../../reusable/InputTextField";
import { CommercialLeavesPrintingOptions } from "../../DropDownOptions/CommercialLevesPrintingOptions";

const CommercialNotePad = () => {
    const quotationRef = useRef(null);
    const [displayQuotation, setDisplayQuotation] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [displayOrderDetails, setDisplayOrderDetails] = useState(false);

    const employeeId = localStorage.getItem("uniqueId");
    const boxType = "commercialPrinting";

    const {
        closure, setClosure,
        length, setLength,
        breadth, setBreadth,
        dimUnit, setDimUnit,
        qtt1, setQtt1,
        qtt2, setQtt2,
        qtt3, setQtt3,
        quality, setQuality,
        lamination, setLamination,
        transport, setTransport,
        region, setRegion,
        applications, setApplications,
        applicationsValue, setApplicationsValue,
        printingColours, setPrintingColours,
        printingColoursInsidePaper, setPrintingColoursInsidePaper,
        doubleSide, setDoubleSide,
        diaCut, setDiaCut,
        posterLayout, setPosterLayout,
        posterGummingType, setPosterGummingType,
        dimensionType, setDimensionType,
        standardDimensions, setStandardDimensions,

        folderType, setFolderType,
        pouchSize, setPouchSize,
        customPouchSize, setCustomPouchSize,

        brochureType, setBrochureType,
        bookletPages, setbookletPages,
        bookletBindingType, setBookletBindingType,
        
        bookletCoverPaperType, setBookletCoverPaperType,
        bookletCoverPaperGSM, setBookletCoverPaperGSM,
        bookletCoverOutsideColours, setBookletCoverOutsideColours,
        bookletCoverLamination, setBookletCoverLamination,
        bookletCoverDoubleSide, setBookletCoverDoubleSide,
        bookletCoverInsideColours, setBookletCoverInsideColours,
        bookletCoverApplications, setBookletCoverApplications,
        
        bookletInnerPaperType, setBookletInnerPaperType,
        bookletInnerPaperGSM, setBookletInnerPaperGSM,
        bookletInnerOutsideColours, setBookletInnerOutsideColours,
        bookletInnerLamination, setBookletInnerLamination,
        bookletInnerDoubleSide, setBookletInnerDoubleSide,
        bookletInnerInsideColours, setBookletInnerInsideColours,
        bookletInnerApplications, setBookletInnerApplications,
        prescriptionPadType, setPrescriptionPadType,

        chitPadGalleyType, setChitPadGalleyType,
        padThickness, setPadThickness,
        padInnerPaperGSM, setPadInnerPaperGSM,
        padOuterPaperGSM, setPadOuterPaperGSM,
        padInnerPrinting, setPadInnerPrinting,
        padInnerPrintingColours, setPadInnerPrintingColours,
        padGalleyApplications, setPadGalleyApplications,

        chitNoOfChits, setChitNoOfChits,
        chitLength, setChitLength,
        chitBreadth, setChitBreadth,
        chitGSM, setChitGSM,
        chitPrintingColours, setChitPrintingColours,
        chitBackSidePrinting, setChitBackSidePrinting,
        chitBackSideColours, setChitBackSideColours,

        prescriptionPadGalleyType, setPrescriptionPadGalleyType,
        prescriptionPadGalleyLength, setPrescriptionPadGalleyLength,
        prescriptionPadGalleyBreadth, setPrescriptionPadGalleyBreadth,
        prescriptionPadGalleyBoardThickness, setPrescriptionPadGalleyBoardThickness,
        prescriptionPadGalleyInnerPaperGSM, setPrescriptionPadGalleyInnerPaperGSM,
        prescriptionPadGalleyOuterPaperGSM, setPrescriptionPadGalleyOuterPaperGSM,
        prescriptionPadGalleyLamination, setPrescriptionPadGalleyLamination,
        prescriptionPadPerforation, setPrescriptionPadPerforation,

        notePadCoverPaperType, setNotePadCoverPaperType,
        notePadCoverPaperGSM, setNotePadCoverPaperGSM,
        notePadCoverOutsideColours, setNotePadCoverOutsideColours,
        notePadCoverLamination, setNotePadCoverLamination,
        notePadCoverDoubleSide, setNotePadCoverDoubleSide,
        notePadCoverInsideColours, setNotePadCoverInsideColours,

        leavesPrinting, setLeavesPrinting,

    } = useContext(CommercialQISContext);

    const resetCoverApplicationsValue = (appName) => {
        setBookletCoverApplications((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const handleCoverApplicationChange = (event) => {
            const { name, checked } = event.target;
            setBookletCoverApplications((prevApplications) => ({
            ...prevApplications,
            [name]: checked,
            }));
            if (!checked) {
            resetCoverApplicationsValue(name);
            }
        };

    return (
        <div>
            <label className="cover-inner-section-titile" style={{color:"#800000"}}>
                Galley (Rigid)
            </label>
            <div className="booklet-cover-inner-container">
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            options={CommercialPaperTypeOptions}
                            label={"Cover Paper Type"}
                            value={notePadCoverPaperType}
                            setValue={setNotePadCoverPaperType}
                        />
                    </div>
                    <div className="input-box">
                        {
                            (notePadCoverPaperType === "artPaper") && (
                                <DropDownFields
                                    options={CommercialArtPaperGSMOptions}
                                    label={"Art Paper GSM"}
                                    value={notePadCoverPaperGSM}
                                    setValue={setNotePadCoverPaperGSM}
                                />
                            )
                        }
                        {
                            (notePadCoverPaperType === "artCard") && (
                                <DropDownFields
                                    options={CommercialArtCardGSMOptions}
                                    label={"Art Card GSM"}
                                    value={notePadCoverPaperGSM}
                                    setValue={setNotePadCoverPaperGSM}
                                />
                            )
                        }
                        {
                            (notePadCoverPaperType === "maplitho") && (
                                <DropDownFields
                                    options={CommercialMaplithoGSMOptions}
                                    label={"Maplitho GSM"}
                                    value={notePadCoverPaperGSM}
                                    setValue={setNotePadCoverPaperGSM}
                                />
                            )
                        }
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            options={PrintingColoursOptions}
                            label={"Outside Colours"}
                            value={notePadCoverOutsideColours}
                            setValue={setNotePadCoverOutsideColours}
                        />
                    </div>
                    <div className="input-box">
                        <DropDownFields
                            options={LaminationCommercialOptions}
                            label={"Lamination"}
                            value={notePadCoverLamination}
                            setValue={setNotePadCoverLamination}                                                    
                        />
                    </div>
                </div>
                <label>Double side Printing</label>
                <div className="column">
                    <div className="input-box">
                        <div className="doubleSidePrinting">
                            <div class="cover-radio-label">
                                <input
                                    type="radio"
                                    name="notePadCoverDoubleSidePrinting"
                                    id="coverYes"
                                    value="yes"
                                    checked={notePadCoverDoubleSide === "yes"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setNotePadCoverDoubleSide(e.target.value)}
                                />
                                <label className="bookletCoverLabelNo" for="coverYes">
                                    Yes
                                </label>
                            </div>
                            <div className="cover-radio-label">
                                <input
                                    type="radio"
                                    name="notePadCoverDoubleSidePrinting"
                                    id="coverNo"
                                    value="no"
                                    checked={notePadCoverDoubleSide === "no"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setNotePadCoverDoubleSide(e.target.value)}
                                />
                                <label className="bookletCoverLabelNo" for="coverNo">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="input-box">
                        {notePadCoverDoubleSide==="yes" && (
                            <DropDownFields
                            options={PrintingColoursOptionsInsidePaper}
                            value={notePadCoverInsideColours}
                            setValue={setNotePadCoverInsideColours}
                            label={"No. of Colours for Inside Print"}
                            />
                        )}
                    </div>
                </div>
                <div className="applicationsGridContainer">
                    <label htmlFor="applications">Applications : </label>
                    <div className="commercialApplicationsGrid">
                        <CommercialApplications
                            applications={bookletCoverApplications}
                            handleApplicationChange={handleCoverApplicationChange}
                        />
                    </div>
                </div>
            </div>
            <label className="cover-inner-section-titile" style={{color:"#800000", paddingTop:"15px"}}>
                NotePad Leaves
            </label>
            <div className="booklet-cover-inner-container">
                <div className="column">
                    <div className="input-box">
                        <InputTextField
                            value={chitNoOfChits}
                            setValue={setChitNoOfChits}
                            label={"No. of chits"}
                        />
                    </div>
                    <div className="input-box">
                        <DropDownFields
                            value={chitGSM}
                            setValue={setChitGSM}
                            options={CommercialChitGSMOptions}
                            label={"GSM of Chits"}
                        />
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            value={leavesPrinting}
                            setValue={setLeavesPrinting}
                            options={CommercialLeavesPrintingOptions}
                            label={"Printing on leaves"}
                        />
                    </div>
                    <div className="input-box">
                        {
                            (leavesPrinting === "yes") && (
                                <DropDownFields
                                    value={chitPrintingColours}
                                    setValue={setChitPrintingColours}
                                    options={PrintingColoursOptions}
                                    label={"No of Colours for Printing"}
                                />
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CommercialNotePad;