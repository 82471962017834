import React, {useContext, useState, useRef} from "react";
import "./RigidBoxContainer.css";
import "./Applications.css";
import { toast } from "react-toastify";

import DropDownFields from "../reusable/DropDownFields";
import InputTextField from "../reusable/InputTextField";
import { CommercialClosuresOptions } from "../DropDownOptions/boxClosureTypes/CommercialClosuresOptions";
import { CommercialQISContext } from "../context/CommercialQISContext";
import { CommercialPaperGSMOptions } from "../DropDownOptions/CommercialPaperGSMOptions"
import { commercialQISapi } from "../apis/apiClients";
import { LaminationCommercialOptions } from "../DropDownOptions/LaminationCommercialOptions";
import { regionOptions } from "../DropDownOptions/RegionOptions";
import { transportOptions } from "../DropDownOptions/TransportOptions";
import CommercialApplications from "../DropDownOptions/CommercialApplications";
import { PrintingColoursOptions } from "../DropDownOptions/PrintingColoursOptions";
import { PrintingColoursOptionsInsidePaper } from "../DropDownOptions/PrintingColoursOptionsInsidePaper";
import { DimensionUnitOptions } from "../DropDownOptions/DimensionUnitOptions";
import OutputTables from "../BDA/OutputTables";
import QuotationPriceDisplayCommercial from "../components/QuotationPriceDisplayCommercial";
import { PosterTypeOptions } from "../DropDownOptions/PosterTypesOptions";
import { PaperGSMOptions } from "../DropDownOptions/PaperGSMOptions";
import { PosterGummingTypes } from "../DropDownOptions/PosterGummingTypes";
import { CommercialDimensionTypes } from "../DropDownOptions/CommercialDimensionTypes";
import { CommercialStandardPaperSizes } from "../DropDownOptions/CommercialStandardPaperSizes";
import { CommercialBrochureTypeOptions } from "../DropDownOptions/CommercialBrochureTypeOptions";
import { CommercialPaperTypeOptions } from "../DropDownOptions/CommercialPaperTypeOptions";
import { CommercialArtPaperGSMOptions } from "../DropDownOptions/CommercialArtPaperGSMOptions";
import { CommercialArtCardGSMOptions } from "../DropDownOptions/CommercialArtCardGSMOptions";
import { CommercialMaplithoGSMOptions } from "../DropDownOptions/CommercialMaplithoGSMOptions";
import { CommercialBookletBindingTypeOptions } from  "../DropDownOptions/CommercialBookletBindingTypeOptions";
import { PosterLaminationOptions} from "../DropDownOptions/PosterLaminationOptions";
import { PrescriptionPadTypeOptions } from "../DropDownOptions/PrescriptionPadTypeOptions";
import { CommercialFolderTypeOptions } from "../DropDownOptions/CommercialFolderTypeOptions";
import { CommercialFolderPouchSizeOptions } from "../DropDownOptions/CommercialFolderPouchSizeOptions";

import CommercialFolder from "./CommercialDashboards/CommercialFolder";
import CommercialCard from "./CommercialDashboards/CommercialCard";
import CommercialPoster from "./CommercialDashboards/CommercialPoster";
import CommercialBrochure from "./CommercialDashboards/CommercialBrochure";
import CommercialBooklet from "./CommercialDashboards/CommercialBooklet";
import CommercialPrescriptionPad from "./CommercialDashboards/CommercialPrescriptionPad";
import CommercialChitPad from "./CommercialDashboards/CommercialChitPad";
import CommercialNotePad from "./CommercialDashboards/CommercialNotePad";

const CommercialAlternateContainer = () => {
    const quotationRef = useRef(null);
    const [displayQuotation, setDisplayQuotation] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [displayOrderDetails, setDisplayOrderDetails] = useState(false);

    const employeeId = localStorage.getItem("uniqueId");
    const boxType = "commercialPrinting";

    const {
        closure, setClosure,
        length, setLength,
        breadth, setBreadth,
        dimUnit, setDimUnit,
        qtt1, setQtt1,
        qtt2, setQtt2,
        qtt3, setQtt3,
        quality, setQuality,
        lamination, setLamination,
        transport, setTransport,
        region, setRegion,
        applications, setApplications,
        applicationsValue, setApplicationsValue,
        printingColours, setPrintingColours,
        printingColoursInsidePaper, setPrintingColoursInsidePaper,
        doubleSide, setDoubleSide,
        diaCut, setDiaCut,
        posterLayout, setPosterLayout,
        posterGummingType, setPosterGummingType,
        dimensionType, setDimensionType,
        standardDimensions, setStandardDimensions,

        folderType, setFolderType,
        pouchSize, setPouchSize,
        customPouchSize, setCustomPouchSize,

        brochureType, setBrochureType,
        bookletPages, setbookletPages,
        bookletBindingType, setBookletBindingType,
        
        bookletCoverPaperType, setBookletCoverPaperType,
        bookletCoverPaperGSM, setBookletCoverPaperGSM,
        bookletCoverOutsideColours, setBookletCoverOutsideColours,
        bookletCoverLamination, setBookletCoverLamination,
        bookletCoverDoubleSide, setBookletCoverDoubleSide,
        bookletCoverInsideColours, setBookletCoverInsideColours,
        bookletCoverApplications, setBookletCoverApplications,
        
        bookletInnerPaperType, setBookletInnerPaperType,
        bookletInnerPaperGSM, setBookletInnerPaperGSM,
        bookletInnerOutsideColours, setBookletInnerOutsideColours,
        bookletInnerLamination, setBookletInnerLamination,
        bookletInnerDoubleSide, setBookletInnerDoubleSide,
        bookletInnerInsideColours, setBookletInnerInsideColours,
        bookletInnerApplications, setBookletInnerApplications,
        prescriptionPadType, setPrescriptionPadType,

    } = useContext(CommercialQISContext);

    const resetApplicationsValue = (appName) => {
        setApplicationsValue((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const resetCoverApplicationsValue = (appName) => {
        setBookletCoverApplications((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const resetInnerApplicationsValue = (appName) => {
        setBookletInnerApplications((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };
    
    const handleApplicationChange = (event) => {
        const { name, checked } = event.target;
        setApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetApplicationsValue(name);
        }
    };

    const handleCoverApplicationChange = (event) => {
        const { name, checked } = event.target;
        setBookletCoverApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetCoverApplicationsValue(name);
        }
    };

    const handleInnerApplicationChange = (event) => {
        const { name, checked } = event.target;
        setBookletInnerApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetInnerApplicationsValue(name);
        }
    };

    // const handleStandardDimensionChange = (selectedOption) => {
    //     setStandardDimensions(selectedOption);
    //     if (selectedOption==="A4") {
    //         setLength("8");
    //         setBreadth("11");
    //     }
    // };

    const handleDimensionChange = (selectedOption) => {
        setStandardDimensions(selectedOption);
        if (selectedOption === "A3") {
            setDimUnit("mm");
            setLength("297");
            setBreadth("420");
        }
        else if (selectedOption === "A4") {
            setDimUnit("mm");
            setLength("210");
            setBreadth("297");
        }
        else if (selectedOption === "A5") {
            setDimUnit("mm");
            setLength("148");
            setBreadth("210");
        }
        else if (selectedOption === "A6") {
            setDimUnit("mm");
            setLength("105");
            setBreadth("148");
        }
        else if (selectedOption === "4x6") {
            setDimUnit("inch");
            setLength("4");
            setBreadth("6");
        }
        else if (selectedOption === "5x7") {
            setDimUnit("inch");
            setLength("5");
            setBreadth("7");
        }
        else if (selectedOption === "5x9") {
            setDimUnit("inch");
            setLength("5");
            setBreadth("9");
        }
        else if (selectedOption === "4x7") {
            setDimUnit("inch");
            setLength("4");
            setBreadth("7");
        }
        else {
            // Reset length and breadth if option is not "A4"
            setDimUnit("");
            setLength("");
            setBreadth("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisplayOrderDetails(true);
        try {
            const res = await commercialQISapi(
                employeeId,
                boxType,
                closure,
                length,
                breadth,
                dimUnit,
                qtt1,
                qtt2,
                qtt3,
                quality,
                printingColours,
                transport,
                region,
                doubleSide,
                printingColoursInsidePaper,
                diaCut,
                lamination,
                applications,
                posterLayout,
                posterGummingType,
                dimensionType,
                standardDimensions,
                folderType,
                pouchSize,
                customPouchSize,
                brochureType,
                bookletPages,
                bookletBindingType,
                bookletCoverPaperType,
                bookletCoverPaperGSM,
                bookletCoverOutsideColours,
                bookletCoverLamination,
                bookletCoverDoubleSide,
                bookletCoverInsideColours,
                bookletCoverApplications,
                bookletInnerPaperType,
                bookletInnerPaperGSM,
                bookletInnerOutsideColours,
                bookletInnerLamination,
                bookletInnerDoubleSide,
                bookletInnerInsideColours,
                bookletInnerApplications,
            );
            if (res.status === 200) {
                setDisplayQuotation(true);
                toast.success("Quotation Generated successfully!");
            }
            setTableData(res.data);
            return res.data;
        } catch (error) {
        toast.error(`Failed to Generate the Quotation!: ${error}`);
        console.log(error);
        }
    };

    return (
        <div>
            <div className="outerContainer">
                <div className="container">
                    <div className="formTitle">Order Details</div>
                    <div className="formDetails">
                        <form onSubmit={handleSubmit} action="POST" class="form">
                            <div>
                                <DropDownFields
                                    label={"Closure"}
                                    value={closure}
                                    setValue={setClosure}
                                    options={CommercialClosuresOptions}
                                />
                            </div>
                            <label>Dimension Type</label>
                            <div className="column">
                                <div className="input-box">
                                    <div className="dimensionTypes">
                                        <div className="radio-label">
                                            <input
                                                type="radio"
                                                name="dimensionTypes"
                                                id="standard"
                                                value="standard"
                                                checked={dimensionType==="standard"}
                                                style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                                onChange={(e) => setDimensionType(e.target.value)}
                                            />
                                            <label className="labelStandard" for="standard">
                                                Standard
                                            </label>
                                        </div>
                                        <div className="radio-label">
                                            <input
                                                type="radio"
                                                name="dimensionTypes"
                                                id="custom"
                                                value="custom"
                                                checked={dimensionType==="custom"}
                                                style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                                onChange={(e) => setDimensionType(e.target.value)}
                                            />
                                            <label className="labelStandard" for="custom">
                                                Custom
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-box">
                                    {
                                        dimensionType === "standard" && (
                                            <DropDownFields
                                                label={"Standard Dimensions"}
                                                value={standardDimensions}
                                                setValue={handleDimensionChange}
                                                options={CommercialStandardPaperSizes}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            {
                                dimensionType==="custom" && (
                                    <div className="column">
                                        <div className="input-box">
                                            <DropDownFields
                                                label={"Dimention Unit"}
                                                value={dimUnit}
                                                setValue={setDimUnit}
                                                options={DimensionUnitOptions}
                                            />
                                        </div>
                                        <div className="input-box">
                                            <InputTextField
                                                label={"Length"}
                                                value={length}
                                                setValue={setLength}
                                                placeholder={"Enter Length"}
                                            />
                                        </div>
                                        <div className="input-box">
                                            <InputTextField
                                                label={"Breadth"}
                                                value={breadth}
                                                setValue={setBreadth}
                                                placeholder={"Enter Breadth"}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            {dimensionType === "standard" && (
                                <div className="column">
                                    <div className="input-box">
                                        <label>Dimention Unit</label>
                                        <label>{dimUnit}</label>
                                    </div>
                                    <div className="input-box">
                                        <label>Length</label>
                                        <label>{length}</label>
                                    </div>
                                    <div className="input-box">
                                        <label>Breadth</label>
                                        <label>{breadth}</label>
                                    </div>
                                </div>
                            )}
                            <div className="column">
                                <div className="input-box">
                                    <InputTextField
                                        value={qtt1}
                                        setValue={setQtt1}
                                        label={"Quantity 1"}
                                        placeholder={"Quantity 1"}
                                        required
                                    />
                                </div>
                                <div className="input-box">
                                    <InputTextField
                                        value={qtt2}
                                        setValue={setQtt2}
                                        label={"Quantity 2"}
                                        placeholder={"Quantity 2"}
                                    />
                                </div>
                                <div className="input-box">
                                    <InputTextField
                                        value={qtt3}
                                        setValue={setQtt3}
                                        label={"Quantity 3"}
                                        placeholder={"Quantity 3"}
                                    />
                                </div>
                            </div>
                            {
                                (closure === "card" ||
                                closure === "flyer" ||
                                closure === "certificate" ||
                                closure === "postCard" ||
                                closure === "menuCard" ||
                                closure === "doorHandle") && (
                                    <div>
                                        <CommercialCard/>
                                    </div>
                                )
                            }
                            {
                                (closure === "poster") && (
                                    <div>
                                        <CommercialPoster/>
                                    </div>
                                )
                            }
                            {
                                (closure === "folder") && (
                                    <div>
                                        <CommercialFolder/>
                                    </div>
                                )
                            }
                            {
                                (closure === "brochure") && (
                                    <div>
                                        <CommercialBrochure/>
                                    </div>
                                )
                            }
                            {
                                (closure === "booklet") && (
                                    <div>
                                        <CommercialBooklet/>
                                    </div>
                                )
                            }
                            {
                                (closure === "prescriptionPad") && (
                                    <div>
                                        <CommercialPrescriptionPad/>
                                    </div>
                                )
                            }
                            {
                                (closure === "chitPad") && (
                                    <div>
                                        <CommercialChitPad/>
                                    </div>
                                )
                            }
                            {
                                (closure === "notePad") && (
                                    <div>
                                        <CommercialNotePad/>
                                    </div>
                                )
                            }
                            <div className="column">
                                <div className="input-box">
                                    <DropDownFields
                                        label={"Transport"}
                                        value={transport}
                                        setValue={setTransport}
                                        options={transportOptions}
                                    />
                                </div>
                                <div className="input-box">
                                    <DropDownFields
                                        label={"Region"}
                                        value={region}
                                        setValue={setRegion}
                                        options={regionOptions}
                                    />
                                </div>
                            </div>
                            <button type="submit">Magic Button ✨</button>
                        </form>
                    </div>
                    {displayQuotation ? (
                        <QuotationPriceDisplayCommercial qtt1={qtt1} qtt2={qtt2} qtt3={qtt3} tableData={tableData}/>
                    ) : (
                        ""
                    )}
                    {/* {displayQuotation ? (
                        <OutputTables
                            tableData={tableData}
                            qtt1={qtt1}
                            qtt2={qtt2}
                            qtt3={qtt3}
                        />
                    ) : (
                        ""
                    )} */}
                </div>
                <div ref={quotationRef}></div>
            </div>
        </div>
    )
}

export default CommercialAlternateContainer;