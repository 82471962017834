import React from "react";
import InputTextField from "../reusable/InputTextField";
import InputTextAreaField from "../reusable/InputTextAreaField";

const OrderDetails = ({ order, setOrders, removeOrder }) => {
  return (
    <div>
      {/* <InputTextField
        value={order.orderDescription}
        setValue={(value) => setOrders({ ...order, orderDescription: value })}
        label={"Order Description"}
      /> */}
      <InputTextAreaField
        value={order.orderDescription}
        setValue={(value) => setOrders({ ...order, orderDescription: value })}
        label={"Order Description"}
      />

      <InputTextField
        value={order.hsn}
        setValue={(value) => setOrders({ ...order, hsn: value })}
        label={"HSN/SAC"}
      />
      <InputTextField
        value={order.quantity}
        setValue={(value) => setOrders({ ...order, quantity: value })}
        label={"Quantity"}
      />
      <InputTextField
        value={order.ratePerPiece}
        setValue={(value) => setOrders({ ...order, ratePerPiece: value })}
        label={"Rate per piece"}
      />
      <InputTextField
        value={order.finalAmount}
        setValue={(value) => setOrders({ ...order, finalAmount: value })}
        label={"Final Amount"}
      />
      <div className="addMoreContainer">
        <span onClick={removeOrder} className="addMoreOrders">
          -
        </span>
        &nbsp;Remove this order
      </div>
    </div>
  );
};

export default OrderDetails;
