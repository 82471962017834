import React, { createContext, useState } from "react";

export const UniqueIdContext = createContext();

const UniqueIdProvider = ({ children }) => {
  const [uniqueId, setUniqueId] = useState(null);
  const [name, setName] = useState(null);
  const [price1, setPrice1] = useState(null);
  const [price2, setPrice2] = useState(null);
  const [price3, setPrice3] = useState(null);

  return (
    <UniqueIdContext.Provider
      value={{
        uniqueId,
        setUniqueId,
        name,
        setName,
        price1,
        setPrice1,
        price2,
        setPrice2,
        price3,
        setPrice3,
      }}
    >
      {children}
    </UniqueIdContext.Provider>
  );
};

export default UniqueIdProvider;
