export const RigidBoxOptions = [
  { value: "", label: "None" },
  
  { value: "fbe486", label: "Apparel - Top Bottom with sleeve - FBE486" },
  { value: "rb020", label: "Apparel - Luxury Shoe Gift Box - RB020" },
  { value: "rb208", label: "Apparel - Custom Luxury Sock Box - RB208" },

  { value: "rb141", label: "Chocolates and Candies - Chocolate Overlapping Lid - RB141" },
  { value: "rb174", label: "Chocolates and Candies - Artisan Chocolate Box - RB174" },
  { value: "rb143", label: "Chocolates and Candies - Chocolate Telescopic Box - RB143" },
  { value: "rb076", label: "Chocolates and Candies - Chocolate Drawer Style Box - RB076" },
  { value: "rb026", label: "Chocolates and Candies - Chocolate Lid Off Box With Ribbon - RB026" },
  { value: "rb085", label: "Chocolates and Candies - Chocolate Box with Blister Tray - RB085" },
  
  { value: "rb127", label: "Cosmetics - Luxury Lotion Packaging - RB127" },
  { value: "rb179", label: "Cosmetics - Luxury Magnetic Cosmetic Packaging Box - RB179" },
  { value: "rb180", label: "Cosmetics - Match Box Skincare Packaging Box - RB180" },
  { value: "rb181", label: "Cosmetics - Cosmetic Luxury Box With Lid - RB181" },
  { value: "rb168", label: "Cosmetics - Rigid Loyalty Box - RB168" },
  { value: "rb177", label: "Cosmetics - Hair Straightener Box - RB177" },
  { value: "rb183", label: "Cosmetics - Creative Skincare Packaging Box - RB183" },
  { value: "rb185", label: "Cosmetics - Luxury Make Up Box With Sleeve - RB185" },
  { value: "rb169", label: "Cosmetics - Eyelash Extension Box - RB169" },

  { value: "rb010", label: "Retail - Perfume Drawer Box with Ribbon Handle - RB010" },

];