import React, { useEffect, useState } from "react";
import UserDropdown from "../reusable/UserDropdown";
import "./BriefSheetsInPGD.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  SubmitDesignsInPGD,
  UploadPDFInPGD,
  pendingDesignsInPGD,
} from "../apis/apiClients";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../constants/constants";
import SearchComponent from "../searchBar/SearchComponent";

const BriefSheetsInPGD = () => {
  const [file, setFile] = useState(null);
  const [orders, setOrders] = useState([]);
  const [uploadClicked, setUploadClicked] = useState(false);
  const history = useHistory();

  const handlePrev = () => {
    history.push("/PGDashboard");
  };

  const submitWithPDF = async (orderId) => {
    try {
      const res = await SubmitDesignsInPGD(orderId);
      if (res.status === 200) {
        console.log("Order Accepted Successfully!");
      }
      toast.success("Order Submitted Successfully!");
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (orderId) => {
    const formData = new FormData();
    formData.append("pdf", file);

    try {
      const res = await UploadPDFInPGD({ orderId, formData });
      if (res.status === 200) {
        console.log("PDF Uploaded Successfully!");
        setFile(null);
      }
      toast.success("PDF Uploaded Successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  const uniqueId = localStorage.getItem("uniqueId");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await pendingDesignsInPGD(uniqueId);
        console.log(response, "response");

        if (response) {
          const orders = response.orders;
          setOrders(orders);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrders();
  }, [uniqueId]);

  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">Brief Sheets In PGD</b>
            <UserDropdown />
          </div>
        </div>
      </div>
      <h2>Number of Orders : {orders.length}</h2>
      <SearchComponent orders={orders} setOrders={setOrders} />
      <div className="ordersContainer">
        {orders.map((order) => (
          <div className="ordersItem" key={order._id}>
            <div className="orderBoxId">
              <p
                style={{
                  color: "#800000",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
              >
                ORDER ID: {order.orderId}
              </p>
            </div>
            <p>
              <b>Client Name : </b> {order.clientName}
            </p>
            <p>
              <b>Client Email : </b> {order.clientEmail}
            </p>
            <p>
              <b>Client Address : </b> {order.clientAddress}
            </p>
            <p>
              <b>Client Contact : </b> {order.clientContact}
            </p>
            <p>
              <b>Client Pincode :</b> {order.clientPincode}
            </p>
            <p>
              <b>Client GST : </b> {order.clientGST}
            </p>
            <p>
              <b>Box Type : </b> {order.boxType}
            </p>
            <p>
              <b>Closure :</b> {order.closure}
            </p>
            <p>
              <b>Dimension: </b>
              {order.dim} {order.dimUnit}
            </p>

            <p>
              <b>Quantity 1 : </b>
              {order.qtt1}
            </p>
            <p>
              <b>Quantity 2 : </b>
              {order.qtt2}
            </p>
            <p>
              <b>Quantity 3 :</b> {order.qtt3}
            </p>
            <p>
              <b>Paper GSM : </b> {order.quality}
            </p>
            <p>
              <b>Region : </b> {order.region}
            </p>
            <p>
              <b>Price 1 : </b>
              {order.price1}
            </p>
            <p>
              <b>Price 2 : </b>
              {order.price2}
            </p>
            <p>
              <b>Price 3 : </b>
              {order.price3}
            </p>
            <p>
              <b>Transport : </b>
              {order.transport}
            </p>
            <p>
              <b>Sale Date : </b>
              {order.saleDate}
            </p>
            <p>
              <b>Remarks/Additional Info : </b>
              {order.remarks}
            </p>

            <div className="applicationsValueContainer">
              <p>
                Applications :
                <div className="applicationsValue">
                  {Object.keys(order.applicationsValue).map((app) => (
                    <div key={app}>
                      {order.applicationsValue[app] &&
                        ` ${app} : ${order.applicationsValue[app]}%`}
                    </div>
                  ))}
                </div>
              </p>
            </div>
            <p>
              <b>Images</b>
            </p>
            {order.imageUrls.length ? (
              <div>
                {order.imageUrls.map((imageUrl, index) => (
                  <a
                    href={`${BASE_URL}${imageUrl.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="viewPdfButton" key={index}>
                      <a
                        rel="noreferrer"
                        href={`${BASE_URL}${imageUrl}`}
                        target="_blank"
                        className="linkDecoration"
                      >
                        <div className="imageContainer" key={index}>
                          <img
                            key={imageUrl.index}
                            src={`${BASE_URL}${imageUrl}`}
                            alt="ImagesUpload"
                            className="hoverImage"
                            style={{
                              width: "120px",
                              height: "100px",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </a>
                    </button>
                  </a>
                ))}
              </div>
            ) : (
              <h4>No Images found</h4>
            )}
            <div className="buttonContainer">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(order.orderId);
                }}
              >
                <div>
                  <div className="uploadAndLabel">
                    <i class="fa-solid fa-upload" id="orderId"></i>
                    <label htmlFor="pdfFile">Upload PDF</label>
                  </div>

                  <input type="file" id="pdfFile" onChange={handleFileChange} />
                </div>
                {file ? (
                  <button onClick={() => setUploadClicked(true)} type="submit">
                    Upload
                  </button>
                ) : (
                  ""
                )}
              </form>

              {uploadClicked ? (
                <button
                  onClick={() => submitWithPDF(order.orderId)}
                  className="rejectButtonSMPending"
                >
                  Submit &nbsp;✅
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BriefSheetsInPGD;
