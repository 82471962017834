import React, { useEffect, useState, useCallback } from "react";
import {
  acceptedOrderInSM,
  pendingApprovalofSM,
  rejectedOrder,
} from "../apis/apiClients";
import { useHistory } from "react-router-dom";
import UserDropdown from "../reusable/UserDropdown";
import "./SMPendingApproval.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../constants/constants";
import SearchComponent from "../searchBar/SearchComponent";

const SMPendingApproval = () => {
  const [orders, setOrders] = useState([]);

  const history = useHistory();

  const uniqueId = localStorage.getItem("uniqueId");

  const handlePrev = () => {
    history.push("/salesDashboard");
  };

  const fetchOrders = useCallback(async () => {
    try {
      const response = await pendingApprovalofSM(uniqueId);
      if (response && Array.isArray(response.orders)) {
        setOrders(response.orders);
      } else {
        console.log("No valid data found in the response.");
      }
    } catch (error) {
      console.log(error);
    }
  }, [uniqueId]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleAccept = async (orderId) => {
    const confirmAccept = window.confirm(
      "Are you sure you want to accept this order?"
    );

    if (confirmAccept) {
      try {
        const res = await acceptedOrderInSM(orderId);
        if (res.status === 200) {
          console.log("Order Accepted successfully!");
        }
        console.log(res.data);
        toast.success("Order Accepted Successfully!");
      } catch (error) {
        console.log(error);
      }
    }
    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  const handleRejection = async (orderId) => {
    const confirmReject = window.confirm(
      "Are you sure you want to reject this order?"
    );

    if (confirmReject) {
      try {
        const res = await rejectedOrder(orderId);
        if (res.status === 200) {
          console.log("Order Rejected successfully!");
        }
        toast.success("Order Rejected Successfully!");
      } catch (error) {
        console.log(error);
      }
    }
    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">SM Pending Approval!</b>
            <UserDropdown />
          </div>
        </div>
      </div>
      <h2>Number of orders : {orders.length}</h2>

      <SearchComponent
        orders={orders}
        setOrders={setOrders}
        fetchOrders={fetchOrders}
      />

      <div className="ordersContainer">
        {orders.map((order) => (
          <div className="ordersItem" key={order._id}>
            <div className="orderBoxId">
              <p
                style={{
                  color: "#800000",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
              >
                ORDER ID: {order.orderId}
              </p>
            </div>

            <p>
              <b>Client Name :</b> {order.clientName}
            </p>
            <p>
              <b>Client Email : </b>
              {order.clientEmail}
            </p>
            <p>
              <b>Client Address : </b>
              {order.clientAddress}
            </p>
            <p>
              <b>Client Contact : </b> {order.clientContact}
            </p>
            <p>
              <b>Client Pincode :</b> {order.clientPincode}
            </p>
            <p>
              <b>Client GST :</b> {order.clientGST}
            </p>
            <p>
              <b>Box Type : </b> {order.boxType}
            </p>
            <p>
              <b>Closure :</b> {order.closure}
            </p>
            <p>
              <b>Dimension : </b> {order.dim} {order.dimUnit}
            </p>

            <p>
              <b>Quantity 1 : </b>
              {order.qtt1}
            </p>
            <p>
              <b>Quantity 2 :</b> {order.qtt2}
            </p>
            <p>
              <b>Quantity 3 :</b> {order.qtt3}
            </p>
            <p>
              <b>Paper GSM :</b> {order.quality}
            </p>
            <p>
              <b>Region : </b> {order.region}
            </p>
            <p>
              <b>Price 1: </b>
              {order.price1}
            </p>
            <p>
              <b>Price 2 : </b>
              {order.price2}
            </p>
            <p>
              <b>Price 3 : </b>
              {order.price3}
            </p>
            <p>
              <b>Transport : </b>
              {order.transport}
            </p>
            <p>
              <b>Sale Date : </b>
              {order.saleDate}
            </p>
            <p>
              <b>Remarks/Additional Info : </b>
              {order.remarks}
            </p>

            <div className="applicationsValueContainer">
              <p>
                <b> Applications : </b>
                <div className="applicationsValue">
                  {Object.keys(order.applicationsValue).map((app) => (
                    <div key={app}>
                      {order.applicationsValue[app] &&
                        ` ${app} : ${order.applicationsValue[app]}%`}
                    </div>
                  ))}
                </div>
              </p>
            </div>
            <p>
              <b>Images</b>
            </p>
            {order.imageUrls.length ? (
              <div>
                {order.imageUrls.map((imageUrl, index) => (
                  <a
                    href={`${BASE_URL}${imageUrl.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="viewPdfButton" key={index}>
                      <a
                        rel="noreferrer"
                        href={`${BASE_URL}${imageUrl}`}
                        target="_blank"
                        className="linkDecoration"
                      >
                        <div className="imageContainer" key={index}>
                          <img
                            key={imageUrl.index}
                            src={`${BASE_URL}${imageUrl}`}
                            alt="ImagesUpload"
                            className="hoverImage"
                            style={{
                              width: "120px",
                              height: "100px",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </a>
                    </button>
                  </a>
                ))}
              </div>
            ) : (
              <h4>No Images found</h4>
            )}
            <div className="buttonContainer">
              <button
                onClick={() => handleAccept(order.orderId)}
                className="acceptButtonSMPending"
              >
                Accept &nbsp; ✅
              </button>
              <button
                onClick={() => handleRejection(order.orderId)}
                className="rejectButtonSMPending"
              >
                Reject &nbsp;❌
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SMPendingApproval;
