import React, { useContext } from "react";
import { ProformaInvoiceContext } from "../context/ProformaInvoiceContext";
import "./ViewInvoice.css";
import UserDropdown from "../reusable/UserDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import numberToWords from "number-to-words";

const ViewInvoice = () => {
  const { clientGST, billingAddress, orders } = useContext(
    ProformaInvoiceContext
  );

  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const totalFinalAmount = orders.reduce(
    (total, order) => total + parseInt(order.finalAmount),
    0
  );
  const formattedTotalAmount = totalFinalAmount.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });

  const eighteenPercent = 0.18;
  const gstAmount = totalFinalAmount * eighteenPercent;
  const formattedGSTAmount = gstAmount.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });

  const numericFormattedTotalAmount = parseFloat(
    formattedTotalAmount.replace(/,/g, "")
  );
  const numericGstAmount = parseFloat(gstAmount.toFixed(2));

  const grandTotal = numericFormattedTotalAmount + numericGstAmount;

  const formattedGrandTotal = grandTotal.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });

  const amountInWords = numberToWords.toWords(grandTotal);

  const history = useHistory();

  const handlePrev = () => {
    history.push("/CSDashboard/CSInvoiceDetails");
  };
  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">Preview Performa Invoice</b>
            <UserDropdown />
          </div>
        </div>
      </div>
      <div className="PIBackground">
        <div className="performaInvoiceContainer">
          <body>
            <table
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              align="center"
              class="fullTable"
            >
              <tr>
                <td height="10"></td>
              </tr>
              <tr>
                <td>
                  <table
                    width="600"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    align="center"
                    className="fullTable"
                    bgcolor="#ffffff"
                    style={{
                      borderRadius: "10px 10px 0 0",
                    }}
                  >
                    <tr class="hiddenMobile">
                      <td height="30"></td>
                    </tr>
                    <tr class="visibleMobile">
                      <td height="20"></td>
                    </tr>

                    <tr>
                      <td>
                        <table
                          width="480"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                          class="fullPadding"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <table
                                  width="220"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  align="left"
                                  class="col"
                                >
                                  <tbody>
                                    <tr>
                                      <td align="left">
                                        <img
                                          src="https://i.ibb.co/MDxfhtB/baxsaalogo-1-1.png"
                                          width="102"
                                          height="102"
                                          alt="logo"
                                          border="0"
                                        />
                                      </td>
                                    </tr>
                                    <tr class="hiddenMobile">
                                      <td height="20"></td>
                                    </tr>
                                    <tr class="visibleMobile">
                                      <td height="20"></td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          color: "#5b5b5b",
                                          fontFamily: "'Open Sans', sans-serif",
                                          lineHeight: "15px",
                                          verticalAlign: "top",
                                          textAlign: "left",
                                        }}
                                      >
                                        <b>LILAC PRINT PVT LTD</b>
                                        <br />
                                        122,A/2, Shah & Nahar Industrial Estate
                                        <br />
                                        Lower Parel, Mumbai 400013
                                        <br />
                                        GSTIN/UIN: 27AAECL6312R1Z1
                                        <br />
                                        State Name: Maharashtra, Code: 27
                                        <br />
                                        Company's PAN : AAECL6312R <br />
                                        CIN: U22219MH2021PTC361317
                                        <br />
                                        E-Mail: accounts@lilacprint.in
                                      </td>
                                    </tr>
                                    <tr class="hiddenMobile">
                                      <td height="20"></td>
                                    </tr>
                                    <tr class="visibleMobile">
                                      <td height="20"></td>
                                    </tr>

                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          color: "#5b5b5b",
                                          fontFamily: "'Open Sans', sans-serif",
                                          lineHeight: "15px",
                                          verticalAlign: "top",
                                          textAlign: "left",
                                        }}
                                      >
                                        <b>BILLING ADDRESS</b>
                                        <br />
                                        {billingAddress} <br />
                                        <b>GSTIN/UIN : </b> {clientGST}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <table
                                  width="220"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  align="right"
                                  class="col"
                                >
                                  <tbody>
                                    <tr class="visibleMobile">
                                      <td height="20"></td>
                                    </tr>
                                    <tr>
                                      <td height="5"></td>
                                    </tr>

                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "20px",
                                          color: "#000",
                                          letterSpacing: "-1px",
                                          fontFamily: "'Open Sans', sans-serif",
                                          lineHeight: "1",
                                          verticalAlign: "top",
                                          textAlign: "right",
                                          padding: "38px 0px 0px 0px",
                                        }}
                                      >
                                        PRO-FORMA INVOICE
                                      </td>
                                    </tr>
                                    <tr>
                                      <td height="55"></td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          color: "#5b5b5b",
                                          fontFamily: "'Open Sans', sans-serif",
                                          lineHeight: "15px",
                                          verticalAlign: "top",
                                          textAlign: "right",
                                        }}
                                      >
                                        <b>
                                          INVOICE NO.
                                          {/* ${invoiceNumber} */}
                                        </b>
                                        <br />
                                        <br />
                                        <b>Issue Date : {currentDate}</b>
                                        <br />
                                        <br />
                                        <b>Reference No. & Date.</b>
                                        <br />
                                        <br />
                                        <b>Terms of Delivery</b>
                                        <br />
                                        <br />
                                        <b>Packaging Specialist</b>
                                        <br />
                                      </td>
                                    </tr>
                                    <tr>
                                      <tr class="hiddenMobile">
                                        <td height="20"></td>
                                      </tr>
                                      <tr class="visibleMobile">
                                        <td height="20"></td>
                                      </tr>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              align="center"
              class="fullTable"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      width="600"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      align="center"
                      class="fullTable"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr class="hiddenMobile">
                          <td height="50"></td>
                        </tr>
                        <tr class="visibleMobile">
                          <td height="30"></td>
                        </tr>
                        <tr>
                          <td>
                            <table
                              width="480"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              align="center"
                              class="fullPadding"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#000",
                                      fontWeight: "normal",
                                      lineHeight: "1",
                                      verticalAlign: "top",
                                      padding: "0 6px 7px 0",
                                    }}
                                    align="left"
                                  >
                                    <b>Sl.no</b>
                                  </th>
                                  <th
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#000",
                                      fontWeight: "normal",
                                      lineHeight: "1",
                                      verticalAlign: "top",
                                      padding: "0 6px 7px 0",
                                    }}
                                    width="40%"
                                    align="left"
                                  >
                                    <b>Description of Goods</b>
                                  </th>
                                  <th
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#000",
                                      fontWeight: "normal",
                                      lineHeight: "1",
                                      verticalAlign: "top",
                                      padding: "0 5px 7px",
                                    }}
                                    align="left"
                                  >
                                    <b>HSN/SAC</b>
                                  </th>
                                  <th
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#000",
                                      fontWeight: "normal",
                                      lineHeight: "1",
                                      verticalAlign: "top",
                                      padding: "0 5px 7px",
                                    }}
                                    align="center"
                                  >
                                    <b>Quantity</b>
                                  </th>
                                  <th
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#000",
                                      fontWeight: "normal",
                                      lineHeight: "1",
                                      verticalAlign: "top",
                                      padding: "0 5px 7px",
                                    }}
                                    align="center"
                                  >
                                    <b>rps</b>
                                  </th>
                                  <th
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#000",
                                      fontWeight: "normal",
                                      lineHeight: "1",
                                      verticalAlign: "top",
                                      padding: "0 0 7px",
                                    }}
                                    align="right"
                                  >
                                    <b> Amount</b>
                                  </th>
                                </tr>
                                <tr>
                                  <td
                                    height="1"
                                    style={{
                                      background: "#bebebe",
                                      colspan: "7",
                                    }}
                                  ></td>
                                </tr>
                                <tr>
                                  <td height="10" colspan="7"></td>
                                </tr>
                                {orders.map((order, index) => (
                                  <>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "'Open Sans', sans-serif",
                                          color: "#646a6e",
                                          lineHeight: "18px",
                                          verticalAlign: "top",
                                          padding: "10px 0",
                                        }}
                                      >
                                        <small>{index + 1}</small>
                                      </td>

                                      <td
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "'Open Sans', sans-serif",
                                          color: "#000",
                                          lineHeight: "18px",
                                          verticalAlign: "top",
                                          padding: "10px 0",
                                        }}
                                        className="article"
                                      >
                                        {order.orderDescription}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "'Open Sans', sans-serif",
                                          color: "#646a6e",
                                          lineHeight: "18px",
                                          verticalAlign: "top",
                                          padding: "10px 0",
                                        }}
                                      >
                                        <small>{order.hsn}</small>
                                      </td>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "'Open Sans', sans-serif",
                                          color: "#646a6e",
                                          lineHeight: "18px",
                                          verticalAlign: "top",
                                          padding: "10px 0",
                                        }}
                                        align="center"
                                      >
                                        {order.quantity}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "'Open Sans', sans-serif",
                                          color: "#646a6e",
                                          lineHeight: "18px",
                                          verticalAlign: "top",
                                          padding: "10px 0",
                                        }}
                                        align="center"
                                      >
                                        {order.ratePerPiece}
                                      </td>
                                      <td
                                        style={{
                                          fontSize: "12px",
                                          fontFamily: "'Open Sans', sans-serif",
                                          color: "#1e2b33",
                                          lineHeight: "18px",
                                          verticalAlign: "top",
                                          padding: "10px 0",
                                        }}
                                        align="right"
                                      >
                                        {order.finalAmount}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        height="1"
                                        colspan="7"
                                        style={{
                                          borderBottom: "1px solid #e4e4e4",
                                        }}
                                      ></td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td height="20"></td>
                        </tr>
                        <tr />
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              align="center"
              class="fullTable"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      width="600"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      align="center"
                      class="fullTable"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <table
                              width="480"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              align="center"
                              class="fullPadding"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#000",
                                      lineHeight: "22px",
                                      verticalAlign: "top",
                                      textAlign: "right",
                                    }}
                                  >
                                    <b>Subtotal</b>
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#646a6e",
                                      lineHeight: "22px",
                                      verticalAlign: "top",
                                      textAlign: "right",
                                      whiteSpace: "nowrap",
                                    }}
                                    width="80"
                                  >
                                    {totalFinalAmount}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#000",
                                      lineHeight: "22px",
                                      verticalAlign: "top",
                                      textAlign: "right",
                                    }}
                                  >
                                    <b>GST 18%</b>
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#646a6e",
                                      lineHeight: "22px",
                                      verticalAlign: "top",
                                      textAlign: "right",
                                    }}
                                  >
                                    {formattedGSTAmount}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#000",
                                      lineHeight: "22px",
                                      verticalAlign: "top",
                                      textAlign: "right",
                                    }}
                                  >
                                    <strong>Grand Total (Incl.Tax)</strong>
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "12px",
                                      fontFamily: "'Open Sans', sans-serif",
                                      color: "#000",
                                      lineHeight: "22px",
                                      verticalAlign: "top",
                                      textAlign: "right",
                                    }}
                                  >
                                    <strong>{formattedGrandTotal}</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              fontSize: "11px",
                              fontFamily: "'Open Sans', sans-serif",
                              color: "#5b5b5b",
                              lineHeight: "1",
                              verticalAlign: "top",
                            }}
                          ></td>
                        </tr>
                      </tbody>
                      <table
                        width="480"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        align="center"
                        class="fullPadding"
                      >
                        <tbody>
                          <tr>
                            <td style={{ border: "2px solid #fff" }}>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                align="left"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "12px",
                                        fontFamily: "'Open Sans', sans-serif",
                                        color: "#5b5b5b",
                                        lineHeight: "1",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      <strong>Total Amount (in words) :</strong>
                                      {amountInWords} only.
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              align="center"
              class="fullTable"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      width="600"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      align="center"
                      class="fullTable"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr class="hiddenMobile">
                          <td height="30"></td>
                        </tr>
                        <tr class="visibleMobile">
                          <td height="20"></td>
                        </tr>
                        <tr>
                          <td>
                            <table
                              width="480"
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              align="center"
                              class="fullPadding"
                            >
                              <tbody>
                                <tr>
                                  <td style={{ border: "2px solid #fff" }}>
                                    <table
                                      width="220"
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      align="left"
                                      class="col"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: "11px",
                                              fontFamily:
                                                "'Open Sans', sans-serif",
                                              color: "#5b5b5b",
                                              lineHeight: "1",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            <strong>
                                              COMPANY BANK DETAILS
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width="100%" height="5"></td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: "12px",
                                              fontFamily:
                                                "'Open Sans', sans-serif",
                                              color: "#5b5b5b",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            Bank Name : KOTAK MAHINDRA BANK A/c
                                            No. : 9745488852 <br />
                                            Branch : Lower Parel, Mumbai <br />
                                            IFS Code : KKBK0000638
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr></tr>
                        <tr class="hiddenMobile">
                          <td height="40"></td>
                        </tr>
                        <tr class="visibleMobile">
                          <td height="20"></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </body>
          <html>
            <table
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              align="center"
              class="fullTable"
            >
              <tr>
                <td height="10"></td>
              </tr>
              <tr>
                <td>
                  <table
                    width="600"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    align="center"
                    class="fullTable"
                    bgcolor="#ffffff"
                    style={{ borderRadius: "10px 10px 0 0" }}
                  >
                    <tr class="hiddenMobile">
                      <td height="60"></td>
                    </tr>
                    <tr class="visibleMobile">
                      <td height="50"></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              align="center"
              class="fullTable"
            >
              <tr>
                <td>
                  <table
                    width="600"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    align="center"
                    class="fullTable"
                    bgcolor="#ffffff"
                    style={{ borderRadius: "0 0 10px 10px" }}
                  >
                    <tr>
                      <td>
                        <table
                          width="480"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                          class="fullPadding"
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontSize: "12px",
                                  color: "#5b5b5b",
                                  fontFamily: "'Open Sans', sans-serif",
                                  lineHeight: "18px",
                                  verticalAlign: "top",
                                  textAlign: "left",
                                  fontStyle: "bold",
                                }}
                              >
                                <strong>Declaration</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          width="480"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                          class="fullPadding"
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontSize: "12px",
                                  color: "#5b5b5b",
                                  fontFamily: "'Open Sans', sans-serif",
                                  lineHeight: "18px",
                                  verticalAlign: "top",
                                  textAlign: "left",
                                  fontStyle: "bold",
                                }}
                              >
                                Declaration We declare that this Proforma
                                Invoice shows the approximate price of the goods
                                described and that all particulars are true and
                                correct. This is just a proforma invoice and
                                once the customer accepts this and makes the
                                payment, a Tax invoice will be generated
                                automatically. In case of specific need/details
                                to required, feel free to write us.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <td height="20"></td>

                        <table
                          width="480"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                          class="fullPadding"
                        >
                          <td height="20"></td>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontSize: "12px",
                                  color: "#5b5b5b",
                                  fontFamily: "'Open Sans', sans-serif",
                                  lineHeight: "18px",
                                  verticalAlign: "top",
                                  textAlign: "left",
                                  fontStyle: "bold",
                                }}
                              >
                                <strong>Terms and Conditions</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          width="480"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                          class="fullPadding"
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontSize: "12px",
                                  color: "#5b5b5b",
                                  fontFamily: "'Open Sans', sans-serif",
                                  lineHeight: "18px",
                                  verticalAlign: "top",
                                  textAlign: "left",
                                  fontStyle: "bold",
                                }}
                              >
                                1- Payment has to be made 100 % in Advance{" "}
                                <br />
                                2- Customer has to provide his own logo /
                                graphic design in CDR format or any appropriate
                                format with high Quality. For For fresh Graphic
                                Design 2000 INR or 50 USD will be charged ( For
                                Revision of design 500 INR / Revision will be
                                applicable). Charges for Logo design are extra (
                                1500 INR or 30 USD)
                                <br />
                                3- For all transactions GST and necessary taxes
                                are applicable. Customers need to share their
                                GST Number to avail of GST Credit else it will
                                be filed as B2C only. <br />
                                4- Delivery /Courier Charges are as per actual
                                and not included in the pricing. <br />
                                5- Delivery / Shipment / Order completion is
                                based on the number of quantities & delivery
                                location. It can be min.7 days (working) <br />
                                6- Minimal Packaging material charges are extra
                                ( as per actual) <br />
                                7- This document is valid for 5 working days and
                                prices can be changed based on market paper
                                /pouch price. <br />
                                8- We don't work on SundaysHolidays & Statutory
                                holidays declared by Govt of India.
                                <br />
                                9- One product printed will not be
                                returned/refunded.
                                <br />
                                10-While placing the order, you are agreeing to
                                the company T&C, Shipping Policy, Privacy
                                Policy, Cancellation & Refund Policy. Refer to
                                FAQ in case of clarification is required.
                                <br />
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <br />
                        <table
                          width="480"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                          class="fullPadding"
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  fontSize: "12px",
                                  color: "#5b5b5b",
                                  fontFamily: "'Open Sans', sans-serif",
                                  lineHeight: "18px",
                                  verticalAlign: "top",
                                  textAlign: "left",
                                  fontStyle: "bold",
                                }}
                              >
                                CIN 000000000000000, PAN: AAECL6312R, TAN:
                                BLRE09872C <br />
                                UAN: KR03E0042829 , GST : 29AAFCE3993E1ZD, MSME
                                No. KR03E0042829 <br />
                                Employer PF Number: PYBOM1673678000 <br />
                                Employee State Insurance Number:
                                50000497020001001 <br />
                                Shop & Establishment Certificate No:
                                18/192/CE/0192/2017 <br />
                                United Nation Global Market Partner Number:
                                648407 <br />
                                Startup registration number: DIPP12877 <br />
                                Niti Ayog / Women Entrepreneurship Platform
                                (WEP) Member - WEPENT0000011261 MSME Global Mart
                                Gold Membership Number: NSIC00220109
                                <br />
                                India SME Forum registered Member-
                                01200321ASSO1102 <br />
                                India Enterpenure Association Registration
                                Number: EAI2020KA0062 <br />
                                MyGov No. 17486914
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr class="spacer">
                      <td height="40"></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </html>
        </div>
      </div>
    </div>
  );
};

export default ViewInvoice;
