import React from "react";
import UserDropdown from "../reusable/UserDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const JRPTDashboard = () => {
  const history = useHistory();

  const redirectToJRPTPendingSamples = () => {
    history.push("/JRPTDashboard/PendingSamplesInJRPT");
  };

  const redirectToJRPTPendingFinalProd = () => {
    history.push("/JRPTDashboard/PendingFinalProduction");
  };

  return (
    <div>
      <div className="navbarLayer">
        <div className="logoContainer">
          <h2>JR.Pt Dashboard</h2>

          <UserDropdown />
        </div>
      </div>
      <div className="CSFields">
        <div className="buttonContainerCS">
          <button
            onClick={() => redirectToJRPTPendingSamples()}
            className="dashboardButtonCS"
          >
            <i class="fa-solid fa-vial" id="orderIcon"></i>
            Pending Samples
          </button>
        </div>
        <div className="buttonContainerCS">
          <button
            onClick={() => redirectToJRPTPendingFinalProd()}
            className="dashboardButtonCS"
          >
            <i class="fa-solid fa-terminal" id="orderIcon"></i>
            Pending Final Production
          </button>
        </div>
      </div>
    </div>
  );
};

export default JRPTDashboard;
