import React, { useState, useEffect, useCallback, useContext } from "react";
import UserDropdown from "../reusable/UserDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DemoOrders from "../reusable/ViewOrders";
import { viewPreviousQuotations } from "../apis/apiClients";
import "./Pagination.css";
import { SearchContext } from "../context/SearchContext";

const PreviousQuotations = () => {
  const [orders, setOrders] = useState([]);
  const [currPageCount, setCurrPageCount] = useState(1);
  const [totalOrders, setTotalOrders] = useState("");

  // try making same api call for search and pagination

  const { query, searchPost } = useContext(SearchContext);
  const history = useHistory();
  const handlePrev = () => {
    history.push("/dashboard");
  };

  const fetchOrders = useCallback(async () => {
    try {
      const uniqueId = localStorage.getItem("uniqueId");
      const response = await viewPreviousQuotations({
        uniqueId,
        currPageCount,
      });
      console.log(response, "response");

      if (response) {
        const totalQuotations = parseInt(response.len / 9);
        const orders = response.orders;
        setOrders(orders);
        setTotalOrders(totalQuotations);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currPageCount]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <div>
      <div className="navbarLayer">
        <div className="logoContainer">
          <i
            onClick={handlePrev}
            id="backwardArrow"
            className="fa-solid fa-circle-arrow-left"
          ></i>
          <b className="titleOrders">Previous Quotations</b>
          <UserDropdown />
        </div>
      </div>
      <form>
        <div className="searchAndFilter">
          <div>
            <label>Filter by</label>
            <select>
              <option value="">Select an option...</option>
              <option value="latest">Latest</option>
              <option value="fbb">Fbb BoxType</option>
              <option value="corrugated">Corrugated BoxType</option>
              <option value="rigid">Rigid BoxType</option>
              <option value="paperBag">paper BoxType</option>
            </select>
          </div>
          <div className="searchAndInput">
            <i class="fa-solid fa-magnifying-glass" id="searchIcon"></i>
            <div className="searchFromApi">
              <input
                type="text"
                placeholder="Type to Search..."
                value={query}
                onChange={(e) => searchPost(e.target.value)}
              />
            </div>
          </div>
        </div>
      </form>

      <DemoOrders orders={orders} />
      <div className="paginationBtnContainer">
        <button
          onClick={() => setCurrPageCount(currPageCount - 1)}
          className="pagination_btn"
          disabled={currPageCount === 1}
        >
          PREV
        </button>
        <div>
          {currPageCount} of {totalOrders}
        </div>
        <button
          onClick={() => setCurrPageCount(currPageCount + 1)}
          className="pagination_btn"
          disabled={currPageCount === totalOrders}
        >
          NEXT
        </button>
      </div>
    </div>
  );
};

export default PreviousQuotations;
