import React from "react";

const CorrugatedClosureImageDisplay = ({ selectedClosure}) => {
    return (
        <div style={{ margin: "20px"}}>

            {/*  */}
            {/*  */}
            {/* APPAREL SECTION */}
            {/*  */}
            {/*  */}

            {selectedClosure === "cbe189" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe189open.png"}
                        alt="Description of cbe189"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe189closed.png"}
                        alt="Description of cbe189"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe189mock.png"}
                        alt="Description of cbe189"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe189kld.png"}
                        alt="Description of cbe189"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe107" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe107open.png"}
                        alt="Description of cbe107"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe107closed.png"}
                        alt="Description of cbe107"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe107mock.png"}
                        alt="Description of cbe107"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe107kld.png"}
                        alt="Description of cbe107"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe117" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe117open.png"}
                        alt="Description of cbe117"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe117closed.png"}
                        alt="Description of cbe117"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe117mock.png"}
                        alt="Description of cbe117"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe117kld.png"}
                        alt="Description of cbe117"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe491" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe491open.png"}
                        alt="Description of cbe491"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe491closed.png"}
                        alt="Description of cbe491"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe491mock.png"}
                        alt="Description of cbe491"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/apparel/cbe491kld.png"}
                        alt="Description of cbe491"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* BAKERY AND CAKES SECTION */}
            {/* bakery_and_cakes */}
            {/*  */}

            {selectedClosure === "fbe420" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/bakery_and_cakes/fbe420open.png"}
                        alt="Description of fbe420"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/bakery_and_cakes/fbe420closed.png"}
                        alt="Description of fbe420"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/bakery_and_cakes/fbe420mock.png"}
                        alt="Description of fbe420"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/bakery_and_cakes/fbe420kld.png"}
                        alt="Description of fbe420"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* COSMETICS SECTION */}
            {/* cosmetics */}
            {/*  */}

            {selectedClosure === "cbe385" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/cosmetics/cbe385open.png"}
                        alt="Description of cbe385"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/cosmetics/cbe385closed.png"}
                        alt="Description of cbe385"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/cosmetics/cbe385mock.png"}
                        alt="Description of cbe385"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/cosmetics/cbe385kld.png"}
                        alt="Description of cbe385"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* ECOMMERCE SECTION */}
            {/* ecommerce */}
            {/*  */}

            {selectedClosure === "c002" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c002open.png"}
                        alt="Description of c002"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c002closed.png"}
                        alt="Description of c002"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c002mock.png"}
                        alt="Description of c002"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c002kld.png"}
                        alt="Description of c002"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c003" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c003open.png"}
                        alt="Description of c003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c003closed.png"}
                        alt="Description of c003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c003mock.png"}
                        alt="Description of c003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c003kld.png"}
                        alt="Description of c003"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c004" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c004open.png"}
                        alt="Description of c004"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c004closed.png"}
                        alt="Description of c004"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c004mock.png"}
                        alt="Description of c004"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c004kld.png"}
                        alt="Description of c004"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c005" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c005open.png"}
                        alt="Description of c005"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c005closed.png"}
                        alt="Description of c005"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c005mock.png"}
                        alt="Description of c005"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c005kld.png"}
                        alt="Description of c005"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c006" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c006open.png"}
                        alt="Description of c006"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c006closed.png"}
                        alt="Description of c006"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c006mock.png"}
                        alt="Description of c006"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c006kld.png"}
                        alt="Description of c006"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c007" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c007open.png"}
                        alt="Description of c007"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c007closed.png"}
                        alt="Description of c007"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c007mock.png"}
                        alt="Description of c007"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c007kld.png"}
                        alt="Description of c007"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c008" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c008open.png"}
                        alt="Description of c008"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c008closed.png"}
                        alt="Description of c008"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c008mock.png"}
                        alt="Description of c008"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c008kld.png"}
                        alt="Description of c008"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c009" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c009open.png"}
                        alt="Description of c009"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c009closed.png"}
                        alt="Description of c009"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c009mock.png"}
                        alt="Description of c009"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c009kld.png"}
                        alt="Description of c009"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c018" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c018open.png"}
                        alt="Description of c018"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c018closed.png"}
                        alt="Description of c018"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c018mock.png"}
                        alt="Description of c018"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c018kld.png"}
                        alt="Description of c018"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c019" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c019open.png"}
                        alt="Description of c019"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c019closed.png"}
                        alt="Description of c019"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c019mock.png"}
                        alt="Description of c019"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c019kld.png"}
                        alt="Description of c019"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c020" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c020open.png"}
                        alt="Description of c020"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c020closed.png"}
                        alt="Description of c020"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c020mock.png"}
                        alt="Description of c020"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c020kld.png"}
                        alt="Description of c020"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c021" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c021open.png"}
                        alt="Description of c021"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c021closed.png"}
                        alt="Description of c021"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c021mock.png"}
                        alt="Description of c021"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c021kld.png"}
                        alt="Description of c021"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c022" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c022open.png"}
                        alt="Description of c022"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c022closed.png"}
                        alt="Description of c022"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c022mock.png"}
                        alt="Description of c022"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c022kld.png"}
                        alt="Description of c022"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "c025" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c025open.png"}
                        alt="Description of c025"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c025closed.png"}
                        alt="Description of c025"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c025mock.png"}
                        alt="Description of c025"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/c025kld.png"}
                        alt="Description of c025"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe371" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe371open.png"}
                        alt="Description of cbe371"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe371closed.png"}
                        alt="Description of cbe371"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe371mock.png"}
                        alt="Description of cbe371"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe371kld.png"}
                        alt="Description of cbe371"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe376" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe376open.png"}
                        alt="Description of cbe376"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe376closed.png"}
                        alt="Description of cbe376"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe376mock.png"}
                        alt="Description of cbe376"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe376kld.png"}
                        alt="Description of cbe376"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe383" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe383open.png"}
                        alt="Description of cbe383"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe383closed.png"}
                        alt="Description of cbe383"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe383mock.png"}
                        alt="Description of cbe383"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe383kld.png"}
                        alt="Description of cbe383"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe384" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe384open.png"}
                        alt="Description of cbe384"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe384closed.png"}
                        alt="Description of cbe384"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe384mock.png"}
                        alt="Description of cbe384"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe384kld.png"}
                        alt="Description of cbe384"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe382" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe382open.png"}
                        alt="Description of cbe382"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe382closed.png"}
                        alt="Description of cbe382"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe382mock.png"}
                        alt="Description of cbe382"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe382kld.png"}
                        alt="Description of cbe382"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe381" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe381open.png"}
                        alt="Description of cbe381"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe381closed.png"}
                        alt="Description of cbe381"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe381mock.png"}
                        alt="Description of cbe381"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe381kld.png"}
                        alt="Description of cbe381"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe380" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe380open.png"}
                        alt="Description of cbe380"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe380closed.png"}
                        alt="Description of cbe380"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe380mock.png"}
                        alt="Description of cbe380"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe380kld.png"}
                        alt="Description of cbe380"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe379" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe379open.png"}
                        alt="Description of cbe379"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe379closed.png"}
                        alt="Description of cbe379"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe379mock.png"}
                        alt="Description of cbe379"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe379kld.png"}
                        alt="Description of cbe379"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe378" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe378open.png"}
                        alt="Description of cbe378"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe378closed.png"}
                        alt="Description of cbe378"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe378mock.png"}
                        alt="Description of cbe378"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe378kld.png"}
                        alt="Description of cbe378"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe375" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe375open.png"}
                        alt="Description of cbe375"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe375closed.png"}
                        alt="Description of cbe375"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe375mock.png"}
                        alt="Description of cbe375"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe375kld.png"}
                        alt="Description of cbe375"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe374" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe374open.png"}
                        alt="Description of cbe374"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe374closed.png"}
                        alt="Description of cbe374"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe374mock.png"}
                        alt="Description of cbe374"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe374kld.png"}
                        alt="Description of cbe374"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe373" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe373open.png"}
                        alt="Description of cbe373"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe373closed.png"}
                        alt="Description of cbe373"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe373mock.png"}
                        alt="Description of cbe373"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe373kld.png"}
                        alt="Description of cbe373"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe372" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe372open.png"}
                        alt="Description of cbe372"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe372closed.png"}
                        alt="Description of cbe372"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe372mock.png"}
                        alt="Description of cbe372"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/ecommerce/cbe372kld.png"}
                        alt="Description of cbe372"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* RETAIL SECTION */}
            {/* retail */}
            {/*  */}
            
            {selectedClosure === "cbe195" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe195open.png"}
                        alt="Description of cbe195"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe195closed.png"}
                        alt="Description of cbe195"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe195mock.png"}
                        alt="Description of cbe195"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe195kld.png"}
                        alt="Description of cbe195"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe194" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe194open.png"}
                        alt="Description of cbe194"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe194closed.png"}
                        alt="Description of cbe194"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe194mock.png"}
                        alt="Description of cbe194"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe194kld.png"}
                        alt="Description of cbe194"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "cbe004" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe004open.png"}
                        alt="Description of cbe004"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe004closed.png"}
                        alt="Description of cbe004"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe004mock.png"}
                        alt="Description of cbe004"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/cbe004kld.png"}
                        alt="Description of cbe004"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "fbe010" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/fbe010open.png"}
                        alt="Description of fbe010"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/fbe010closed.png"}
                        alt="Description of fbe010"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/fbe010mock.png"}
                        alt="Description of fbe010"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/CorrugatedBoxes/retail/fbe010kld.png"}
                        alt="Description of fbe010"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
        </div>
    );
};

export default CorrugatedClosureImageDisplay;