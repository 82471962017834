import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const StyleText = () => {
  const [text, setText] = useState("");
  const [fetchedData, setFetchedData] = useState(null);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const saveContentToBackend = async () => {
    const apiUrl = "http://localhost:8080/api/saveContent";
    const content = text;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content }),
      });

      const data = await response.json();

      console.log("Content saved successfully", data);
    } catch (error) {
      console.error("Error saving content", error);
    }
  };

  const showStyledData = async () => {
    const apiUrl = "http://localhost:8080/api/displayContent";
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      setFetchedData(data);

      console.log("Content fetched successfully", data);
    } catch (error) {
      console.error("Error fetching content", error);
    }
  };

  const formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "color",
    "background",
    "link",
  ];

  const buttonStyle = {
    backgroundColor: "#4CAF50",
    border: "none",
    color: "white",
    padding: "10px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    borderRadius: "4px",
    cursor: "pointer",
  };

  const handleTextChange = (newText) => {
    setText(newText);
  };
  return (
    <div>
      <label>Styled text</label>
      <ReactQuill
        value={text}
        onChange={handleTextChange}
        modules={modules}
        formats={formats}
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      />
      <br />
      <button onClick={saveContentToBackend} style={buttonStyle}>
        Input
      </button>
      <br />
      <br />
      <button onClick={showStyledData} style={buttonStyle}>
        Output
      </button>
      <div>
        {fetchedData &&
          fetchedData.content &&
          fetchedData.content.map((item, index) => (
            <div
              key={index}
              style={{
                border: "1px solid black",
                padding: "10px",
                marginBottom: "10px",
              }}
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          ))}
      </div>
    </div>
  );
};

export default StyleText;
