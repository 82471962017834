import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserDropdown from "../reusable/UserDropdown";
import {
  FinalDispatchSubmit,
  JRPTFinalDispatchProduction,
  UploadImagesByJRPTInFinalProduction,
} from "../apis/apiClients";
import InputTextField from "../reusable/InputTextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchComponent from "../searchBar/SearchComponent";

const PendingFinalProduction = () => {
  const [files, setFiles] = useState([]);
  const [orders, setOrders] = useState([]);
  const [notes, setNotes] = useState("");
  const history = useHistory();
  const [uploadClicked, setUploadClicked] = useState(false);

  const handlePrev = () => {
    history.push("/JRPTDashboard");
  };

  const handleMultipleChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const uniqueId = localStorage.getItem("uniqueId");

  const UploadImagesInFinalProduction = async (orderId, event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("orderId", orderId);
      formData.append("notes", notes);
      files.forEach((file) => formData.append("images", file));
      const response = await UploadImagesByJRPTInFinalProduction(formData);
      if (response.status === 200) {
        toast.success("Images Uploaded Successfully!");
        setFiles([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await JRPTFinalDispatchProduction(uniqueId);
        if (response.status === 200) {
          toast.success("Images Uploaded Successfully!");
          setFiles([]);
        }
        console.log(response, "response");

        if (response) {
          const orders = response.orders;
          setOrders(orders);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrders();
  }, [uniqueId]);

  const DispatchFinalProduction = async (orderId, event) => {
    event.preventDefault();
    try {
      const res = await FinalDispatchSubmit(orderId);
      if (res.status === 200) {
        console.log("Order Accepted Successfully!");
        toast.success("Order Submitted Successfully!");
      }
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      setOrders(orders.filter((order) => order.orderId !== orderId));
    }, 2000);
  };

  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">Final Pending Production</b>
            <UserDropdown />
          </div>
        </div>
      </div>
      <h2>Number of Orders : {orders.length}</h2>
      <SearchComponent orders={orders} setOrders={setOrders} />
      <div className="ordersContainer">
        {orders.length === 0 ? (
          <h3>No orders found.</h3>
        ) : (
          orders.map((order) => (
            <div className="ordersItem" key={order._id}>
              <div className="orderBoxId">
                <p
                  style={{
                    color: "#800000",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  ORDER ID: {order.orderId}
                </p>
              </div>
              <p>Client Name: {order.clientName}</p>
              <p>Client Email: {order.clientEmail}</p>
              <p>Client Contact: {order.clientContact}</p>
              <p>Client Pincode: {order.clientPincode}</p>
              <p>Client GST: {order.clientGST}</p>
              <p>box Type: {order.boxType}</p>
              <p>Closure: {order.closure}</p>
              <p>Dimension: {order.dim}</p>
              <p>Dimension Unit: {order.dimUnit}</p>
              <p>Paper Price: {order.paperPrice}</p>
              <p>Quantity 1: {order.qtt1}</p>
              <p>Quantity 2: {order.qtt2}</p>
              <p>Quantity 3: {order.qtt3}</p>
              <p>Quality: {order.quality}</p>
              <p>Region: {order.region}</p>
              <p>Transport: {order.transport}</p>

              <div className="applicationsValueContainer">
                <p>
                  Applications Value:
                  <div className="applicationsValue">
                    {Object.keys(order.applicationsValue).map((app) => (
                      <div key={app}>
                        {order.applicationsValue[app] &&
                          ` ${app} : ${order.applicationsValue[app]}%`}
                      </div>
                    ))}
                  </div>
                </p>
              </div>
              <div className="buttonContainer">
                <form
                  id="uploadForm"
                  onSubmit={(event) =>
                    UploadImagesInFinalProduction(order.orderId, event)
                  }
                >
                  <div class="form-group">
                    <label for="imageFiles">Select multiple image files:</label>
                    <input
                      type="file"
                      id="imageFiles"
                      name="images"
                      multiple
                      required
                      onChange={handleMultipleChange}
                    />
                  </div>

                  {files.length > 0 && (
                    <InputTextField
                      type="textarea"
                      value={notes}
                      label={"Notes"}
                      setValue={setNotes}
                    />
                  )}

                  {files.length > 0 && (
                    <button
                      onClick={(event) => {
                        setUploadClicked(true);
                        // handleMultileImages(order.orderId, event);
                      }}
                      type="submit"
                      className="btn"
                    >
                      Upload
                    </button>
                  )}
                </form>

                {uploadClicked ? (
                  <button
                    onClick={(event) =>
                      DispatchFinalProduction(order.orderId, event)
                    }
                    className="rejectButtonSMPending"
                  >
                    Submit &nbsp;✅
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PendingFinalProduction;
