import React, {createContext, useState} from "react";

import { PrintingColoursOptions } from "../DropDownOptions/PrintingColoursOptions";
import { PrintingColoursOptionsInsidePaper } from "../DropDownOptions/PrintingColoursOptionsInsidePaper";
import { LaminationCommercialOptions } from "../DropDownOptions/LaminationCommercialOptions";
import { PosterTypeOptions } from "../DropDownOptions/PosterTypesOptions";
import { PosterGummingTypes} from "../DropDownOptions/PosterGummingTypes";
import { CommercialStandardPaperSizes } from "../DropDownOptions/CommercialStandardPaperSizes";
import { CommercialFolderTypeOptions} from "../DropDownOptions/CommercialFolderTypeOptions"
import { CommercialFolderPouchSizeOptions } from "../DropDownOptions/CommercialFolderPouchSizeOptions";
import { CommercialLeavesPrintingOptions } from "../DropDownOptions/CommercialLevesPrintingOptions";

export const CommercialQISContext = createContext();

export const CommercialQISContextProvider = ({ children }) => {
    const [error, setError] = useState("");
    const [closure, setClosure] = useState("");
    const [qtt1, setQtt1] = useState("");
    const [qtt2, setQtt2] = useState("");
    const [qtt3, setQtt3] = useState("");
    const [length, setLength] = useState("");
    const [breadth, setBreadth] = useState("");
    const [height, setHeight] = useState("");
    const [dimUnit, setDimUnit] = useState("");
    const [quality, setQuality] = useState("");
    const [transport, setTransport] = useState("");
    const [region, setRegion] = useState("");
    const [printingColours, setPrintingColours] = useState(PrintingColoursOptions[0].value);
    const [printingColoursInsidePaper, setPrintingColoursInsidePaper] = useState(PrintingColoursOptionsInsidePaper[0].value);
    const [lamination, setLamination] = useState(LaminationCommercialOptions[0].value);
    const [doubleSide, setDoubleSide] = useState("");
    const [diaCut, setDiaCut] = useState("");
    const [applications, setApplications] = useState({
        // Gold: false,
        // Thermal: false,
        // Pearl: false,
        // Gumming: false,
        LogoSpotUV: false,
        SpotUVOnOtherElements: false,
        LogoEmbossing: false,
        EmbossingOnOtherElements: false,
        LogoFoiling: false,
        FoilingOnOtherElements: false,
        DripOffUV: false,
        Varnish: false,
    }); 
    const [applicationsValue, setApplicationsValue] = useState({
        DripOffUV: null,
        Gumming: null,
        Varnish: null,
    });
    const [posterLayout, setPosterLayout] = useState(PosterTypeOptions[0].value);
    const [posterGummingType, setPosterGummingType] = useState(PosterGummingTypes[0].value);
    const [dimensionType, setDimensionType] = useState("standard");
    const [standardDimensions, setStandardDimensions] = useState(CommercialStandardPaperSizes[0].value);
    const [folderType, setFolderType] = useState(CommercialFolderTypeOptions[0].value);
    const [pouchSize, setPouchSize] = useState(CommercialFolderPouchSizeOptions[0].value);
    const [customPouchSize, setCustomPouchSize] = useState("");
    const [brochureType, setBrochureType] = useState("");
    const [bookletPages, setbookletPages] = useState("");
    const [bookletBindingType, setBookletBindingType] = useState("");
    
    const [bookletCoverPaperType, setBookletCoverPaperType] = useState("");
    const [bookletCoverPaperGSM, setBookletCoverPaperGSM] = useState("");
    const [bookletCoverOutsideColours, setBookletCoverOutsideColours] = useState(PrintingColoursOptions[0].value);
    const [bookletCoverLamination, setBookletCoverLamination] = useState("");
    const [bookletCoverDoubleSide, setBookletCoverDoubleSide] = useState("");
    const [bookletCoverInsideColours, setBookletCoverInsideColours] = useState(PrintingColoursOptions[0].value);
    const [bookletInnerPaperType, setBookletInnerPaperType] = useState("");
    const [bookletInnerPaperGSM, setBookletInnerPaperGSM] = useState("");
    const [bookletInnerOutsideColours, setBookletInnerOutsideColours] = useState(PrintingColoursOptionsInsidePaper[0].value);
    const [bookletInnerLamination, setBookletInnerLamination] = useState("");
    const [bookletInnerDoubleSide, setBookletInnerDoubleSide] = useState("");
    const [bookletInnerInsideColours, setBookletInnerInsideColours] = useState(PrintingColoursOptionsInsidePaper[0].value);
    const [prescriptionPadType, setPrescriptionPadType] = useState("");

    const [chitPadGalleyType, setChitPadGalleyType] = useState("");
    const [padThickness, setPadThickness] = useState("");
    const [padInnerPaperGSM, setPadInnerPaperGSM] = useState("");
    const [padOuterPaperGSM, setPadOuterPaperGSM] = useState("");
    const [padInnerPrinting, setPadInnerPrinting] = useState("");
    const [padInnerPrintingColours, setPadInnerPrintingColours] = useState("")
    const [padGalleyApplications, setPadGalleyApplications] = useState("");

    const [chitNoOfChits, setChitNoOfChits] = useState("");
    const [chitLength, setChitLength] = useState("");
    const [chitBreadth, setChitBreadth] = useState("");
    const [chitGSM, setChitGSM] = useState("");
    const [chitPrintingColours, setChitPrintingColours] = useState("");
    const [chitBackSidePrinting, setChitBackSidePrinting] = useState("");
    const [chitBackSideColours, setChitBackSideColours] = useState("");

    const [prescriptionPadClosure, setPrescriptionPadClosure] = useState("");
    const [prescriptionPadGalleyType, setPrescriptionPadGalleyType] = useState("");
    const [prescriptionPadGalleyLength, setPrescriptionPadGalleyLength] = useState("");
    const [prescriptionPadGalleyBreadth, setPrescriptionPadGalleyBreadth] = useState("");
    const [prescriptionPadGalleyBoardThickness, setPrescriptionPadGalleyBoardThickness] = useState("");
    const [prescriptionPadGalleyInnerPaperGSM, setPrescriptionPadGalleyInnerPaperGSM] = useState("");
    const [prescriptionPadGalleyOuterPaperGSM, setPrescriptionPadGalleyOuterPaperGSM] = useState("");
    const [prescriptionPadGalleyLamination, setPrescriptionPadGalleyLamination] = useState("");
    const [prescriptionPadPerforation, setPrescriptionPadPerforation] = useState("");

    const [notePadCoverPaperType, setNotePadCoverPaperType] = useState("");
    const [notePadCoverPaperGSM, setNotePadCoverPaperGSM] = useState("");
    const [notePadCoverOutsideColours, setNotePadCoverOutsideColours] = useState(PrintingColoursOptions[0].value);
    const [notePadCoverLamination, setNotePadCoverLamination] = useState("");
    const [notePadCoverDoubleSide, setNotePadCoverDoubleSide] = useState("");
    const [notePadCoverInsideColours, setNotePadCoverInsideColours] = useState("");

    const [leavesPrinting, setLeavesPrinting] = useState(CommercialLeavesPrintingOptions[0].value);

    
    const [bookletCoverApplications, setBookletCoverApplications] = useState({
        LogoSpotUV: false,
        LogoEmbossing: false,
        LogoFoiling: false,
        DripOffUV: false,
        Varnish: false,
    });
    const [bookletInnerApplications, setBookletInnerApplications] = useState({
        LogoSpotUV: false,
        LogoEmbossing: false,
        LogoFoiling: false,
        DripOffUV: false,
        Varnish: false,
    })

    return (
        <CommercialQISContext.Provider
            value={{
                error, setError,
                closure, setClosure,
                qtt1, setQtt1,
                qtt2, setQtt2,
                qtt3, setQtt3,
                length, setLength,
                breadth, setBreadth,
                height, setHeight,
                dimUnit, setDimUnit,
                quality, setQuality,
                transport, setTransport,
                region, setRegion,
                printingColours, setPrintingColours,
                printingColoursInsidePaper, setPrintingColoursInsidePaper,
                lamination, setLamination,
                doubleSide, setDoubleSide,
                diaCut, setDiaCut,
                applications, setApplications,
                applicationsValue, setApplicationsValue,
                posterLayout, setPosterLayout,
                posterGummingType, setPosterGummingType,
                dimensionType, setDimensionType,
                standardDimensions, setStandardDimensions,
                folderType, setFolderType,
                pouchSize, setPouchSize,
                customPouchSize, setCustomPouchSize,
                brochureType, setBrochureType,
                bookletPages, setbookletPages,
                bookletBindingType, setBookletBindingType,
                bookletCoverApplications, setBookletCoverApplications,
                bookletInnerApplications, setBookletInnerApplications,
                bookletCoverPaperType, setBookletCoverPaperType,
                bookletCoverPaperGSM, setBookletCoverPaperGSM,
                bookletCoverOutsideColours, setBookletCoverOutsideColours,
                bookletCoverLamination, setBookletCoverLamination,
                bookletCoverDoubleSide, setBookletCoverDoubleSide,
                bookletCoverInsideColours, setBookletCoverInsideColours,
                bookletInnerPaperType, setBookletInnerPaperType,
                bookletInnerPaperGSM, setBookletInnerPaperGSM,
                bookletInnerOutsideColours, setBookletInnerOutsideColours,
                bookletInnerLamination, setBookletInnerLamination,
                bookletInnerDoubleSide, setBookletInnerDoubleSide,
                bookletInnerInsideColours, setBookletInnerInsideColours,
                prescriptionPadType, setPrescriptionPadType,

                chitPadGalleyType, setChitPadGalleyType,
                padThickness, setPadThickness,
                padInnerPaperGSM, setPadInnerPaperGSM,
                padOuterPaperGSM, setPadOuterPaperGSM,
                padInnerPrinting, setPadInnerPrinting,
                padInnerPrintingColours, setPadInnerPrintingColours,
                padGalleyApplications, setPadGalleyApplications,

                chitNoOfChits, setChitNoOfChits,
                chitLength, setChitLength,
                chitBreadth, setChitBreadth,
                chitGSM, setChitGSM,
                chitPrintingColours, setChitPrintingColours,
                chitBackSidePrinting, setChitBackSidePrinting,
                chitBackSideColours, setChitBackSideColours,

                prescriptionPadClosure, setPrescriptionPadClosure,
                prescriptionPadGalleyType, setPrescriptionPadGalleyType,
                prescriptionPadGalleyLength, setPrescriptionPadGalleyLength,
                prescriptionPadGalleyBreadth, setPrescriptionPadGalleyBreadth,
                prescriptionPadGalleyBoardThickness, setPrescriptionPadGalleyBoardThickness,
                prescriptionPadGalleyInnerPaperGSM, setPrescriptionPadGalleyInnerPaperGSM,
                prescriptionPadGalleyOuterPaperGSM, setPrescriptionPadGalleyOuterPaperGSM,
                prescriptionPadGalleyLamination, setPrescriptionPadGalleyLamination,
                prescriptionPadPerforation, setPrescriptionPadPerforation,

                notePadCoverPaperType, setNotePadCoverPaperType,
                notePadCoverPaperGSM, setNotePadCoverPaperGSM,
                notePadCoverOutsideColours, setNotePadCoverOutsideColours,
                notePadCoverLamination, setNotePadCoverLamination,
                notePadCoverDoubleSide, setNotePadCoverDoubleSide,
                notePadCoverInsideColours, setNotePadCoverInsideColours,

                leavesPrinting, setLeavesPrinting
            }}
        >
            {children}
        </CommercialQISContext.Provider>
    );
};

export default CommercialQISContextProvider;