import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserDropdown from "../reusable/UserDropdown";
import {
  fetchOrdersWithImages,
  sendThisOrderToJRPTFromCSSampleDispatch,
  sendThisOrderToPTCSFromCSSampleDispatch,
  AcceptedOrderByCSInSampleDispatch,
} from "../apis/apiClients";
import { BASE_URL } from "../constants/constants";
import "./SampleDispatch.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchComponent from "../searchBar/SearchComponent";

const SampleDispatch = () => {
  const [orders, setOrders] = useState([]);
  const history = useHistory();

  const handlePrev = () => {
    history.push("/CSDashboard");
  };

  const handleAcceptInCSSampleAccept = async (orderId) => {
    const confirmSales = window.alert(
      "Are you sure you want to accept this order?"
    );

    if (!confirmSales) {
      try {
        const res = await AcceptedOrderByCSInSampleDispatch(orderId);
        if (res.status === 200) {
          toast.success("Order Sent Back To JRPT Final!");
        }
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    }
  };

  const uniqueId = localStorage.getItem("uniqueId");

  const sendBackToPTCSDashboard = async (orderId) => {
    const confirmSales = window.alert(
      "Are you sure you want to send back this order to PTCS?"
    );

    if (!confirmSales) {
      try {
        const res = await sendThisOrderToPTCSFromCSSampleDispatch(orderId);
        if (res.status === 200) {
          toast.success("Order Sent Back To Sales Manager!");
        }
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
      toast.success("Order Sent Back To PTCS!");
    }
  };

  const sendBackToJRPTDashboard = async (orderId) => {
    const confirmSales = window.alert(
      "Are you sure you want to send back this order to JRPT?"
    );

    if (!confirmSales) {
      try {
        const res = await sendThisOrderToJRPTFromCSSampleDispatch(orderId);
        if (res.status === 200) {
          toast.success("Order Sent Back To JRPT!");
        }
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
      toast.success("Order Sent Back To JRPT!");
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetchOrdersWithImages(uniqueId);
        console.log(response, "response");

        if (response) {
          const orders = response.orders;
          setOrders(orders);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrders();
  }, [uniqueId]);

  return (
    <div>
      <div className="navbarLayer">
        <div className="logoContainer">
          <i
            onClick={handlePrev}
            id="backwardArrow"
            className="fa-solid fa-circle-arrow-left"
          ></i>
          <b className="titleOrders">Sample Dispatch In CS</b>
          <UserDropdown />
        </div>
      </div>
      <h2>Number of Orders : {orders.length}</h2>
      <SearchComponent orders={orders} setOrders={setOrders} />
      <div className="ordersContainer">
        {orders.length === 0 ? (
          <h3>No orders found.</h3>
        ) : (
          orders.map((order) => (
            <div className="ordersItem" key={order._id}>
              <div className="orderBoxId">
                <p
                  style={{
                    color: "#800000",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  ORDER ID: {order.orderId}
                </p>
              </div>
              <p>
                <b>Client Name : </b> {order.clientName}
              </p>
              <p>
                <b>Client Email : </b> {order.clientEmail}
              </p>
              <p>
                <b>Client Address : </b> {order.clientAddress}
              </p>
              <p>
                <b>Client Contact : </b> {order.clientContact}
              </p>
              <p>
                <b>Client Pincode :</b> {order.clientPincode}
              </p>
              <p>
                <b>Client GST : </b> {order.clientGST}
              </p>
              <p>
                <b>Box Type : </b> {order.boxType}
              </p>
              <p>
                <b>Closure :</b> {order.closure}
              </p>
              <p>
                <b>Dimension: </b> {order.dim} {order.dimUnit}
              </p>

              <p>
                <b>Quantity 1 : </b>
                {order.qtt1}
              </p>
              <p>
                <b>Quantity 2 : </b>
                {order.qtt2}
              </p>
              <p>
                <b>Quantity 3 :</b> {order.qtt3}
              </p>
              <p>
                <b>Paper GSM : </b> {order.quality}
              </p>
              <p>
                <b>Region : </b> {order.region}
              </p>
              <p>
                <b>Transport :</b> {order.transport}
              </p>

              <p>
                <b>Applications :</b>
                {Object.keys(order.applicationsValue).map((app) => (
                  <span key={app}>
                    {order.applicationsValue[app] && ` ${app},`}
                  </span>
                ))}
              </p>
              <p>
                <b>Images</b>
              </p>
              <p>
                {order.imageUrls.map((imageUrl, index) => (
                  <a
                    href={`${BASE_URL}${imageUrl.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="viewPdfButton" key={index}>
                      <a
                        rel="noreferrer"
                        href={`${BASE_URL}${imageUrl}`}
                        target="_blank"
                        className="linkDecoration"
                      >
                        <div className="imageContainer" key={index}>
                          <img
                            key={imageUrl.index}
                            src={`${BASE_URL}${imageUrl}`}
                            alt="ImagesUpload"
                            className="hoverImage"
                            style={{
                              width: "120px",
                              height: "100px",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </a>
                    </button>
                  </a>
                ))}
                <div className="buttonContainer">
                  <button
                    onClick={() => handleAcceptInCSSampleAccept(order.orderId)}
                    className="acceptButtonSMPending"
                  >
                    Accept &nbsp; ✅
                  </button>
                  <button
                    onClick={() => sendBackToPTCSDashboard(order.orderId)}
                    className="rejectButtonSMPending"
                  >
                    Go Back to PTCS &nbsp;❌
                  </button>
                  <button
                    onClick={() => sendBackToJRPTDashboard(order.orderId)}
                    className="rejectButtonSMPending"
                  >
                    Go back to JRPT &nbsp;❌
                  </button>
                </div>
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SampleDispatch;
