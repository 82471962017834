import React from "react";
import UserDropdown from "../reusable/UserDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import StyleText from "../styleText/StyleText";

const StatusInCS = () => {
  const history = useHistory();

  const handlePrev = () => {
    history.push("/CSDashboard");
  };

  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">Status In CS</b>
            <UserDropdown />
          </div>
        </div>
      </div>
      <StyleText />
    </div>
  );
};

export default StatusInCS;
