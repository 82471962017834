import React, { useContext } from "react";
import "./dashboard.css";
import { useHistory } from "react-router-dom";
import UserDropdown from "../reusable/UserDropdown";
import { SearchContext } from "../context/SearchContext";

const BDADashboard = () => {
  const history = useHistory();
  const uniqueId = localStorage.getItem("uniqueId");

  const { query } = useContext(SearchContext);

  const redirectToPreviousQuotations = () => {
    history.push(
      `/dashboard/prevQuotations?uniqueId=${uniqueId}&search=${query}`
    );
  };

  const redirectToBDAEscalation = () => {
    history.push(
      `/dashboard/BDAEscalation?uniqueId=${uniqueId}&escalationStatus=type1`
    );
  };

  const redirectToPunchCard = () => {
    history.push("/dashboard/InputField");
  };

  const redirectToOrders = () => {
    history.push(`/dashboard/Orders?uniqueId=${uniqueId}`);
  };

  const redirectToQIS = () => {
    history.push("/dashboard/Qis");
  };

  return (
    <div className="mainDiv">
      <div className="navbarLayer">
        <div className="logoContainer">
          <b className="titleOrders">BDA Dashboard</b>
          <UserDropdown />
        </div>
      </div>
      <div className="CSFields">
        <div className="buttonContainerCS">
          <button
            className="dashboardButtonCS"
            onClick={() => redirectToPunchCard()}
          >
            <i class="fa-sharp fa-solid fa-list" id="punchIcon"></i>
            Punch Card
          </button>
        </div>
        <div className="buttonContainerCS">
          <button className="dashboardButtonCS" onClick={() => redirectToQIS()}>
            <i class="fa-sharp fa-solid fa-quote-right" id="QISIcon"></i>
            QIS
          </button>
        </div>
        <div className="buttonContainerCS">
          <button
            className="dashboardButtonCS"
            onClick={() => redirectToOrders()}
          >
            <i class="fa-solid fa-check" id="orderIcon"></i>
            View Orders
          </button>
        </div>
        <div className="buttonContainerCS">
          <button
            className="dashboardButtonCS"
            onClick={() => redirectToBDAEscalation()}
          >
            <i class="fa-solid fa-bolt" id="escalationIcon"></i>
            Escalation
          </button>
        </div>
        <div className="buttonContainerCS">
          <button
            className="dashboardButtonCS"
            onClick={() => redirectToPreviousQuotations()}
          >
            <i class="fa-sharp fa-solid fa-backward" id="QISIcon"></i>
            Previous Quotations
          </button>
        </div>
      </div>
    </div>
  );
};

export default BDADashboard;
