import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserDropdown from "../reusable/UserDropdown";
import { JRPTPendingDesigns, UploadMultipleImages } from "../apis/apiClients";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SubmitWithImages } from "../apis/apiClients";
import InputTextField from "../reusable/InputTextField";
import SearchComponent from "../searchBar/SearchComponent";

const PendingSamplesInJRPT = () => {
  const [files, setFiles] = useState([]);
  const [uploadClicked, setUploadClicked] = useState(false);
  const [orders, setOrders] = useState([]);
  const history = useHistory();
  const [notes, setNotes] = useState("");

  const handlePrev = () => {
    history.push("/JRPTDashboard");
  };

  const handleMultipleChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleSubmitWithImages = async (orderId, event) => {
    event.preventDefault();
    try {
      const res = await SubmitWithImages(orderId);
      if (res.status === 200) {
        console.log("Order Accepted Successfully!");
      }
      toast.success("Image Uploaded Successfully!");
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      setOrders(orders.filter((order) => order.orderId !== orderId));
    }, 2000);
  };

  const handleMultileImages = async (orderId, event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("orderId", orderId);
      formData.append("notes", notes);
      files.forEach((file) => formData.append("images", file));
      const response = await UploadMultipleImages(formData);
      if (response.status === 200) {
        toast.success("Images Uploaded Successfully!");
        setFiles([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uniqueId = localStorage.getItem("uniqueId");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await JRPTPendingDesigns(uniqueId);
        console.log(response, "response");

        if (response) {
          const orders = response.orders;
          setOrders(orders);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrders();
  }, [uniqueId]);

  return (
    <div>
      <div className="orderTitleContainer">
        <div className="navbarLayer">
          <div className="logoContainer">
            <i
              onClick={handlePrev}
              id="backwardArrow"
              className="fa-solid fa-circle-arrow-left"
            ></i>
            <b className="titleOrders">Pending Samples in JRPT</b>
            <UserDropdown />
          </div>
        </div>
      </div>
      <h2>Number of Orders : {orders.length}</h2>
      <SearchComponent orders={orders} setOrders={setOrders} />
      <div className="ordersContainer">
        {orders.map((order) => (
          <div className="ordersItem" key={order._id}>
            <div className="orderBoxId">
              <p
                style={{
                  color: "#800000",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
              >
                ORDER ID: {order.orderId}
              </p>
            </div>
            <p>
              <b>Client Name : </b> {order.clientName}
            </p>
            <p>
              <b>Client Email : </b> {order.clientEmail}
            </p>
            <p>
              <b>Client Address : </b> {order.clientAddress}
            </p>
            <p>
              <b>Client Contact : </b> {order.clientContact}
            </p>
            <p>
              <b>Client Pincode :</b> {order.clientPincode}
            </p>
            <p>
              <b>Client GST : </b> {order.clientGST}
            </p>
            <p>
              <b>Box Type : </b> {order.boxType}
            </p>
            <p>
              <b>Closure :</b> {order.closure}
            </p>
            <p>
              <b>Dimension: </b> {order.dim} {order.dimUnit}
            </p>

            <p>
              <b>Quantity 1 : </b>
              {order.qtt1}
            </p>
            <p>
              <b>Quantity 2 : </b>
              {order.qtt2}
            </p>
            <p>
              <b>Quantity 3 :</b> {order.qtt3}
            </p>
            <p>
              <b>Paper GSM : </b> {order.quality}
            </p>
            <p>
              <b>Region : </b> {order.region}
            </p>
            <p>
              <b>Transport :</b> {order.transport}
            </p>

            <div className="applicationsValueContainer">
              <p>
                Applications Value:
                <div className="applicationsValue">
                  {Object.keys(order.applicationsValue).map((app) => (
                    <div key={app}>
                      {order.applicationsValue[app] &&
                        ` ${app} : ${order.applicationsValue[app]}%`}
                    </div>
                  ))}
                </div>
              </p>
            </div>
            <div className="buttonContainer">
              <form
                id="uploadForm"
                onSubmit={(event) =>
                  handleSubmitWithImages(order.orderId, event)
                }
              >
                <div class="form-group">
                  <label for="imageFiles">Select multiple image files:</label>
                  <input
                    type="file"
                    id="imageFiles"
                    name="images"
                    multiple
                    required
                    onChange={handleMultipleChange}
                  />
                </div>

                {files.length > 0 && (
                  <InputTextField
                    type="textarea"
                    value={notes}
                    label={"Notes"}
                    setValue={setNotes}
                  />
                )}

                {files.length > 0 && (
                  <button
                    onClick={(event) => {
                      setUploadClicked(true);
                      handleMultileImages(order.orderId, event);
                    }}
                    type="submit"
                    className="btn"
                  >
                    Upload
                  </button>
                )}
              </form>

              {uploadClicked ? (
                <button
                  onClick={(event) =>
                    handleSubmitWithImages(order.orderId, event)
                  }
                  className="rejectButtonSMPending"
                >
                  Submit &nbsp;✅
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PendingSamplesInJRPT;
