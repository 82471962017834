import React from "react";

const CorrugatedClosureImages = ({ showModal }) => {
  const ClosureImage = ({ label, imageUrl }) => {
    return (
      <div className="closureImageContainer">
        <label>{label}</label>
        <img src={imageUrl} alt={label} />
      </div>
    );
  };
  return (
    <>
      <div className="modal-wrapper" onClick={showModal}></div>
      <div className="modal-container">
        <div className="closureContainer"></div>
        <br />

        <h4>Corrugated Closures</h4>
        <div
          style={{ borderBottom: "3px solid black", margin: "10px 0" }}
        ></div>
        <div className="closureContainer">
          <ClosureImage
            label="Mailer Box"
            imageUrl="/Images/mailerCorrugated.png"
          />
          <div
            style={{ borderBottom: "3px solid black", margin: "10px 0" }}
          ></div>
          <ClosureImage
            label="Top Bottom"
            imageUrl="/Images/topBottomCorrugated.png"
          />
          <div
            style={{ borderBottom: "3px solid black", margin: "10px 0" }}
          ></div>
          <ClosureImage
            label="Top Bottom Shoe Box"
            imageUrl="/Images/topBottomShoeBoxRigid.png"
          />
          <div
            style={{ borderBottom: "3px solid black", margin: "10px 0" }}
          ></div>
          <ClosureImage
            label="Tuck In"
            imageUrl="/Images/tuckInCorrugated.png"
          />
        </div>
        <br />

        <div className="modal-btn" onClick={showModal}>
          Close
        </div>
      </div>
    </>
  );
};

export default CorrugatedClosureImages;
