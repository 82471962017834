import React, {useContext, useState, useRef } from "react";
import { CommercialChitPadGalleyTypeOptions } from "../../DropDownOptions/CommercialChitPadGalleyTypeOptions";
import DropDownFields from "../../reusable/DropDownFields";
import InputTextField from "../../reusable/InputTextField";
import { CommercialQISContext } from "../../context/CommercialQISContext";
import {CommercialKappaPadThickness} from "../../DropDownOptions/CommercialKappaPadThickness";
import {CommercialPadGalleyInnerPaperGSMOptions} from "../../DropDownOptions/CommercialPadGalleyInnerPaperGSMOptions";
import {CommercialPadGalleyOuterPaperGSMOptions} from "../../DropDownOptions/CommercialPadGalleyOuterPaperGSMOptions";
import { LaminationCommercialOptions } from "../../DropDownOptions/LaminationCommercialOptions";
import { LaminationOptions } from "../../DropDownOptions/LaminationOptions";
import { PrintingColoursOptionsInsidePaper } from "../../DropDownOptions/PrintingColoursOptionsInsidePaper";
import {CommercialChitGSMOptions} from "../../DropDownOptions/CommercialChitGSMOptions";
import { PrintingColoursOptions } from "../../DropDownOptions/PrintingColoursOptions";
import CommercialApplications from "../../DropDownOptions/CommercialApplications";

const CommercialChitPad = () => {
    const quotationRef = useRef(null);
    const [displayQuotation, setDisplayQuotation] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [displayOrderDetails, setDisplayOrderDetails] = useState(false);

    const employeeId = localStorage.getItem("uniqueId");
    const boxType = "commercialPrinting";

    const {
        closure, setClosure,
        length, setLength,
        breadth, setBreadth,
        height, setHeight,
        dimUnit, setDimUnit,
        qtt1, setQtt1,
        qtt2, setQtt2,
        qtt3, setQtt3,
        quality, setQuality,
        lamination, setLamination,
        transport, setTransport,
        region, setRegion,
        applications, setApplications,
        applicationsValue, setApplicationsValue,
        printingColours, setPrintingColours,
        printingColoursInsidePaper, setPrintingColoursInsidePaper,
        doubleSide, setDoubleSide,
        diaCut, setDiaCut,
        posterLayout, setPosterLayout,
        posterGummingType, setPosterGummingType,
        dimensionType, setDimensionType,
        standardDimensions, setStandardDimensions,

        folderType, setFolderType,
        pouchSize, setPouchSize,
        customPouchSize, setCustomPouchSize,

        brochureType, setBrochureType,
        bookletPages, setbookletPages,
        bookletBindingType, setBookletBindingType,
        
        bookletCoverPaperType, setBookletCoverPaperType,
        bookletCoverPaperGSM, setBookletCoverPaperGSM,
        bookletCoverOutsideColours, setBookletCoverOutsideColours,
        bookletCoverLamination, setBookletCoverLamination,
        bookletCoverDoubleSide, setBookletCoverDoubleSide,
        bookletCoverInsideColours, setBookletCoverInsideColours,
        bookletCoverApplications, setBookletCoverApplications,
        
        bookletInnerPaperType, setBookletInnerPaperType,
        bookletInnerPaperGSM, setBookletInnerPaperGSM,
        bookletInnerOutsideColours, setBookletInnerOutsideColours,
        bookletInnerLamination, setBookletInnerLamination,
        bookletInnerDoubleSide, setBookletInnerDoubleSide,
        bookletInnerInsideColours, setBookletInnerInsideColours,
        bookletInnerApplications, setBookletInnerApplications,
        prescriptionPadType, setPrescriptionPadType,

        chitPadGalleyType, setChitPadGalleyType,
        padThickness, setPadThickness,
        padInnerPaperGSM, setPadInnerPaperGSM,
        padOuterPaperGSM, setPadOuterPaperGSM,
        padInnerPrinting, setPadInnerPrinting,
        padInnerPrintingColours, setPadInnerPrintingColours,
        padGalleyApplications, setPadGalleyApplications,

        chitNoOfChits, setChitNoOfChits,
        chitLength, setChitLength,
        chitBreadth, setChitBreadth,
        chitGSM, setChitGSM,
        chitPrintingColours, setChitPrintingColours,
        chitBackSidePrinting, setChitBackSidePrinting,
        chitBackSideColours, setChitBackSideColours,

    } = useContext(CommercialQISContext);

    const resetPadGalleyApplicationsValue = (appName) => {
        setApplicationsValue((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const handlePadGalleyApplicationChange = (event) => {
        const { name, checked } = event.target;
        setPadGalleyApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetPadGalleyApplicationsValue(name);
        }
    };

    return (
        <div>
            {/* 
            1. Chit Pad Galley Type
            2. L x B x H
            3. Kappa - Thickness
            4. Asspass Paper - GSM
            5. Outer Paper - GSM
            6. Outer Printing - No of colours
            7. Inner Printing - No of colours
            8. Lamination
            9. Applications
            */}
            <label className="cover-inner-section-title" style={{color:"#800000"}}>
                Galley (Rigid)
            </label>
            <div className="booklet-cover-inner-container">
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            options={CommercialChitPadGalleyTypeOptions}
                            label={"Galley Type"}
                            value={chitPadGalleyType}
                            setValue={setChitPadGalleyType}
                        />
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <InputTextField
                            value={length}
                            setValue={setLength}
                            label={"Length"}
                            placeholder={"Enter Length"}
                        />
                    </div>
                    <div className="input-box">
                        <InputTextField
                            value={breadth}
                            setValue={setBreadth}
                            label={"Breadth"}
                            placeholder={"Enter Breadth"}
                        />
                    </div>
                    <div className="input-box">
                        <InputTextField
                            value={height}
                            setValue={setHeight}
                            label={"Height"}
                            placeholder={"Enter Height"}
                        />
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            value={padThickness}
                            setValue={setPadThickness}
                            label= {"Board Thickness"}
                            options={CommercialKappaPadThickness}
                        />
                    </div>
                    <div className="input-box">
                        <DropDownFields
                            value={padInnerPaperGSM}
                            setValue={setPadInnerPaperGSM}
                            label={"Asspass GSM"}
                            options={CommercialPadGalleyInnerPaperGSMOptions}
                        />
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            value={padOuterPaperGSM}
                            setValue={setPadOuterPaperGSM}
                            label={"Outer Paper GSM"}
                            options={CommercialPadGalleyOuterPaperGSMOptions}
                        />
                    </div>
                    <div className="input-box">
                        <DropDownFields
                            options={LaminationOptions}
                            label={"Lamination"}
                            value={lamination}
                            setValue={setLamination}
                        />
                    </div>
                </div>
                <label>Inner Printing</label>
                <div className="column">
                    <div className="input-box">
                        <div className="doubleSidePrinting">
                            <div class="radio-label">
                                <input
                                    type="radio"
                                    name="padInnerDoubleSidePrinting"
                                    id="innerYes"
                                    value="yes"
                                    checked={padInnerPrinting === "yes"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setPadInnerPrinting(e.target.value)}
                                />
                                <label style={{marginTop:"26px"}} className="padInnerLabelNo" for="innerYes">
                                    Yes
                                </label>
                            </div>
                            <div className="radio-label">
                                <input
                                    type="radio"
                                    name="padInnerDoubleSidePrinting"
                                    id="innerNo"
                                    value="no"
                                    checked={padInnerPrinting === "no"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setPadInnerPrinting(e.target.value)}
                                />
                                <label style={{marginTop:"26px"}} className="padInnerLabelNo" for="innerNo">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="input-box">
                        {padInnerPrinting==="yes" && (
                            <DropDownFields
                            options={PrintingColoursOptionsInsidePaper}
                            value={padInnerPrintingColours}
                            setValue={setPadInnerPrintingColours}
                            label={"Colours for Inside Print"}
                            />
                        )}
                    </div>
                </div>
                <div className="applicationsGridContainer">
                    <label htmlFor="applications">Applications : </label>
                    <div className="commercialApplicationsGrid">
                        <CommercialApplications
                            applications={padGalleyApplications}
                            handleApplicationChange={handlePadGalleyApplicationChange}
                        />
                    </div>
                </div>
            </div>
            {/* 
            1. No of chits
            2. Paper GSM
            3. No of Printing Colours
            4. L x B
            5. Lamination
            6. Applications
            7. Double Side Printing
            8. Dia Cut
            */}
            <label className="cover-inner-section-title" style={{color:"#800000"}}>
                Chits (Paper)
            </label>
            <div className="booklet-cover-inner-container">
                <div className="column">
                    <div className="input-box">
                        <InputTextField
                            value={chitNoOfChits}
                            setValue={setChitNoOfChits}
                            label={"No. of chits"}
                        />
                    </div>
                    <div className="input-box">
                        <DropDownFields
                            value={chitGSM}
                            setValue={setChitGSM}
                            options={CommercialChitGSMOptions}
                            label={"GSM of Chits"}
                        />
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <InputTextField
                            value={chitLength}
                            setValue={setChitLength}
                            label={"Length"}
                        />
                    </div>
                    <div className="input-box">
                        <InputTextField
                            value={chitBreadth}
                            setValue={setChitBreadth}
                            label={"Breadth"}
                        />
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            value={chitPrintingColours}
                            setValue={setChitPrintingColours}
                            options={PrintingColoursOptions}
                            label={"No of Colours for Printing"}
                        />
                    </div>
                </div>
                <label>Chit Back Side Printing</label>
                <div className="column">
                    <div className="input-box">
                        <div className="doubleSidePrinting">
                            <div class="cover-radio-label">
                                <input
                                    type="radio"
                                    name="chitDoubleSidePrinting"
                                    id="coverYes"
                                    value="yes"
                                    checked={chitBackSidePrinting === "yes"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setChitBackSidePrinting(e.target.value)}
                                />
                                <label style={{marginTop:"26px"}} className="chitLabelNo" for="coverYes">
                                    Yes
                                </label>
                            </div>
                            <div className="cover-radio-label">
                                <input
                                    type="radio"
                                    name="chitDoubleSidePrinting"
                                    id="coverNo"
                                    value="no"
                                    checked={chitBackSidePrinting === "no"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setChitBackSidePrinting(e.target.value)}
                                />
                                <label style={{marginTop:"26px"}} className="chitLabelNo" for="coverNo">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="input-box">
                        {
                            (chitBackSidePrinting === "yes") && (
                                <DropDownFields
                                    value={chitBackSideColours}
                                    setValue={setChitBackSideColours}
                                    options={PrintingColoursOptionsInsidePaper}
                                    label={"Colours for Back Print"}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CommercialChitPad;