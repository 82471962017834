import React from "react";
import { useHistory } from "react-router-dom";
import "./salesDashboard.css";
import UserDropdown from "../reusable/UserDropdown";

const SalesDashboard = () => {
  const history = useHistory();

  const handleOrders = () => {
    history.push("/salesDashboard/managerOrders");
  };

  const handlePendingApproval = () => {
    history.push("salesDashboard/sm-pending-approval");
  };

  const handleSMEscalation = () => {
    history.push("/salesDashboard/SMEscalation");
  };

  return (
    <div className="mainDiv">
      <div className="navbarLayer">
        <div className="logoContainer">
          <h2>SM dashboard</h2>

          <UserDropdown />
        </div>
      </div>

      <div className="buttonContainer">
        <button onClick={() => handleOrders()} className="dashboardButton">
          <i class="fa-solid fa-check" id="orderIcon"></i>
          View Orders
        </button>
      </div>
      <div className="buttonContainer">
        <button
          onClick={() => handlePendingApproval()}
          className="dashboardButton"
        >
          <i className="fa-sharp fa-solid fa-xmark" id="pendingIcon"></i>
          Pending Approvals
        </button>
      </div>

      <div className="buttonContainer">
        <button
          onClick={() => handleSMEscalation()}
          className="dashboardButton"
        >
          <i class="fa-solid fa-bolt" id="escalationIcon"></i>
          Escalation
        </button>
      </div>
    </div>
  );
};

export default SalesDashboard;
