  export const FBBOptions = [
  { value: "", label: "None" },
  
  { value: "topBottom", label: "Top Bottom Closure"},

  { value: "fbe127", label: "Apparel - Sock Packing Box - FBE127" },
  { value: "fbe119", label: "Apparel - Luxury Clothing Bag - FBE119" },
  { value: "fbe288", label: "Apparel - Towel Packaging - FBE288" },
  { value: "fbe431", label: "Apparel - Tray With Fbb Sleeve - FBE431" },

  { value: "b153", label: "Bakery and Cakes - Bakery Pouch - B153"},
  { value: "b154", label: "Bakery and Cakes - Custom Bakery Bag - B154"},
  { value: "fbe066", label: "Bakery and Cakes - Custom Donut Box - FBE066"},
  { value: "fbe115", label: "Bakery and Cakes - Take-Out Container Box - FBE115"},
  { value: "fbe118", label: "Bakery and Cakes - Custom Pastry Box - FBE118"},
  { value: "fbe120", label: "Bakery and Cakes - Baked Goods Packaging - FBE120"},
  { value: "fbe139", label: "Bakery and Cakes - Cookie Box With Window - FBE139"},
  { value: "fbe282", label: "Bakery and Cakes - Cake box With Handle - FBE282"},
  { value: "fbe473", label: "Bakery and Cakes - Bakery and Cakes Box - FBE473"},
  { value: "fbe474", label: "Bakery and Cakes - Printed Bakery Box - FBE474"},
  { value: "fbe505", label: "Bakery and Cakes - Pie Packaging Box - FBE505"},
  { value: "fbe504", label: "Bakery and Cakes - Pastry Packaging Box - FBE504"},
  { value: "fbe6015", label: "Bakery and Cakes - Pie Box - FBE6015"},
  { value: "fbe476", label: "Bakery and Cakes - Mini Cake Box - FBE476"},
  { value: "fbe507", label: "Bakery and Cakes - Cookie Packaging Box - FBE507"},
  { value: "fbe506", label: "Bakery and Cakes - Macaron Packaging Box - FBE506"},
  { value: "fbe279", label: "Bakery and Cakes - Ice-cream Cake Packaging with window - FBE279"},
  // { value: "fbe475", label: "Bakery and Cakes - Printed Bakery Box - FBE475"},
  
  { value: "fbe508", label: "Chocolates and Candies - Custom Candy Bar Packaging Hanger Box - FBE508"},
  { value: "fbe082", label: "Chocolates and Candies - Chocolate Subscription Box - FBE082"},
  { value: "fbe6003", label: "Chocolates and Candies - Custom Pillow Candies Box -FBE6003"},
  { value: "fbe101", label: "Chocolates and Candies - Chocolate Slab Packaging Box - FBE101"},
  
  { value: "f001", label: "Coffee - Reverse Tuck End - F001" },
  { value: "f002", label: "Coffee - Straight Tuck End - F002" },
  { value: "f003", label: "Coffee - Bookend - F003" },
  { value: "f005", label: "Coffee - Tuck End Auto Bottom - F005" },
  { value: "f006", label: "Coffee - Full Flap Auto Bottom - F006" },
  { value: "f009", label: "Coffee - Gable Bag - F009" },
  { value: "f013", label: "Coffee - Lock Cap Auto Bottom - F013" },
  { value: "f014", label: "Coffee - 6 Corner Box - F014" },
  { value: "f021", label: "Coffee - Hexagon Box - F021" },
  { value: "f022", label: "Coffee - 4 Corner Display Box - F022" },
  { value: "f023", label: "Coffee - Auto Bottom Display Box - F023" },
  { value: "f024", label: "Coffee - Side Lock Tuck Top Box - F024" },

  { value: "fbe443", label: "Cosmetics - Makeup Glass Jar Packaging - FBE443" },
  { value: "fbe502", label: "Cosmetics - Hair Mask Packaging - FBE502" },
  { value: "fbe501", label: "Cosmetics - Moisturizer Cream Packaging Box - FBE6015" },
  { value: "fbe499", label: "Cosmetics - Hair Dye Packaging Box - FBE499" },
  { value: "fbe241", label: "Cosmetics - Face Mask Box Combo Box - FBE241" },
  { value: "fbe214", label: "Cosmetics - Cream Packaging Box - FBE214" },
  { value: "fbe442", label: "Cosmetics - Lipstick Box - FBE442" },
  { value: "fbe441", label: "Cosmetics - Hand Cream Box - FBE441" },
  { value: "fbe6093", label: "Cosmetics - Mascara Box - FBE6093" },

  { value: "fbe458", label: "Retail - Retail Cardboard Box - FBE458" },
  { value: "fbe459", label: "Retail - Custom Printed Retail Box - FBE459" },
  { value: "fbe461", label: "Retail - Electronic Hanger Boxes - FBE461" },
  { value: "fbe465", label: "Retail - Retail Boxes Packaging - FBE465" },
  { value: "fbe468", label: "Retail - Retail Product Display Box - FBE468" },
  { value: "fbe471", label: "Retail - Display Box with Logo - FBE471" },
  { value: "fbe470", label: "Retail - Custom Printed Display Box for Retail - FBE470" },
  { value: "fbe467", label: "Retail - Professional Packaging for Products - FBE467" },
  { value: "fbe466", label: "Retail - Retail Cardboard Box - FBE466" },
  { value: "fbe464", label: "Retail - Hanging Cardboard - FBE464" },
  { value: "fbe462", label: "Retail - Vapes Product Hanger Boxes - FBE462" },
  { value: "fbe493", label: "Retail - CBD Oil Packaging Box - FBE493" },
  { value: "fbe495", label: "Retail - Hardware Packaging Box - FBE495" },
  { value: "fbe496", label: "Retail - Fountain Pen Packaging Box - FBE496" },
  { value: "fbe498", label: "Retail - Art Supply Packaging Box - FBE498" },
  { value: "fbe042", label: "Retail - Petal Top Box - FBE042" },
  { value: "fbe055", label: "Retail - Gold Foil Boxes with Lids - FBE055" },
  { value: "fbe056", label: "Retail - Drawer Paper Boxes - FBE056" },
  { value: "fbe057", label: "Retail - Floral Print Pillow Box - FBE057" },
  { value: "fbe058", label: "Retail - Perfume Box - FBE058" },
  { value: "fbe061", label: "Retail - Tuck Flap with Inside Cavity - FBE061" },
  { value: "fbe063", label: "Retail - Soap Box - FBE063" },
  { value: "fbe065", label: "Retail - Custom Cardboard Tray and Sleeve - FBE065" },
  { value: "fbe078", label: "Retail - Hair Product Packaging Box - FBE078" },
  { value: "fbe126", label: "Retail - Custom Die Cut Box - FBE126" },
  { value: "fbe140", label: "Retail - Tapered Top Box - FBE140" },
];
