// export const BASE_URL = "http://localhost:8080"; // while testing in local
// export const PDF_URL = "http://localhost:8080/pdfs";

export const BASE_URL = "http://baxsaaforward.in:5000"; // while testing in local
export const PDF_URL = "http://baxsaaforward.in:5000/pdfs";

export const ENDPOINTS = {
  LOGIN: "/login", // login api

  // BDA Dashboard
  BDA_ORDERS: "/orders",
  BDA_ESCALATION: "/escalationType1",
  PUNCH_DATA: "/punchData",
  QIS_ORDER_PUNCH: "/qissend",
  COMMERCIAL_QIS: "/commercialQIS",
  QIS_MAIL: "/qismail",
  BDA_EDITABLE_ORDER: "/escalationType1/edit",
  PUNCH_ORDERS_WITH_IMAGES: "/BDAReferenceImage",
  VIEW_PREV_QUOTATIONS: "/bdaPreviousQuotes",
  SEARCH_QUOTATIONS: "/bdaPreviousQuotes/search",
  SEND_CLIENT_ID_IN_PUNCH: "/getClientFolderPath",
  DOWNLOAD_EXCEL_WORKING: "/downloadExcel",

  // Sales Manages (SM) Dashboard
  SM_ORDERS: "/manager",
  SM_PENDING_APPROVAL: "/sm_pending_approvals",
  SM_ORDER_REJECTED: "/smreject",
  SM_ORDER_ACCEPT: "/smaccept",
  SM_ESCALATION: "/escalationType2",
  SM_ESCALATION_REJECT: "/smreject",
  SM_ESCALATION_EDIT_ORDER: "/escalationType2/edit",

  // PTCS Dashboard
  PTCS_ORDERS: "/ptcsOrders",
  PTCS_PENDING_APPROVAL: "/ptcs_pending_approvals",
  PTCS_ACCEPTED_ORDER: "/ptcsAccept",
  PTCS_REJECTED_ORDER: "/ptcsReject",
  PTCS_ESCALATION_ORDERS: "/escalationType3",
  PTCS_ESCALATION_EDITABLE_ORDER: "/escalationType3/edit",

  // CS Dashboard
  CS_BRIEF_SHEETS: "/csBriefSheets",
  ACCEPTED_ORDER_IN_CS: "/csBriefAccept",
  REJECTED_ORDER_IN_CS: "/csBriefReject",
  GET_GRAPHIC_DESIGNS: "/csGraphicDesigns",
  ACCEPT_PGD_DESIGNS_IN_CS: "/csGDAccept",
  REJECT_PGD_DESIGNS_IN_CS: "/csGDReject",
  REJECTED_ORDER_BY_CS_TO_PTCS: "/csSampleReject",
  CS_SAMPLE_DISPATCH_ORDERS: "/csSampleDispatch",
  VIEW_IMAGES_IN_SAMPLE_DISPATCH: "/getimages",
  ACCEPTED_ORDER_BY_CS_IN_SAMPLE_DISPATCH: "/csSampleAccept",
  CS_FINAL_DISPATCH: "/csFinalDispatch",
  CS_FINAL_DISPATCH_IMAGES: "/finalimages",
  CS_FINAL_CLOSE_SALE: "/csClose",
  CS_PROFORMA_INVOICE_INPUT: "/proformaMail",

  // PGD Dashboard
  PGD_PENDING_DESIGNS: "/pgdPendingDesigns",
  PDF_UPLOAD: "/uploadpdf",
  SUBMIT_IN_BRIEF_SHEETS_PGD: "/submitPdf",

  //JRPT Dashboard

  PENDING_DESIGNS_IN_JRPT: "/jrptPendingSamples", // get orders by sending employee Id in query param
  JRPT_SAMPLE_UPLOAD_IMAGES: "/JRPTSampleUpload", // on uploading images send formData, orderId, notes
  SUBMIT_WITH_IMAGES: "/jrptSampleSubmit", // orderId
  JRPT_FINAL_DISPATCH: "/jrptFinalDispatch", // get orders by sending employeeId
  JRPT_IN_FINAL_UPLOAD: "/jrptFinalUpload", // send formData, orderId, notes
  JRPT_FINAL_SUBMIT: "/jrptFinalSubmit", // send order id
};
