export const FbbPaperTypeOptions = [
  { value: "", label: "None" },
  { value: "fbb", label: "FBB" },
  { value: "whiteBack", label: "White Back" },
  { value: "greyBack", label: "Grey Back" },
  { value: "peCoating", label: "PE Coating" },
  { value: "specialityPaper", label: "Speciality Paper"},
  { value: "kraft", label: "Kraft"}
];

