import React, { useEffect, useState } from "react";
import { BDAEscalation, editableOrdersofBDA } from "../apis/apiClients";
import { useHistory } from "react-router-dom";
import "./BDAEscalation.css";
import UserDropdown from "../reusable/UserDropdown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BDAEscalationComponent = () => {
  const [orders, setOrders] = useState([]);
  const [tempOrders, setTempOrders] = useState([]);
  const [changed, setChanged] = useState(false);
  const [applicationState, setApplicationState] = useState(false);

  const uniqueId = localStorage.getItem("uniqueId");

  const history = useHistory();

  const handlePrev = () => {
    history.push("/dashboard");
  };

  const BDAEditedOrder = () => {
    toast.success("Order Edited Successfully!");

    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  useEffect(() => {
    const fetchEscalationOrders = async () => {
      try {
        const response = await BDAEscalation({
          uniqueId: uniqueId,
          Type1: "type1",
        });

        if (response) {
          const orders = response.orders;
          setOrders(orders);
          setTempOrders(orders);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchEscalationOrders();
  }, [uniqueId]);

  useEffect(() => {
    const hasApplications = tempOrders.some((order) => order.applications);
    setApplicationState(hasApplications);
  }, [tempOrders]);

  const handleInputChange = (index, field, value, applicationsValue) => {
    const updatedOrders = [...tempOrders];
    if (applicationsValue) {
      updatedOrders[index][applicationsValue] = {
        ...updatedOrders[index][applicationsValue],
        [field]: value,
      };
      if (field === "applicationsValue") {
        updatedOrders[index]["applications"] = true;
        setApplicationState(true);
      }
    } else {
      updatedOrders[index] = {
        ...updatedOrders[index],
        [field]: value,
      };
    }
    setChanged(true);
    setTempOrders(updatedOrders);
  };

  const updateOrder = async (editedOrder) => {
    try {
      const res = await editableOrdersofBDA([editedOrder]);
      if (res.status === 200) {
        console.log("Orders updated successfully!");
        setOrders((prevOrders) => {
          const updatedOrders = prevOrders.map((order) =>
            order.orderId === editedOrder.orderId ? editedOrder : order
          );
          return updatedOrders;
        });
        setChanged(false);
        if (editedOrder) {
          const editedOrderId = editedOrder.orderId;
          console.log("Edited Order ID:", editedOrderId);
        }
      }
      toast.success("Order Edited Successfully!");
      return res.data;
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  return (
    <div>
      <div className="navbarLayer">
        <div className="logoContainer">
          <i
            onClick={handlePrev}
            id="backwardArrow"
            className="fa-solid fa-circle-arrow-left"
          ></i>
          <b className="titleOrders">BDA Escalation Orders</b>

          <UserDropdown />
        </div>
      </div>
      <h2>Number of Escalation : {orders.length}</h2>
      <div className="ordersContainer">
        {orders.length === 0 ? (
          <h2>No orders found.</h2>
        ) : (
          orders.map((order, index) => (
            <div className="ordersItem" key={order._id}>
              <div className="orderBoxId">
                <p
                  style={{
                    color: "#800000",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  ORDER ID: {order.orderId}
                </p>
              </div>
              <br />
              <label>Client Name :</label>
              <input
                type="text"
                value={tempOrders[index].clientName}
                onChange={(e) =>
                  handleInputChange(index, "clientName", e.target.value)
                }
              />
              <label>Client Email : </label>
              <input
                type="text"
                value={tempOrders[index].clientEmail}
                onChange={(e) =>
                  handleInputChange(index, "clientEmail", e.target.value)
                }
              />
              <label>Client Contact :</label>
              <input
                type="text"
                value={tempOrders[index].clientContact}
                onChange={(e) =>
                  handleInputChange(index, "clientContact", e.target.value)
                }
              />
              <label>Client Pincode :</label>
              <input
                type="text"
                value={tempOrders[index].clientPincode}
                onChange={(e) =>
                  handleInputChange(index, "clientPincode", e.target.value)
                }
              />
              <label>Client GST :</label>
              <input
                type="text"
                value={tempOrders[index].clientGST}
                onChange={(e) =>
                  handleInputChange(index, "clientGST", e.target.value)
                }
              />
              <label>Box Type : </label>
              <select
                value={tempOrders[index].boxType}
                onChange={(e) =>
                  handleInputChange(index, "boxType", e.target.value)
                }
              >
                <option value=""></option>
                <option value="rigidBox">Rigid Box</option>
                <option value="CorrugatedBox">Corrugated Box</option>
                <option value="paperBag">Paper Bag</option>
              </select>

              <label>Closure :</label>
              <input
                type="text"
                value={tempOrders[index].closure}
                onChange={(e) =>
                  handleInputChange(index, "closure", e.target.value)
                }
              />
              <label>Dimension :</label>
              <input
                type="text"
                value={tempOrders[index].dim}
                onChange={(e) =>
                  handleInputChange(index, "dim", e.target.value)
                }
              />
              <label>Dimension Unit :</label>
              <input
                type="text"
                value={tempOrders[index].dimUnit}
                onChange={(e) =>
                  handleInputChange(index, "dim", e.target.value)
                }
              />

              <label>Quantity 1 : </label>
              <input
                type="text"
                value={tempOrders[index].qtt1}
                onChange={(e) =>
                  handleInputChange(index, "qtt1", e.target.value)
                }
              />
              <label>Quantity 2 : </label>
              <input
                type="text"
                value={tempOrders[index].qtt2}
                onChange={(e) =>
                  handleInputChange(index, "qtt2", e.target.value)
                }
              />
              <label>Quantity 3 : </label>
              <input
                type="text"
                value={tempOrders[index].qtt3}
                onChange={(e) =>
                  handleInputChange(index, "qtt3", e.target.value)
                }
              />
              <label>Paper GSM : </label>
              <input
                type="text"
                value={tempOrders[index].quality}
                onChange={(e) =>
                  handleInputChange(index, "quality", e.target.value)
                }
              />
              <label>Region : </label>
              <input
                type="text"
                value={tempOrders[index].region}
                onChange={(e) =>
                  handleInputChange(index, "region", e.target.value)
                }
              />
              <label>Transport : </label>
              <input
                type="text"
                value={tempOrders[index].transport}
                onChange={(e) =>
                  handleInputChange(index, "transport", e.target.value)
                }
              />
              <p>
                <label>Applications :</label>
                {applicationState && (
                  <>
                    {Object.keys(tempOrders[index].applicationsValue).map(
                      (app) => (
                        <div key={app}>
                          {tempOrders[index].applicationsValue[app] !==
                            undefined && (
                            <>
                              <span>{`${app}: `}</span>
                              <input
                                type="text"
                                value={tempOrders[index].applicationsValue[app]}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    app,
                                    e.target.value,
                                    "applicationsValue"
                                  )
                                }
                              />
                            </>
                          )}
                        </div>
                      )
                    )}
                  </>
                )}
              </p>

              {changed ? (
                <>
                  <button
                    className="editButton"
                    onClick={() => {
                      setTempOrders([...orders]);
                      setChanged(false);
                      window.location.reload(true);
                    }}
                  >
                    Cancel ❌
                  </button>
                  <button
                    className="editButton"
                    onClick={() => {
                      BDAEditedOrder();
                      updateOrder(tempOrders[index]);
                    }}
                  >
                    Submit ✅
                  </button>
                </>
              ) : null}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BDAEscalationComponent;
