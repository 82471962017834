import React, { createContext, useState } from "react";

export const OrderDetailsContext = createContext();

const OrderDetailsProvider = ({ children }) => {
  const [remarks, setRemarks] = useState("");
  const [imageUpload, setImageUpload] = useState(false);
  const [receivedOrderId, setReceivedOrder] = useState("");
  const [receivedClientId, setReceivedClientId] = useState("");
  const [folderPath, setFolderPath] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientContact, setClientContact] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientPincode, setClientPincode] = useState("");
  const [clientGST, setClientGST] = useState();
  const [boxType, setBoxType] = useState("");
  const [product, setProduct] = useState("");
  const [closure, setClosure] = useState("");
  const [asspassPaper, setAsspassPaper] = useState("");
  const [asspassQuality, setAsspassQuality] = useState("");
  const [boardThickness, setBoardThickness] = useState("");
  const [kraftQuality, setKraftQuality] = useState("");
  const [cuffSize, setCuffSize] = useState("");
  const [handle, setHandle] = useState("");
  const [dimUnit, setDimUnit] = useState("");
  const [dim, setDim] = useState("");
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");
  const [qtt1, setQtt1] = useState("");
  const [quality, setQuality] = useState("");
  const [transport, setTransport] = useState("");
  const [region, setRegion] = useState("");
  const [cavity, setCavity] = useState("");
  const [price1, setPrice1] = useState("");
  const [saleDate, setSaleDate] = useState("");
  const [doubleSide, setDoubleSide] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [applications, setApplications] = useState({
    Matte: false,
    Gloss: false,
    Gold: false,
    Thermal: false,
    Pearl: false,
    SpotUV: false,
    EmbossDia: false,
    FoilingDia: false,
    Foiling: false,
    DripOffUV: false,
    Varnish: false,
    Gumming: false,
    PunchDia: false,
  });

  const [applicationsValue, setApplicationsValue] = useState({
    Matte: null,
    Gloss: null,
    Gold: null,
    Thermal: null,
    Pearl: null,
    SpotUV: null,
    EmbossDia: null,
    FoilingDia: null,
    Foiling: null,
    DripOffUV: null,
    Varnish: null,
    Gumming: null,
    PunchDia: null,
  });
  return (
    <OrderDetailsContext.Provider
      value={{
        remarks,
        setRemarks,
        imageUpload,
        setImageUpload,
        receivedOrderId,
        setReceivedOrder,
        receivedClientId,
        setReceivedClientId,
        folderPath,
        setFolderPath,
        showImage,
        setShowImage,
        error,
        setError,
        files,
        setFiles,
        clientName,
        setClientName,
        clientEmail,
        setClientEmail,
        clientContact,
        setClientContact,
        clientAddress,
        setClientAddress,
        clientPincode,
        setClientPincode,
        clientGST,
        setClientGST,
        product,
        setProduct,
        cavity,
        setCavity,
        boxType,
        setBoxType,
        closure,
        setClosure,
        asspassPaper,
        setAsspassPaper,
        asspassQuality,
        setAsspassQuality,
        boardThickness,
        setBoardThickness,
        kraftQuality,
        setKraftQuality,
        cuffSize,
        setCuffSize,
        handle,
        setHandle,
        dim,
        setDim,
        dimUnit,
        setDimUnit,
        length,
        setLength,
        breadth,
        setBreadth,
        height,
        setHeight,
        qtt1,
        setQtt1,
        quality,
        setQuality,
        transport,
        setTransport,
        region,
        setRegion,
        price1,
        setPrice1,
        saleDate,
        setSaleDate,
        uploadFile,
        setUploadFile,
        doubleSide,
        setDoubleSide,
        applications,
        setApplications,
        applicationsValue,
        setApplicationsValue,
      }}
    >
      {children}
    </OrderDetailsContext.Provider>
  );
};

export default OrderDetailsProvider;
