import React from "react";

const InputTextAreaField = ({ label, value, setValue, placeholder }) => {
  return (
    <div>
      <div>
        <label htmlFor={label}>{label}</label>
        <textarea
          placeholder={placeholder}
          style={inputStyle}
          type="textarea"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required
        />
      </div>
    </div>
  );
};

const inputStyle = {
  width: "100%",
  padding: "10px",
  borderRadius: "5px",
  fontSize: "14px",
  lineHeight: "1.5",
  resize: "vertical",
  transition: "border-color 0.3s ease-in-out",
  border: "1px solid #ccc",
  outline: "none",
  fontFamily: "'Open Sans', sans-serif",
};

export default InputTextAreaField;
