import React, { useState, useRef, useEffect } from "react";

const SearchComponent = ({ orders, setOrders }) => {
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleChange = (value) => {
    setInput(value);

    if (value === "") {
      setOrders(orders);
      window.location.reload(true);
    } else {
      const filteredOrders = orders.filter((order) => {
        const orderValues = Object.values(order);
        return orderValues.some((val) => val && val.toString().includes(value));
      });
      setOrders(filteredOrders);
    }
  };

  return (
    <div>
      <div className="input-wrapper">
        <i class="fa-sharp fa-solid fa-magnifying-glass" id="searchIcon"></i>
        <input
          ref={inputRef}
          className="inputSearch"
          type="text"
          placeholder="type to search..."
          value={input}
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
    </div>
  );
};

export default SearchComponent;
