import React, { useState } from "react";
import UserDropdown from "../reusable/UserDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./CS-QIS.css";
// import "./RigidBoxContainer.css";
import CorrugatedContainer from "../InputFieldsForBoxesCSQIS/CorrugatedContainer";
import PaperBagContainer from "../InputFieldsForBoxesCSQIS/PaperBagContainer";
import FBBBoxContainer from "../InputFieldsForBoxesCSQIS/FBBBoxContainer";
import RigidBoxContainer from "../InputFieldsForBoxesCSQIS/RigidBoxContainer";
// import FoodGradeContainer from "../InputFieldsForBoxesCSQIS/FoodGradeContainer";
import CommercialContainer from "../InputFieldsForBoxesCSQIS/CommercialContainer";

const CSQIS = () => {
  const history = useHistory();
  const [activeButton1, setActiveButton1] = useState(null);
  const [activeButton2, setActiveButton2] = useState(null);
  const [activeButton3, setActiveButton3] = useState(null);
  const [activeButton4, setActiveButton4] = useState(null);
  const [activeButton5, setActiveButton5] = useState(null);

  const handlePrev = () => {
    history.push("/CSDashboard");
  };

  const handleButtonClick1 = (button) => {
    setActiveButton1(button);
    setActiveButton2(null);
    setActiveButton3(null);
    setActiveButton4(null);
    setActiveButton5(null);
  };

  const handleButtonClick2 = (button) => {
    setActiveButton2(button);
    setActiveButton1(null);
    setActiveButton3(null);
    setActiveButton4(null);
    setActiveButton5(null);
  };

  const handleButtonClick3 = (button) => {
    setActiveButton3(button);
    setActiveButton1(null);
    setActiveButton2(null);
    setActiveButton4(null);
    setActiveButton5(null);
  };
  const handleButtonClick4 = (button) => {
    setActiveButton4(button);
    setActiveButton1(null);
    setActiveButton2(null);
    setActiveButton3(null);
    setActiveButton5(null);
  };

  const handleButtonClick5 = (button) => {
    setActiveButton5(button);
    setActiveButton1(null);
    setActiveButton2(null);
    setActiveButton3(null);
    setActiveButton4(null);
  };

  return (
    <div>
      <div className="navbarLayer">
        <div className="logoContainer">
          <i
            onClick={handlePrev}
            id="backwardArrow"
            className="fa-solid fa-circle-arrow-left"
          ></i>
          <b className="titleOrders">CS QIS!</b>
          <UserDropdown />
        </div>
      </div>
      <h3
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Choose your box type!
      </h3>

      <div className="boxTypeContainer">
        <div className="button-row button-row1">
          <button
            className={`button${activeButton1 === "FBB" ? " is-active" : ""}`}
            onClick={() => handleButtonClick1("FBB")}
          >
            FBB
          </button>
          <button
            className={`button${
              activeButton2 === "Corrugated box" ? " is-active" : ""
            }`}
            onClick={() => handleButtonClick2("Corrugated box")}
          >
            Corrugated box
          </button>
          <button
            className={`button${
              activeButton3 === "Rigid Box" ? " is-active" : ""
            }`}
            onClick={() => handleButtonClick3("Rigid Box")}
          >
            Rigid Box
          </button>
          <button
            className={`button${
              activeButton4 === "Paper Bag" ? " is-active" : ""
            }`}
            onClick={() => handleButtonClick4("Paper Bag")}
          >
            Paper Bag
          </button>
          <button
            className={`button${
              activeButton5 === "Commercial Printing" ? " is-active" : ""
            }`}
            onClick={() => handleButtonClick5("Commercial Printing")}
          >
            Commercial Printing
          </button>
        </div>
      </div>

      <div className="displayDetails">
        {activeButton1 ? (
          <div>
            <FBBBoxContainer />
          </div>
        ) : (
          ""
        )}

        {activeButton2 ? (
          <div>
            <CorrugatedContainer />
          </div>
        ) : (
          ""
        )}

        {activeButton3 ? (
          <div>
            <RigidBoxContainer />
          </div>
        ) : (
          ""
        )}

        {activeButton4 ? (
          <div>
            <PaperBagContainer />
          </div>
        ) : (
          ""
        )}

        {activeButton5 ? (
          <div>
            <CommercialContainer />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CSQIS;
