import axios from "axios";
import { BASE_URL, ENDPOINTS } from "../constants/constants";
import { ToastContainer } from "react-toastify";

// login api
export const fetchLoginDetails = async (email, password, role) => {
  try {
    const res = await axios.post(`${BASE_URL}${ENDPOINTS.LOGIN}`, {
      email: email.toString(),
      password: password.toString(),
      role: role.toString(),
    });
    return res;
  } catch (error) {
    throw error;
  }
};

//punching data api

export const postPunchCost = async (
  employeeId,
  clientName,
  clientEmail,
  clientContact,
  clientAddress,
  clientPincode,
  clientGST,
  boxType,
  closure,
  dimUnit,
  dim,
  qtt1,
  transport,
  quality,
  region,
  kraftQuality,
  asspassPaper,
  asspassQuality,
  boardThickness,
  cuffSize,
  handle,
  applications,
  applicationsValue,
  price1,
  saleDate,
  remarks,
  product,
  doubleSide,
  cavity
) => {
  try {
    const timestamp = new Date().getTime();
    const res = await axios.post(`${BASE_URL}${ENDPOINTS.PUNCH_DATA}`, {
      employeeId,
      clientName: clientName.toString(),
      clientEmail: clientEmail.toString(),
      clientContact: clientContact.toString(),
      clientAddress: clientAddress.toString(),
      clientPincode: clientPincode.toString(),
      clientGST: clientGST.toString(),
      boxType: boxType.toString(),
      closure: closure.toString(),
      dimUnit: dimUnit.toString(),
      dim: dim.toString(),
      qtt1: qtt1.toString(),
      transport: transport.toString(),
      quality: quality.toString(),
      region: region.toString(),
      kraftQuality: kraftQuality.toString(),
      asspassPaper: asspassPaper.toString(),
      asspassQuality: asspassQuality.toString(),
      boardThickness: boardThickness.toString(),
      cuffSize: cuffSize.toString(),
      handle: handle.toString(),
      applications,
      applicationsValue,
      price1: price1.toString(),
      saleDate: saleDate.toString(),
      remarks: remarks.toString(),
      product: product.toString(),
      doubleSide: doubleSide.toString(),
      cavity: cavity.toString(),
      timestamp: timestamp.toString(),
    });
    return res;
  } catch (error) {
    throw error;
  }
};

// sending client id in punch
export const sendClientIDinPunch = async (receivedClientId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.SEND_CLIENT_ID_IN_PUNCH}?clientId=${receivedClientId}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// get orders for BDA api

export const getBDAOrders = async (uniqueId) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.BDA_ORDERS}`, {
      params: {
        employeeId: uniqueId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// view previous quotations

export const viewPreviousQuotations = async ({ uniqueId, currPageCount }) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.VIEW_PREV_QUOTATIONS}?employeeId=${uniqueId}&currentPage=${currPageCount}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// search quotations

export const SearchPreviousQuotations = async (query, uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.SEARCH_QUOTATIONS}?employeeId=${uniqueId}&searchQuery=${query}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// get orders for manager in SM dashboard

export const SMmanagerOrders = async (uniqueId) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.SM_ORDERS}`, {
      params: {
        managerId: uniqueId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// get pending orders in SM dashboard

export const pendingApprovalofSM = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.SM_PENDING_APPROVAL}`,
      {
        params: {
          employeeId: uniqueId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// getting rejected order in escalation
export const rejectedOrder = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.SM_ORDER_REJECTED}`,
      {
        params: {
          orderId: orderId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const acceptedOrderInSM = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.SM_ORDER_ACCEPT}`,
      {
        params: {
          orderId: orderId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// rejected orders

export const BDAEscalation = async ({ uniqueId, Type1 }) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.BDA_ESCALATION}`, {
      params: {
        employeeId: uniqueId,
        escalationStatus: Type1,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// editable orders in BDA
export const editableOrdersofBDA = async (tempOrders) => {
  try {
    const res = await axios.patch(
      `${BASE_URL}${ENDPOINTS.BDA_EDITABLE_ORDER}`,
      {
        tempOrders: tempOrders,
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

// qis order details. this is also where the API gets hit
// qissend

const toStringOrNull = (value) => {
  return value !== null && typeof value !== "string" ? value.toString() : value;
};

export const qisOrderDetails = async (
  clientName,
  clientEmail,
  boxType,
  product,
  closure,
  kraftQuality,
  asspassPaper,
  asspassQuality,
  cuffSize,
  boardSize,
  dimUnit,
  handle,
  dim,
  qtt1,
  qtt2,
  qtt3,
  transport,
  quality,
  paperBagPaperType,
  region,
  applications,
  applicationsValue,
  employeeId,
  cavity,
  doubleSide,
  lamination,
  fbbPaperType,
  handleAllClosureType,
  corrugatedInnerPaper,
  corrugatedOuterPaper,
  foodGradePaperColour,
  foodGradePrintingType,
  foodGradePaperGSM,
  printingColours,
  printingColoursInsidePaper,
) => {
  try {
    const timestamp = new Date().getTime();
    const res = await axios.post(`${BASE_URL}${ENDPOINTS.QIS_ORDER_PUNCH}`, {
      clientName: toStringOrNull(clientName),
      clientEmail: toStringOrNull(clientEmail),
      boxType: toStringOrNull(boxType),
      product: toStringOrNull(product),
      closure: toStringOrNull(closure),
      kraftQuality: toStringOrNull(kraftQuality),
      asspassPaper: toStringOrNull(asspassPaper),
      asspassQuality: toStringOrNull(asspassQuality),
      cuffSize: toStringOrNull(cuffSize),
      boardSize: toStringOrNull(boardSize),
      dimUnit: toStringOrNull(dimUnit),
      handle: toStringOrNull(handle),
      dim: toStringOrNull(dim),
      qtt1: toStringOrNull(qtt1),
      qtt2: toStringOrNull(qtt2),
      qtt3: toStringOrNull(qtt3),
      transport: toStringOrNull(transport),
      quality: toStringOrNull(quality),
      paperBagPaperType: toStringOrNull(paperBagPaperType),
      region: toStringOrNull(region),
      applications,
      applicationsValue,
      employeeId,
      cavity: toStringOrNull(cavity),
      doubleSide: toStringOrNull(doubleSide),
      lamination: toStringOrNull(lamination),
      fbbPaperType: toStringOrNull(fbbPaperType),
      handleAllClosureType: toStringOrNull(handleAllClosureType),
      corrugatedInnerPaper: toStringOrNull(corrugatedInnerPaper),
      corrugatedOuterPaper: toStringOrNull(corrugatedOuterPaper),
      foodGradePaperColour: toStringOrNull(foodGradePaperColour),
      foodGradePrintingType: toStringOrNull(foodGradePrintingType),
      foodGradePaperGSM: toStringOrNull(foodGradePaperGSM),
      printingColours: toStringOrNull(printingColours),
      printingColoursInsidePaper: toStringOrNull(printingColoursInsidePaper),
      timestamp: timestamp.toString(),
    });
    return res;
  } catch (error) {
    throw error;
  }
};

//Commercial QIS

export const commercialQISapi = async (
  employeeId,
  boxType,
  closure,
  length,
  breadth,
  dimUnit,
  qtt1,
  qtt2,
  qtt3,
  quality,
  printingColours,
  transport,
  region,
  doubleSide,
  printingColoursInsidePaper,
  diaCut,
  lamination,
  applications,
  posterLayout,
  posterGummingType,
  dimensionType,
  standardDimensions,
  folderType,
  pouchSize,
  customPouchSize,
  brochureType,
  bookletPages,
  bookletBindingType,
  bookletCoverPaperType,
  bookletCoverPaperGSM,
  bookletCoverOutsideColours,
  bookletCoverLamination,
  bookletCoverDoubleSide,
  bookletCoverInsideColours,
  bookletCoverApplications,
  bookletInnerPaperType,
  bookletInnerPaperGSM,
  bookletInnerOutsideColours,
  bookletInnerLamination,
  bookletInnerDoubleSide,
  bookletInnerInsideColours,
  bookletInnerApplications,
) => {
  try {
    const timestamp = new Date().getTime();
    const res = await axios.post(`${BASE_URL}${ENDPOINTS.COMMERCIAL_QIS}`,{
      employeeId : toStringOrNull(employeeId),
      boxType: toStringOrNull(boxType),
      closure : toStringOrNull(closure),
      length : toStringOrNull(length),
      breadth : toStringOrNull(breadth),
      dimUnit : toStringOrNull(dimUnit),
      qtt1 : toStringOrNull(qtt1),
      qtt2 : toStringOrNull(qtt2),
      qtt3 : toStringOrNull(qtt3),
      quality : toStringOrNull(quality),
      printingColours : toStringOrNull(printingColours),
      transport : toStringOrNull(transport),
      region : toStringOrNull(region),
      doubleSide : toStringOrNull(doubleSide),
      printingColoursInsidePaper : toStringOrNull(printingColoursInsidePaper),
      diaCut : toStringOrNull(diaCut),
      lamination : toStringOrNull(lamination),
      applications,
      posterLayout: toStringOrNull(posterLayout),
      posterGummingType: toStringOrNull(posterGummingType),
      dimensionType: toStringOrNull(dimensionType),
      standardDimensions: toStringOrNull(standardDimensions),
      folderType: toStringOrNull(folderType),
      pouchSize: toStringOrNull(pouchSize),
      customPouchSize: toStringOrNull(customPouchSize),
      brochureType: toStringOrNull(brochureType),
      bookletPages: toStringOrNull(bookletPages),
      bookletBindingType: toStringOrNull(bookletBindingType),
      bookletCoverPaperType: toStringOrNull(bookletCoverPaperType),
      bookletCoverPaperGSM: toStringOrNull(bookletCoverPaperGSM),
      bookletCoverOutsideColours: toStringOrNull(bookletCoverOutsideColours),
      bookletCoverLamination: toStringOrNull(bookletCoverLamination),
      bookletCoverDoubleSide: toStringOrNull(bookletCoverDoubleSide),
      bookletCoverInsideColours: toStringOrNull(bookletCoverInsideColours),
      bookletCoverApplications,
      bookletInnerPaperType: toStringOrNull(bookletInnerPaperType),
      bookletInnerPaperGSM: toStringOrNull(bookletInnerPaperGSM),
      bookletInnerOutsideColours: toStringOrNull(bookletInnerOutsideColours),
      bookletInnerLamination: toStringOrNull(bookletInnerLamination),
      bookletInnerDoubleSide: toStringOrNull(bookletInnerDoubleSide),
      bookletInnerInsideColours: toStringOrNull(bookletInnerInsideColours),
      bookletInnerApplications,
      timestamp: timestamp.toString(),
    });
    return res;
  } catch (error) {
    throw error;
  }
};

// fetching PTCS orders

export const fetchPTCSOrders = async (uniqueId) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.PTCS_ORDERS}`, {
      params: {
        ptcsId: uniqueId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ptcs pending approval
export const PTCSPendingApprovalApi = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.PTCS_PENDING_APPROVAL}`,
      {
        params: {
          employeeId: uniqueId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Accepted orders in PTCS
export const acceptedOrderInPTCS = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.PTCS_ACCEPTED_ORDER}`,
      {
        params: {
          orderId: orderId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// rejected ptcs order
export const PTCSRejectedOrder = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.PTCS_REJECTED_ORDER}`,
      {
        params: {
          orderId: orderId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// SM Escalation

export const SMEscalationApi = async (uniqueId) => {
  try {
    const response = await axios.get(`${BASE_URL}${ENDPOINTS.SM_ESCALATION}`, {
      params: {
        smId: uniqueId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// editable orders in SM
export const rejectedOrderInSM = async (orderId) => {
  try {
    const res = await axios.get(
      `${BASE_URL}${ENDPOINTS.SM_ESCALATION_REJECT}`,
      {
        params: {
          orderId: orderId,
        },
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

// editable orders in SM Escalation dasboard
export const editableOrdersInSMEscalation = async (tempOrders) => {
  try {
    const res = await axios.patch(
      `${BASE_URL}${ENDPOINTS.SM_ESCALATION_EDIT_ORDER}`,
      {
        tempOrders: tempOrders,
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

// qis mail send

export const qisMailSend = async (
  price1,
  price2,
  price3,
  clientName,
  clientEmail,
  address,
  //boxType,
  modifiedBoxType,
  //closure,
  modifiedClosure,
  kraftQuality,
  asspassPaper,
  asspassQuality,
  cuffSize,
  boardSize,
  dimUnit,
  modifiedHandle,
  handle,
  dim,
  qtt1,
  qtt2,
  qtt3,
  modifiedTransport,
  transport,
  quality,
  modifiedRegion,
  //region,
  applications,
  applicationsValue,
  employeeId,
  product
) => {
  try {
    const timestamp = new Date().getTime();
    const res = await axios.post(`${BASE_URL}${ENDPOINTS.QIS_MAIL}`, {
      price1: price1.toString(),
      price2: price2.toString(),
      price3: price3.toString(),
      clientName: clientName.toString(),
      clientEmail: clientEmail.toString(),
      address: address.toString(),
      boxType: modifiedBoxType.toString(),
      closure: modifiedClosure.toString(),
      kraftQuality: kraftQuality.toString(),
      asspassPaper: asspassPaper.toString(),
      asspassQuality: asspassQuality.toString(),
      cuffSize: cuffSize.toString(),
      boardSize: boardSize.toString(),
      dimUnit: dimUnit.toString(),
      handle: modifiedHandle.toString(),
      dim: dim.toString(),
      qtt1: qtt1.toString(),
      qtt2: qtt2.toString(),
      qtt3: qtt3.toString(),
      transport: modifiedTransport.toString(),
      quality: quality.toString(),
      region: modifiedRegion.toString(),
      applications,
      applicationsValue,
      employeeId,
      timestamp: timestamp.toString(),
      product: product.toString(),
    });
    console.log(res);
    return res;
  } catch (error) {
    throw error;
  }
};

// brief sheets in CS dashboard

export const BriefSheetsInCSDashboard = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.CS_BRIEF_SHEETS}`,
      {
        params: {
          employeeId: uniqueId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// move forward this accepted order to PGD

export const acceptedOrderInCS = async (orderId) => {
  console.log("received");
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.ACCEPTED_ORDER_IN_CS}`,
      {
        params: {
          orderId: orderId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// send back the rejected order to sales

export const sendBackThisOrderToSalesFromCS = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.REJECTED_ORDER_IN_CS}`,
      {
        params: {
          orderId: orderId,
          rejectTo: "SM",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// send back the rejected order to PTCS

export const sendBackThisOrderToPTCSFromCS = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.REJECTED_ORDER_IN_CS}`,
      {
        params: {
          orderId: orderId,
          rejectTo: "PTCS",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// pending designs -> brief sheets PGD
export const pendingDesignsInPGD = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.PGD_PENDING_DESIGNS}`,
      {
        params: {
          employeeId: uniqueId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// upload pdf in PGD

export const UploadPDFInPGD = async ({ orderId, formData }) => {
  try {
    const response = await axios.post(
      `${BASE_URL}${ENDPOINTS.PDF_UPLOAD}/${orderId}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to Upload the data:", error);
    throw error;
  }
};

// submit pdf along with order Id in brief sheets of PGD
export const SubmitDesignsInPGD = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.SUBMIT_IN_BRIEF_SHEETS_PGD}/${orderId}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// get graphic designs

export const graphicDesignsInCS = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.GET_GRAPHIC_DESIGNS}`,
      {
        params: {
          employeeId: uniqueId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// accepted order by cs in graphic designs

export const acceptedOrderByCSForGD = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.ACCEPT_PGD_DESIGNS_IN_CS}`,
      {
        params: {
          orderId: orderId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// rejected order by cs in GD

export const rejectedOrderByCSForGD = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.REJECT_PGD_DESIGNS_IN_CS}`,
      {
        params: {
          orderId: orderId,
          rejectTo: "PGD",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// rejected order by cs which is going back to PTCS

export const rejectedOrderByCSForPTCS = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.REJECTED_ORDER_BY_CS_TO_PTCS}`,
      {
        params: {
          orderId: orderId,
          rejectTo: "PTCS",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

export const sendThisOrderToPTCSFromCSSampleDispatch = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.REJECTED_ORDER_BY_CS_TO_PTCS}`,
      {
        params: {
          orderId: orderId,
          rejectTo: "PTCS",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

export const sendThisOrderToJRPTFromCSSampleDispatch = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.REJECTED_ORDER_BY_CS_TO_PTCS}`,
      {
        params: {
          orderId: orderId,
          rejectTo: "JRPT",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// accepted order in cs sample dispatch
export const AcceptedOrderByCSInSampleDispatch = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.ACCEPTED_ORDER_BY_CS_IN_SAMPLE_DISPATCH}`,
      {
        params: {
          orderId: orderId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// proforma invoice in cs input
export const proformaInvoiceInput = async (
  clientName,
  clientGST,
  clientEmail,
  clientContact,
  billingAddress,
  orders
) => {
  try {
    const timestamp = new Date().getTime();
    const res = await axios.post(
      `${BASE_URL}${ENDPOINTS.CS_PROFORMA_INVOICE_INPUT}`,
      {
        clientName: clientName.toString(),
        clientGST: clientGST.toString(),
        clientEmail: clientEmail.toString(),
        clientContact: clientEmail.toString(),
        billingAddress: billingAddress.toString(),
        orders,
        timestamp: timestamp.toString(),
      }
    );
    console.log(res);
    return res;
  } catch (error) {
    throw error;
  }
};

// jr pt pending designs

export const JRPTPendingDesigns = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.PENDING_DESIGNS_IN_JRPT}`,
      {
        params: {
          employeeId: uniqueId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// upload multiple images
export const UploadMultipleImages = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}${ENDPOINTS.JRPT_SAMPLE_UPLOAD_IMAGES}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to upload the data:", error);
    throw error;
  }
};

// uploading images

export const SubmitWithImages = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.SUBMIT_WITH_IMAGES}`,
      {
        params: {
          orderId: orderId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to Upload the data:", error);
    throw error;
  }
};

// get orders for sample dispatch
export const fetchOrdersWithImages = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.CS_SAMPLE_DISPATCH_ORDERS}`,
      {
        params: {
          employeeId: uniqueId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// JRPT final Dispatch Production

export const JRPTFinalDispatchProduction = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.JRPT_FINAL_DISPATCH}`,
      {
        params: {
          employeeId: uniqueId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// upload Images in final production

export const UploadImagesByJRPTInFinalProduction = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}${ENDPOINTS.JRPT_IN_FINAL_UPLOAD}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to upload the data:", error);
    throw error;
  }
};

// submit with images
export const FinalDispatchSubmit = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.JRPT_FINAL_SUBMIT}`,
      {
        params: {
          orderId: orderId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to Upload the data:", error);
    throw error;
  }
};

// get escalation orders in ptcs
export const getEscalationOrdersInPTCS = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.PTCS_ESCALATION_ORDERS}`,
      {
        params: {
          ptcsId: uniqueId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// editable orders in ptcs

export const editableOrdersInPTCSEscalation = async (tempOrders) => {
  try {
    const res = await axios.patch(
      `${BASE_URL}${ENDPOINTS.PTCS_ESCALATION_EDITABLE_ORDER}`,
      {
        tempOrders: tempOrders,
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

// CS Final Dispatch

export const fetchOrdersWithImagesInFinalDispatch = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.JRPT_FINAL_DISPATCH}`,
      {
        params: {
          employeeId: uniqueId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch orders:", error);
    throw error;
  }
};

// ptcs escalation

export const EscalateThisOrderToSM = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.PTCS_REJECTED_ORDER}?orderId=${orderId}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to Upload the data:", error);
    throw error;
  }
};

// cs final dispatch

export const getCSFinalDispatchOrders = async (uniqueId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.CS_FINAL_DISPATCH}?employeeId=${uniqueId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// fetching images in cs final dispatch

export const viewImagesInFinalDispatchCS = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.CS_FINAL_DISPATCH_IMAGES}/${orderId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// closing the sale
export const EndOfTheSeasonSale = async (orderId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ENDPOINTS.CS_FINAL_CLOSE_SALE}?orderId=${orderId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// in punch cost upload images

export const punchCostWithImages = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}${ENDPOINTS.PUNCH_ORDERS_WITH_IMAGES}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to upload the data:", error);
    throw error;
  }
};
