import React, { useContext } from "react";
import { CommercialQISContext } from "../context/CommercialQISContext";

const CommercialApplications = ({ applications, handleApplicationChange }) => {
    const { applicationsValue, setApplicationsValue } =
        useContext(CommercialQISContext);
    const {closure, setClosure} = 
        useContext(CommercialQISContext);
    return (
        <>
        <div className="checkBoxGrid">
            <label htmlFor="Spot UV" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                <input
                    type="checkbox"
                    name="LogoSpotUV"
                    checked={applications.LogoSpotUV}
                    onChange={handleApplicationChange}
                    id="logoSpotUVApplication"
                />
                <label for="logoSpotUVApplication">
                    Logo Spot UV
                </label>
            </label>

            {/* <label htmlFor="Spot UV On Other Elements" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                <input
                    type="checkbox"
                    name="SpotUVOnOtherElements"
                    checked={applications.SpotUVOnOtherElements}
                    onChange={handleApplicationChange}
                />
                <label for="spotUVOtherElementsApplication">
                    Spot UV On Other Elements
                </label>
            </label> */}
            {
                (closure === "card" ||
                closure === "flyer" ||
                closure === "postCard" ||
                closure === "menuCard" ||
                closure === "doorHandle" ||
                closure === "certificate" ||
                closure === "folder" ||
                closure === "booklet" ||
                closure === "chitPad" ||
                closure === "prescriptionPad")
                && (
                    <label htmlFor="Logo Embossing" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                        <input
                            type="checkbox"
                            name="LogoEmbossing"
                            checked={applications.LogoEmbossing}
                            onChange={handleApplicationChange}
                            id="logoEmbossingApplication"
                        />
                        <label for="logoEmbossingApplication">
                            Logo Embossing
                        </label>
                    </label>
                )
            }

            {/* <label htmlFor="Embossing On Other Elements" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                <input
                    type="checkbox"
                    name="EmbossingOnOtherElements"
                    checked={applications.EmbossingOnOtherElements}
                    onChange={handleApplicationChange}
                    id="embossingOtherElementsAplication"
                />
                <label for="embossingOtherElementsAplication">
                    Embossing On Other Elements
                </label>
            </label> */}

            <label htmlFor="Logo Foiling" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                <input
                    type="checkbox"
                    name="LogoFoiling"
                    checked={applications.LogoFoiling}
                    onChange={handleApplicationChange}
                    id="logoFoilingApplication"
                />
                <label for="logoFoilingApplication">
                Logo Foiling
                </label>
            </label>

            {/* <label htmlFor="Foiling On Other Elements" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                <input
                    type="checkbox"
                    name="FoilingOnOtherElements"
                    checked={applications.FoilingOnOtherElements}
                    onChange={handleApplicationChange}
                    id="foilingOtherElementsApplication"
                />
                <label for="foilingOtherElementsApplication">
                    Foiling On Other Elements
                </label>
            </label> */}

            <label htmlFor="DripOffUV" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                <input
                    type="checkbox"
                    name="DripOffUV"
                    checked={applications.DripOffUV}
                    onChange={handleApplicationChange}
                    id="dripOffUVApplication"
                />
                <label for="dripOffUVApplication">
                    Drip Off UV
                </label>
            </label>

            <label htmlFor="Varnish" style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                <input
                    type="checkbox"
                    name="Varnish"
                    checked={applications.Varnish}
                    onChange={handleApplicationChange}
                    id="varnishApplication"
                />
                <label for="varnishApplication">
                    Varnish
                </label>
            </label>
        </div>
        </>
    );
};

export default CommercialApplications;
