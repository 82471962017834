// RigidClosureImages.js
import React from "react";

const RigidClosureImagesDisplay = ({ selectedClosure }) => {
    return (
        <div style={{ margin: "20px"}}>
            {/* Conditionally render images based on selected closure */}

            {/*  */}
            {/*  */}
            {/* APPAREL SECTION */}
            {/* apparel */}
            {/*  */}

            {selectedClosure === "fbe486" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/fbe486open.png"}
                        alt="Description of fbe486"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/fbe486closed.png"}
                        alt="Description of fbe486"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/fbe486mock.png"}
                        alt="Description of fbe486"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/fbe486kld.png"}
                        alt="Description of fbe486"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb020" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/rb020open.png"}
                        alt="Description of rb020"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/rb020closed.png"}
                        alt="Description of rb020"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/rb020mock.png"}
                        alt="Description of rb020"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/rb020kld.png"}
                        alt="Description of rb020"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb208" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/rb208open.png"}
                        alt="Description of rb208"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/rb208closed.png"}
                        alt="Description of rb208"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/rb208mock.png"}
                        alt="Description of rb208"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/apparel/rb208kld.png"}
                        alt="Description of rb208"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* CHOCOLATES AND CANDIES SECTION */}
            {/* chocolates_and_candies */}
            {/*  */}
            
            {selectedClosure === "rb141" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb141open.png"}
                        alt="Description of rb141"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb141closed.png"}
                        alt="Description of rb141"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb141mock.png"}
                        alt="Description of rb141"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb141kld.png"}
                        alt="Description of rb141"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb174" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb174open.png"}
                        alt="Description of rb174"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb174closed.png"}
                        alt="Description of rb174"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb174mock.png"}
                        alt="Description of rb174"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb174kld.png"}
                        alt="Description of rb174"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb143" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb143open.png"}
                        alt="Description of rb143"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb143closed.png"}
                        alt="Description of rb143"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb143mock.png"}
                        alt="Description of rb143"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb143kld.png"}
                        alt="Description of rb143"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb076" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb076open.png"}
                        alt="Description of rb076"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb076closed.png"}
                        alt="Description of rb076"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb076mock.png"}
                        alt="Description of rb076"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb076kld.png"}
                        alt="Description of rb076"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb026" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb026open.png"}
                        alt="Description of rb026"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb026closed.png"}
                        alt="Description of rb026"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb026mock.png"}
                        alt="Description of rb026"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb026kld.png"}
                        alt="Description of rb026"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb085" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb085open.png"}
                        alt="Description of rb085"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb085closed.png"}
                        alt="Description of rb085"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb085mock.png"}
                        alt="Description of rb085"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/chocolates_and_candies/rb085kld.png"}
                        alt="Description of rb085"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* COSMETICS SECTION */}
            {/* cosmetics */}
            {/*  */}

            {selectedClosure === "rb127" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb127open.png"}
                        alt="Description of rb127"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb127closed.png"}
                        alt="Description of rb127"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb127mock.png"}
                        alt="Description of rb127"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb127kld.png"}
                        alt="Description of rb127"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb179" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb179open.png"}
                        alt="Description of rb179"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb179closed.png"}
                        alt="Description of rb179"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb179mock.png"}
                        alt="Description of rb179"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb179kld.png"}
                        alt="Description of rb179"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb180" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb180open.png"}
                        alt="Description of rb180"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb180closed.png"}
                        alt="Description of rb180"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb180mock.png"}
                        alt="Description of rb180"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb180kld.png"}
                        alt="Description of rb180"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb181" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb181open.png"}
                        alt="Description of rb181"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb181closed.png"}
                        alt="Description of rb181"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb181mock.png"}
                        alt="Description of rb181"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb181kld.png"}
                        alt="Description of rb181"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb168" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb168open.png"}
                        alt="Description of rb168"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb168closed.png"}
                        alt="Description of rb168"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb168mock.png"}
                        alt="Description of rb168"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb168kld.png"}
                        alt="Description of rb168"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb177" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb177open.png"}
                        alt="Description of rb177"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb177closed.png"}
                        alt="Description of rb177"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb177mock.png"}
                        alt="Description of rb177"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb177kld.png"}
                        alt="Description of rb177"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb183" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb183open.png"}
                        alt="Description of rb183"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb183closed.png"}
                        alt="Description of rb183"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb183mock.png"}
                        alt="Description of rb183"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb183kld.png"}
                        alt="Description of rb183"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb185" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb185open.png"}
                        alt="Description of rb185"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb185closed.png"}
                        alt="Description of rb185"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb185mock.png"}
                        alt="Description of rb185"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb185kld.png"}
                        alt="Description of rb185"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}
            {selectedClosure === "rb169" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb169open.png"}
                        alt="Description of rb169"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb169closed.png"}
                        alt="Description of rb169"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb169mock.png"}
                        alt="Description of rb169"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/cosmetics/rb169kld.png"}
                        alt="Description of rb169"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/*  */}
            {/*  */}
            {/* RETAIL SECTION */}
            {/* retail */}
            {/*  */}

            {selectedClosure === "rb010" && (
                <div className="gridOfClosureImages">
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/retail/rb010open.png"}
                        alt="Description of rb010"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/retail/rb010closed.png"}
                        alt="Description of rb010"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/retail/rb010mock.png"}
                        alt="Description of rb010"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                    <img
                        src={process.env.PUBLIC_URL + "/Images/RigidBoxes/retail/rb010kld.png"}
                        alt="Description of rb010"
                        className="image-element"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            )}

        </div>
    );
};

export default RigidClosureImagesDisplay;
