export const CommercialPaperGSMOptions = [
    { value : "", label : "None"},
    { value : "90AP", label : "90 GSM Art Paper"},
    { value : "100AP", label : "100 GSM Art Paper"},
    { value : "128AP", label : "128 GSM Art Paper"},
    { value : "150AP", label : "150 GSM Art Paper"},
    { value : "170AP", label : "170 GSM Art Paper"},
    { value : "210AC", label : "210 GSM Art Card"},
    { value : "250AC", label : "250 GSM Art Card"},
    { value : "300AC", label : "300 GSM Art Card"},
    { value : "350AC", label : "350 GSM Art Card"},
];