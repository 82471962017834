import React, { useContext, useRef } from "react";
import { CommercialQISContext } from "../../context/CommercialQISContext";
import { CommercialPrescriptionPadClosureOptions } from "../../DropDownOptions/CommercialPrescriptionPadClosureOptions";
import DropDownFields from "../../reusable/DropDownFields";
import InputTextField from "../../reusable/InputTextField";
import { CommercialKappaPadThickness } from "../../DropDownOptions/CommercialKappaPadThickness";
import { CommercialPadGalleyInnerPaperGSMOptions } from "../../DropDownOptions/CommercialPadGalleyInnerPaperGSMOptions";
import { CommercialPadGalleyOuterPaperGSMOptions } from "../../DropDownOptions/CommercialPadGalleyOuterPaperGSMOptions";
import { LaminationCommercialOptions } from "../../DropDownOptions/LaminationCommercialOptions";
import { PrintingColoursOptionsInsidePaper } from "../../DropDownOptions/PrintingColoursOptionsInsidePaper";
import CommercialApplications from "../../DropDownOptions/CommercialApplications";
import { CommercialChitGSMOptions } from "../../DropDownOptions/CommercialChitGSMOptions";
import { PrintingColoursOptions } from "../../DropDownOptions/PrintingColoursOptions";
import { CommercialPadPerforationTypeOptions } from "../../DropDownOptions/CommercialPadPerforationTypeOptions";
import { CommercialPadGalleyTypeOptions } from "../../DropDownOptions/CommercialPadGalleyTypeOptions";
import { CommercialArtCardGSMOptions } from "../../DropDownOptions/CommercialArtCardGSMOptions";

const CommercialPrescriptionPad = () => {
    const quotationRef = useRef(null);

    const employeeId = localStorage.getItem("uniqueId");
    const boxType = "commercialPrinting";

    const {
        closure, setClosure,
        length, setLength,
        breadth, setBreadth,
        height, setHeight,
        dimUnit, setDimUnit,
        qtt1, setQtt1,
        qtt2, setQtt2,
        qtt3, setQtt3,
        quality, setQuality,
        lamination, setLamination,
        transport, setTransport,
        region, setRegion,
        applications, setApplications,
        applicationsValue, setApplicationsValue,
        printingColours, setPrintingColours,
        printingColoursInsidePaper, setPrintingColoursInsidePaper,
        doubleSide, setDoubleSide,
        diaCut, setDiaCut,
        posterLayout, setPosterLayout,
        posterGummingType, setPosterGummingType,
        dimensionType, setDimensionType,
        standardDimensions, setStandardDimensions,

        folderType, setFolderType,
        pouchSize, setPouchSize,
        customPouchSize, setCustomPouchSize,

        brochureType, setBrochureType,
        bookletPages, setbookletPages,
        bookletBindingType, setBookletBindingType,
        
        bookletCoverPaperType, setBookletCoverPaperType,
        bookletCoverPaperGSM, setBookletCoverPaperGSM,
        bookletCoverOutsideColours, setBookletCoverOutsideColours,
        bookletCoverLamination, setBookletCoverLamination,
        bookletCoverDoubleSide, setBookletCoverDoubleSide,
        bookletCoverInsideColours, setBookletCoverInsideColours,
        bookletCoverApplications, setBookletCoverApplications,
        
        bookletInnerPaperType, setBookletInnerPaperType,
        bookletInnerPaperGSM, setBookletInnerPaperGSM,
        bookletInnerOutsideColours, setBookletInnerOutsideColours,
        bookletInnerLamination, setBookletInnerLamination,
        bookletInnerDoubleSide, setBookletInnerDoubleSide,
        bookletInnerInsideColours, setBookletInnerInsideColours,
        bookletInnerApplications, setBookletInnerApplications,
        prescriptionPadType, setPrescriptionPadType,

        chitPadGalleyType, setChitPadGalleyType,
        padThickness, setPadThickness,
        padInnerPaperGSM, setPadInnerPaperGSM,
        padOuterPaperGSM, setPadOuterPaperGSM,
        padInnerPrinting, setPadInnerPrinting,
        padInnerPrintingColours, setPadInnerPrintingColours,
        padGalleyApplications, setPadGalleyApplications,

        chitNoOfChits, setChitNoOfChits,
        chitLength, setChitLength,
        chitBreadth, setChitBreadth,
        chitGSM, setChitGSM,
        chitPrintingColours, setChitPrintingColours,
        chitBackSidePrinting, setChitBackSidePrinting,
        chitBackSideColours, setChitBackSideColours,

        prescriptionPadClosure, setPrescriptionPadClosure,
        prescriptionPadGalleyType, setPrescriptionPadGalleyType,
        prescriptionPadGalleyLength, setPrescriptionPadGalleyLength,
        prescriptionPadGalleyBreadth, setPrescriptionPadGalleyBreadth,
        prescriptionPadGalleyBoardThickness, setPrescriptionPadGalleyBoardThickness,
        prescriptionPadGalleyInnerPaperGSM, setPrescriptionPadGalleyInnerPaperGSM,
        prescriptionPadGalleyOuterPaperGSM, setPrescriptionPadGalleyOuterPaperGSM,
        prescriptionPadGalleyLamination, setPrescriptionPadGalleyLamination,
        prescriptionPadPerforation, setPrescriptionPadPerforation,

    } = useContext(CommercialQISContext);

    const resetPadGalleyApplicationsValue = (appName) => {
        setApplicationsValue((prevApplicationsValue) => ({
        ...prevApplicationsValue,
        [appName]: null,
        }));
    };

    const handlePadGalleyApplicationChange = (event) => {
        const { name, checked } = event.target;
        setPadGalleyApplications((prevApplications) => ({
        ...prevApplications,
        [name]: checked,
        }));
        if (!checked) {
        resetPadGalleyApplicationsValue(name);
        }
    };

    return (
        <div>
            <label className="cover-inner-section-titile" style={{color:"#800000"}}>
                Cover
            </label>
            <div className="booklet-cover-inner-container">
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            value={prescriptionPadClosure}
                            setValue={setPrescriptionPadClosure}
                            options={CommercialPrescriptionPadClosureOptions}
                            label={"Closure"}
                        />
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            value={prescriptionPadGalleyType}
                            setValue={setPrescriptionPadGalleyType}
                            options={CommercialPadGalleyTypeOptions}
                            label={"Galley Type"}
                        />
                    </div>
                    <div className="input-box">
                        {
                            (prescriptionPadGalleyType === "paper") && (
                                <DropDownFields
                                    value={prescriptionPadGalleyOuterPaperGSM}
                                    setValue={setPrescriptionPadGalleyOuterPaperGSM}
                                    options={CommercialArtCardGSMOptions}
                                    label={"Paper GSM"}
                                />
                            )
                        }
                        {
                            (prescriptionPadGalleyType === "kappa") && (
                                <DropDownFields
                                    value={prescriptionPadGalleyBoardThickness}
                                    setValue={setPrescriptionPadGalleyBoardThickness}
                                    options={CommercialKappaPadThickness}
                                    label={"Board Thickness"}
                                />
                            )
                        }
                    </div>
                </div>
                {
                    (prescriptionPadGalleyType === "kappa") && (
                        <div className="column">
                            <div className="input-box">
                                <DropDownFields
                                    value={prescriptionPadGalleyInnerPaperGSM}
                                    setValue={setPrescriptionPadGalleyInnerPaperGSM}
                                    options={CommercialPadGalleyInnerPaperGSMOptions}
                                    label={"Asspass GSM"}
                                />
                            </div>
                            <div className="input-box">
                                <DropDownFields
                                    value={prescriptionPadGalleyOuterPaperGSM}
                                    setValue={setPrescriptionPadGalleyOuterPaperGSM}
                                    options={CommercialPadGalleyOuterPaperGSMOptions}
                                    label={"Outer Paper GSM"}
                                />
                            </div>
                        </div>
                    )
                }
                <div className="column">
                    <div className="input-box">
                        <InputTextField
                            value={prescriptionPadGalleyLength}
                            setValue={setPrescriptionPadGalleyLength}
                            label={"Length"}
                        />
                    </div>
                    <div className="input-box">
                        <InputTextField
                            value={prescriptionPadGalleyBreadth}
                            setValue={setPrescriptionPadGalleyBreadth}
                            label={"Breadth"}
                        />
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            value={prescriptionPadGalleyLamination}
                            setValue={setPrescriptionPadGalleyLamination}
                            options={LaminationCommercialOptions}
                            label={"Lamination"}
                        />
                    </div>
                </div>
                <label>Inner Printing</label>
                <div className="column">
                    <div className="input-box">
                        <div className="doubleSidePrinting">
                            <div class="radio-label">
                                <input
                                    type="radio"
                                    name="padInnerDoubleSidePrinting"
                                    id="innerYes"
                                    value="yes"
                                    checked={padInnerPrinting === "yes"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setPadInnerPrinting(e.target.value)}
                                />
                                <label style={{marginTop:"26px"}} className="padInnerLabelNo" for="innerYes">
                                    Yes
                                </label>
                            </div>
                            <div className="radio-label">
                                <input
                                    type="radio"
                                    name="padInnerDoubleSidePrinting"
                                    id="innerNo"
                                    value="no"
                                    checked={padInnerPrinting === "no"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setPadInnerPrinting(e.target.value)}
                                />
                                <label style={{marginTop:"26px"}} className="padInnerLabelNo" for="innerNo">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="input-box">
                        {padInnerPrinting==="yes" && (
                            <DropDownFields
                            options={PrintingColoursOptionsInsidePaper}
                            value={padInnerPrintingColours}
                            setValue={setPadInnerPrintingColours}
                            label={"Colours for Inside Print"}
                            />
                        )}
                    </div>
                </div>
                <div className="applicationsGridContainer">
                    <label htmlFor="applications">Applications : </label>
                    <div className="commercialApplicationsGrid">
                        <CommercialApplications
                            applications={padGalleyApplications}
                            handleApplicationChange={handlePadGalleyApplicationChange}
                        />
                    </div>
                </div>
            </div>
            <label className="cover-inner-section-titile" style={{color:"#800000", paddingTop:"15px"}}>
                Prescription Pages
            </label>
            <div className="booklet-cover-inner-container">
                <div className="column">
                    <div className="input-box">
                        <InputTextField
                            value={chitNoOfChits}
                            setValue={setChitNoOfChits}
                            label={"No. of chits"}
                        />
                    </div>
                    <div className="input-box">
                        <DropDownFields
                            value={chitGSM}
                            setValue={setChitGSM}
                            options={CommercialChitGSMOptions}
                            label={"GSM of Chits"}
                        />
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <InputTextField
                            value={chitLength}
                            setValue={setChitLength}
                            label={"Length"}
                        />
                    </div>
                    <div className="input-box">
                        <InputTextField
                            value={chitBreadth}
                            setValue={setChitBreadth}
                            label={"Breadth"}
                        />
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            value={chitPrintingColours}
                            setValue={setChitPrintingColours}
                            options={PrintingColoursOptions}
                            label={"No of Colours for Printing"}
                        />
                    </div>
                </div>
                <label>Chit Back Side Printing</label>
                <div className="column">
                    <div className="input-box">
                        <div className="doubleSidePrinting">
                            <div class="cover-radio-label">
                                <input
                                    type="radio"
                                    name="chitDoubleSidePrinting"
                                    id="coverYes"
                                    value="yes"
                                    checked={chitBackSidePrinting === "yes"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setChitBackSidePrinting(e.target.value)}
                                />
                                <label style={{marginTop:"26px"}} className="chitLabelNo" for="coverYes">
                                    Yes
                                </label>
                            </div>
                            <div className="cover-radio-label">
                                <input
                                    type="radio"
                                    name="chitDoubleSidePrinting"
                                    id="coverNo"
                                    value="no"
                                    checked={chitBackSidePrinting === "no"}
                                    style={{ marginTop: "12px", marginBottom: "8px", background: "none"}}
                                    onChange={(e) => setChitBackSidePrinting(e.target.value)}
                                />
                                <label style={{marginTop:"26px"}} className="chitLabelNo" for="coverNo">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="input-box">
                        {
                            (chitBackSidePrinting === "yes") && (
                                <DropDownFields
                                    value={chitBackSideColours}
                                    setValue={setChitBackSideColours}
                                    options={PrintingColoursOptionsInsidePaper}
                                    label={"Colours for Back Print"}
                                />
                            )
                        }
                    </div>
                </div>
                <div className="column">
                    <div className="input-box">
                        <DropDownFields
                            value={prescriptionPadPerforation}
                            setValue={setPrescriptionPadPerforation}
                            options={CommercialPadPerforationTypeOptions}
                            label={"Perforation"}
                        />
                    </div>
                </div>
            </div>
        </div>      
    )   
};

export default CommercialPrescriptionPad;