import React, { useState, useContext } from "react";
import { fetchLoginDetails } from "../apis/apiClients";
import "./LoginPage.css";
import { useHistory } from "react-router-dom";
import { UniqueIdContext } from "../context/UniqueIdContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const history = useHistory();
  const { setUniqueId, setName } = useContext(UniqueIdContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    try {
      const res = await fetchLoginDetails(email, password, role);
      if (res.status === 200) {
        const uniqueId = res.data.uniqueId;
        const name = res.data.name;
        setName(name);
        toast.success("Login Successful!");

        setUniqueId(uniqueId);
        localStorage.setItem("uniqueId", uniqueId);
        localStorage.setItem("name", name);
        if (role === "SM") {
          history.push("/salesDashboard");
        } else if (role === "BDA") {
          history.push("/dashboard/Qis");
        } else if (role === "PTCS") {
          history.push("/PTCSDashboard");
        } else if (role === "CS") {
          history.push("/CSDashboard");
        } else if (role === "PGD") {
          history.push("/PGDashboard");
        } else if (role === "JRPT") {
          history.push("/JRPTDashboard");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to login!");
    }
    // finally {
    //   setIsLoading(false);
    // }
  };

  return (
    <div className="MainLoginContainer">
      <section class="side">
        <img
          src="/Images/boxBackground.webp"
          alt=""
          style={{
            width: "608px",
            height: "770px",
            borderRadius: "30px",
          }}
        />
      </section>
      <div className="greySeparatingLine"></div>

      <section class="main">
        <div class="login-container">
          <img
            src="/Images/baxsaaLogo.png"
            style={{ width: "230px", height: "210px" }}
            alt="baxsaaLogo"
          />

          <form class="login-form" onSubmit={handleSubmit}>
            <div class="form-control">
              <div className="formGroup">
                <label htmlFor="email"></label>
                <div className="emailLabel">Email:</div>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-control">
                <div className="formGroup">
                  <label htmlFor="password"></label>
                  <div
                    className="passwordLabel"
                    // style={{ marginRight: "165px" }}
                  >
                    Password:
                  </div>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="form-control">
                <div className="roleLabel" style={{ marginRight: "204px" }}>
                  Role:
                </div>
                <div class="custom-container">
                  <label class="custom-label" for="role"></label>
                  <select
                    class="custom-select"
                    id="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    style={{ outline: "none" }}
                  >
                    <option value="">Select a role</option>
                    <option value="BDA">BDA</option>
                    <option value="SM">SM</option>
                    <option value="JRPT">JRPT</option>
                    <option value="PTCS">PTCS</option>
                    <option value="PGD">PGD</option>
                    <option value="Admin">Admin</option>
                    <option value="CS">CS</option>
                  </select>
                </div>
              </div>
            </div>

            <button class="submit">Login</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Login;
