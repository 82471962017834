import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserDropdown from "../reusable/UserDropdown";
import {
  getEscalationOrdersInPTCS,
  editableOrdersInPTCSEscalation,
  EscalateThisOrderToSM,
} from "../apis/apiClients";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchComponent from "../searchBar/SearchComponent";

const PTCSEscalation = () => {
  const [orders, setOrders] = useState([]);
  const [tempOrders, setTempOrders] = useState([]);
  const [changed, setChanged] = useState(false);
  const history = useHistory();

  const handlePrev = () => {
    history.push("/PTCSDashboard");
  };
  const uniqueId = localStorage.getItem("uniqueId");

  const handleRejectionofPTCSinEscalation = async (orderId) => {
    const confirmReject = window.alert(
      "Are you sure you want to escalate this order?"
    );

    if (confirmReject) {
      try {
        const response = await EscalateThisOrderToSM(orderId);
        if (response.status === 200) {
          toast.success("Order Rejected to SM!");
        }
        toast.success("Order has been escalated to SM!");
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getEscalationOrdersInPTCS(uniqueId);
        console.log(response, "response");

        if (response) {
          const orders = response.orders;
          setOrders(orders);
          setTempOrders(orders);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrders();
  }, [uniqueId]);

  const editedOrder = async (editedOrder) => {
    try {
      const res = await editableOrdersInPTCSEscalation([editedOrder]);
      if (res.status === 200) {
        console.log("Orders updated successfully!");
        setOrders((prevOrders) => {
          const updatedOrders = prevOrders.map((order) =>
            order.orderId === editedOrder.orderId ? editedOrder : order
          );
          return updatedOrders;
        });
        toast.success("Order Edited Successfully!");
        setChanged(false);
        if (editedOrder) {
          const editedOrderId = editedOrder.orderId;
          console.log("Edited Order ID:", editedOrderId);
        }
      }
      return res.data;
    } catch (error) {
      console.log("Failed to put the data!!");
      console.log(error);
    }
    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  const handleInputChange = (index, field, value, applicationsValue) => {
    const updatedOrders = [...tempOrders];
    if (applicationsValue) {
      updatedOrders[index][applicationsValue] = {
        ...updatedOrders[index][applicationsValue],
        [field]: value,
      };
    } else {
      updatedOrders[index] = {
        ...updatedOrders[index],
        [field]: value,
      };
    }

    setChanged(true);
    setTempOrders(updatedOrders);
  };

  return (
    <div>
      <div className="navbarLayer">
        <div className="logoContainer">
          <i
            onClick={handlePrev}
            id="backwardArrow"
            className="fa-solid fa-circle-arrow-left"
          ></i>
          <b className="titleOrders">PTCS Escalation Orders</b>

          <UserDropdown />
        </div>
      </div>
      <h2>Number of Escalations : {orders.length}</h2>
      <SearchComponent orders={orders} setOrders={setOrders} />
      <div className="ordersContainer">
        {orders.map((order, index) => (
          <div className="ordersItem" key={order._id}>
            <div className="orderBoxId">
              <p
                style={{
                  color: "#800000",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
              >
                ORDER ID: {order.orderId}
              </p>
            </div>
            <br />
            <label>Client Name :</label>
            <input
              type="text"
              value={tempOrders[index].clientName}
              onChange={(e) =>
                handleInputChange(index, "clientName", e.target.value)
              }
            />
            <label>Client Email : </label>
            <input
              type="email"
              value={tempOrders[index].clientEmail}
              onChange={(e) =>
                handleInputChange(index, "clientEmail", e.target.value)
              }
            />
            <label>Client Address : </label>
            <input
              type="text"
              value={tempOrders[index].clientAddress}
              onChange={(e) =>
                handleInputChange(index, "clientAddress", e.target.value)
              }
            />
            <label>Client Contact :</label>
            <input
              type="text"
              value={tempOrders[index].clientContact}
              onChange={(e) =>
                handleInputChange(index, "clientContact", e.target.value)
              }
            />
            <label>Client Pincode :</label>
            <input
              type="text"
              value={tempOrders[index].clientPincode}
              onChange={(e) =>
                handleInputChange(index, "clientPincode", e.target.value)
              }
            />
            <label>Client GST :</label>
            <input
              type="text"
              value={tempOrders[index].clientGST}
              onChange={(e) =>
                handleInputChange(index, "clientGST", e.target.value)
              }
            />
            <label>Box Type:</label>
            <input
              type="text"
              value={tempOrders[index].boxType}
              onChange={(e) =>
                handleInputChange(index, "boxType", e.target.value)
              }
            />

            <label>Closure :</label>
            <input
              type="text"
              value={tempOrders[index].closure}
              onChange={(e) =>
                handleInputChange(index, "closure", e.target.value)
              }
            />
            <label>Dimension :</label>
            <input
              type="text"
              value={tempOrders[index].dim}
              onChange={(e) => handleInputChange(index, "dim", e.target.value)}
            />
            <label>Dimension Unit :</label>
            <input
              type="text"
              value={tempOrders[index].dimUnit}
              onChange={(e) => handleInputChange(index, "dim", e.target.value)}
            />

            <label>Quantity 1 : </label>
            <input
              type="text"
              value={tempOrders[index].qtt1}
              onChange={(e) => handleInputChange(index, "qtt1", e.target.value)}
            />
            <label>Quantity 2 : </label>
            <input
              type="text"
              value={tempOrders[index].qtt2}
              onChange={(e) => handleInputChange(index, "qtt2", e.target.value)}
            />
            <label>Quantity 3 : </label>
            <input
              type="text"
              value={tempOrders[index].qtt3}
              onChange={(e) => handleInputChange(index, "qtt3", e.target.value)}
            />
            <label>Quality : </label>
            <input
              type="text"
              value={tempOrders[index].quality}
              onChange={(e) =>
                handleInputChange(index, "quality", e.target.value)
              }
            />
            <label>Region: </label>
            <input
              type="text"
              value={tempOrders[index].region}
              onChange={(e) =>
                handleInputChange(index, "region", e.target.value)
              }
            />
            <label>Transport: </label>
            <input
              type="text"
              value={tempOrders[index].transport}
              onChange={(e) =>
                handleInputChange(index, "transport", e.target.value)
              }
            />
            <label>Paper GSM: </label>
            <input
              type="text"
              value={tempOrders[index].transport}
              onChange={(e) =>
                handleInputChange(index, "transport", e.target.value)
              }
            />
            <p>
              <label>Applications:</label>
              {Object.keys(tempOrders[index].applicationsValue).map((app) => (
                <div key={app}>
                  {tempOrders[index].applicationsValue[app] !== undefined && (
                    <>
                      <span>{`${app}: `}</span>
                      <input
                        type="text"
                        value={tempOrders[index].applicationsValue[app]}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            app,
                            e.target.value,
                            "applicationsValue"
                          )
                        }
                      />
                    </>
                  )}
                </div>
              ))}
            </p>

            {changed ? (
              <>
                <button
                  className="editButton"
                  onClick={(e) => {
                    setTempOrders({ ...orders });
                    setChanged(false);
                  }}
                >
                  Cancel ❌
                </button>
                <button
                  className="editButton"
                  onClick={() => editedOrder(tempOrders[index])}
                >
                  Save & Submit ✅
                </button>
              </>
            ) : null}

            <button
              onClick={() => handleRejectionofPTCSinEscalation(order.orderId)}
              className="rejectButtonSMPending"
            >
              Reject &nbsp;❌
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PTCSEscalation;
